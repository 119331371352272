import { axiosClient } from '../../../library/Service/axiosClient';
import { REPORT_CONFIG } from '../../admin/reports/report-config';
import { arrayColumn, isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import pageConfig from './PageConfigSearch.json';

class ManagePsyLinkService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        const searchCriteria = ManagePsyLinkService.getDefaultSearchCriteria();
       
        return Promise.all([
            this.getPageConfig(),
            this.search(searchCriteria, params.pageName)
        ]).then((values) => {
            console.log(values, 'fetched data')
            state.formConfig = values[0];
            state.data = {
                userList: values[1].data ?? [],
                searchCriteria: { ...searchCriteria },
                //filterDomains: values[2].data,
                reportConfig: REPORT_CONFIG[params.pageName],
            };
            state.routeInfo = params;
            state.internal = { gridState: {} };
            return Promise.resolve(state);
        });
    }

    static search(searchCriteria: any, pageName: string): any {
      
        const config = REPORT_CONFIG[pageName];
        const payload = ManagePsyLinkService.sanitizeSearchCriteria(searchCriteria,);
        //return axiosClient().post(`${process.env.REACT_APP_LEAD_SERVER}/${config.SEARCH_API}`, payload);
        const apiServer = process.env.REACT_APP_WIDGET_API_SERVER;
        return axiosClient().post(
            `${apiServer}/psychometricLink/getPsyUsers`,
            payload
        );
    }

    static getPageConfig() {
        
        return Promise.resolve(pageConfig);
    }

    static sanitizeSearchCriteria(searchCriteria: any) {
        console.log(searchCriteria, 'searchCriteria data')
        const criteria = { ...searchCriteria };
    

        console.log(criteria, 'criteria list data')
        return criteria;
    }

    static getDefaultSearchCriteria(): any {
        return {
            userEmail:''
        };
    }
}

export default ManagePsyLinkService;
