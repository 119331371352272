import React, { useState, useRef } from 'react';

const CreateImageTemplate: React.FC = () => {
    const divRef = useRef<HTMLDivElement>(null);
    const previewRef = useRef<Window | null>(null);
    const [secondaryColor, setSecondaryColor] = useState('#0064A7'); // Default to whit
    const [alumniName, setAlumniName] = useState('Nitin Beri');
    const [instituteName, setInstituteName] = useState('New Era High School, Panchgani - Class of 1986');
    const [webinarTitle, setWebinarTitle] = useState('Leadership & Innovation: Lessons from Business & Tech Experts');
    const [webinarDate, setWebinarDate] = useState('Feb 3rd, 2025');
    const handleDownload = async () => {
        if (!divRef.current) {
            console.error('Element not found!');
            return;
        }

        const images = Array.from(divRef.current.querySelectorAll('img')) as HTMLImageElement[];

        try {
            await Promise.all(
                images.map((img) => {
                    return new Promise((resolve, reject) => {
                        if (img.complete && img.naturalWidth > 0) {
                            resolve(true);
                        } else {
                            img.onload = () => resolve(true);
                            img.onerror = () => reject(false);
                        }
                    });
                })
            );

            const { default: html2canvas } = await import('html2canvas');
            const scaleFactor = 3; // Increase scale factor for better quality (400px / 400px = 2.7, so 3 is safe)
            const canvas = await html2canvas(divRef.current, {
                backgroundColor: secondaryColor,
                scale: scaleFactor, // Increase scale factor
                useCORS: true,
                allowTaint: false,
            });

            const scaledCanvas = document.createElement('canvas');
            scaledCanvas.width = 1220; // Set desired width
            scaledCanvas.height = 860; // Set desired height
            const scaledContext = scaledCanvas.getContext('2d');

            if (scaledContext) {
                scaledContext.fillStyle = '#ffffff';
                scaledContext.fillRect(0, 0, 1220, 860);
                scaledContext.drawImage(canvas, 0, 0, 1220, 860);

                const dataUrl = scaledCanvas.toDataURL('image/png', 1.0); // Use maximum quality
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'spotlights-pic.jpg'; // Updated file name
                link.click();
            }
        } catch (error) {
            console.error('Error generating image:', error);
        }
    };
    const handlePreview = async () => {
        if (!divRef.current) {
            console.error('Element not found!');
            return;
        }

        // Capture the actual background color before modifying
        const computedStyle = window.getComputedStyle(divRef.current);
        const originalBgColor = computedStyle.backgroundColor;

        // Ensure the background color is set correctly before capturing
        divRef.current.style.backgroundColor = originalBgColor; // Retain the original background color

        const { default: html2canvas } = await import('html2canvas');
        const scaleFactor = 3; // Increase scale factor for better quality
        const canvas = await html2canvas(divRef.current, {
            backgroundColor: null, // Set null to retain actual background
            scale: scaleFactor, // Increase scale factor
            useCORS: true, // Enable CORS for external images
            allowTaint: false, // Disallow tainting the canvas
        });

        if (!previewRef.current || previewRef.current.closed) {
            const screenWidth = window.screen.width;
            const screenHeight = window.screen.height;
            const popupWidth = 1220; // Set desired width
            const popupHeight = 860; // Set desired height
            const left = (screenWidth - popupWidth) / 2;
            const top = (screenHeight - popupHeight) / 2;
            previewRef.current = window.open('', 'Preview', `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`);
        }

        if (previewRef.current) {
            previewRef.current.document.body.innerHTML = '';
            previewRef.current.document.write(
                '<html><head><title>Preview</title></head><body style="margin: 0; display: flex; align-items: center; justify-content: center; background: #ffffff;"></body></html>'
            );
            const img = document.createElement('img');
            img.src = canvas.toDataURL('image/png', 1.0); // Use maximum quality
            img.style.width = '100%';
            img.style.height = 'auto';
            previewRef.current.document.body.appendChild(img);
        }

        // Reset the background color after capturing (optional)
        divRef.current.style.backgroundColor = originalBgColor;
    };
    return (
        <div className="main flex-1">
            <section className="alumni_directory_details">
                <div className="max-1140 mx-auto pt-5">
                    <div className="d-flex justify-content-between align-items-center pb-3">
                        <h3 data-aos="fade-left" data-aos-delay="600" className="font-30 font-600 mb-0 block-title position-relative">
                            Create New Image Template <i className="icon-right-arrow font-16 position-relative me-1"></i>
                            <span>Configure</span>
                        </h3>
                    </div>
                </div>

                <div className="max-1140 pb-5 overflow-hidden mx-auto">
                    <div className="row g-4">
                        <div className="col-sm-12">
                            <div className="bg-gray-F2F4F7 border-radius-6 p-3 p-sm-4 px-md-5 text-center font-24 font-500">
                                Webinar Certificate
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="row g-2 justify-content-between pb-2">
                                <div className="col-auto font-20 font-500">
                                    Category - <span className="font-18 font-400">Achievement</span>
                                </div>
                                <div className="col-auto font-20 font-500">
                                    Size <span className="font-18 font-400">Instagram Post - 1220 x 860 px</span>
                                </div>
                            </div>

                            <div className="bg-gray-F2F4F7 border-radius-6 p-3 p-sm-4">
                                <div className="row g-4">
                                    <div className="col-md-6">
                                        <div className="w-100 h-100">
                                            <div className="bg-white border-radius-6 p-4 w-100 border h-100 d-flex justify-content-center align-items-start">
                                                <div className="x-scroll">
                                                    <div className="web-sertificate" ref={divRef}>
                                                        <img
                                                            src="https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/web-certificate_te.jpg"
                                                            className="img-fluid"
                                                            alt=""
                                                            crossOrigin="anonymous"
                                                        />
                                                        <div className="certificate-holder-name joan-regular">{alumniName}</div>
                                                        <div className="certificate-holder-school">
                                                        {instituteName}
                                                        </div>

                                                        <div className="presenting">
                                                            <div className="title">For presenting a webinar on</div>
                                                            <div className="incontext joan-regular">
                                                            {webinarTitle}
                                                            </div>
                                                            <div className="date">Date: {webinarDate}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="bg-white border-radius-6 p-4 h-100">
                                            <form className="row g-3">
                                                

                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Alumni Name"
                                                        value={alumniName}
                                                        onChange={(e) => setAlumniName(e.target.value)}
                                                    />
                                                </div>

                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Alumni Institute, Program and Batch Year"
                                                        value={instituteName}
                                                        onChange={(e) => setInstituteName(e.target.value)}
                                                    />
                                                </div>

                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Webinar Title"
                                                        value={webinarTitle}
                                                        onChange={(e) => setWebinarTitle(e.target.value)}
                                                    />
                                                </div>

                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Webinar Date"
                                                        value={webinarDate}
                                                        onChange={(e) => setWebinarDate(e.target.value)}
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="action_btns max-100per mt-0">
                                <button type="button" className="nextbtn my-2 my-sm-0 mx-2 px-5" onClick={handlePreview}>
                                    <span className="px-3">Preview</span>
                                </button>
                                <button type="button" className="btn btn-green my-2 my-sm-0 mx-2 height-45 px-5" onClick={handleDownload}>
                                    <span className="px-3">Save as Image</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default CreateImageTemplate;
