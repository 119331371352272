import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmartContext } from '../../../../library/Core/SmartContext';
import WorkSimilarAlumni from '../controls/WorkSimilarAlumni';
import SessionContext from '../../../../library/Core/SessionContext';
import { getCompanyImage } from '../../../../common/styles/Images';
import { getDomainValueForCode, getFlexColDomainValueForCode, isEmpty } from '../../../../library/Core/SmartFunctions';
import { Tooltip } from 'bootstrap';

const ProfileWorkExperience = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);
    const model = state.internal.model;
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);  
    const [isActive, setIsActive] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [actionBtnStatus, setActionBtnStatus]: any = useState({});
    const [selectedStatus,setSelectedStatus] :any =useState({})

    const handleClick = (id: any) => {
        setIsActive(!isActive);
        setIsHidden(!isHidden);

        setActionBtnStatus({
            ...actionBtnStatus,
            [id]: ! actionBtnStatus[id]
        });
    };

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    });

    const isPeerReviewed = sessionState?.isPeerReviewed ?? false;
    const userType = sessionStorage.getItem('user-type-code');
    const isPersonate = sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false;
    if (isPersonate != true && userType == 'ALUMNI' && isPeerReviewed != true) {
        window.location.href = `/alumni/welcome`;
        return;
    }

    function getImage(workId: any, jobCategory: any, jobRoleCode: any) {
        const isFreelancing = jobCategory === 'SELF_EMPLOYED_AND_FREELANCING';
        const domainCode = isFreelancing ? 'FREELANCING_JOB_ROLE_CODE' : (jobCategory === 'TEACHING_AND_RESEARCH' ? 'INSTITUTE_DOMAIN' : 'COMPANY_CODE');
        const flexCol = getFlexColDomainValueForCode(isFreelancing ? jobRoleCode : workId, domainCode, state);
        console.log("flexCol",flexCol)
        const logoKey = isFreelancing ? 'img' : 'logo';
        return getCompanyImage(isEmpty(flexCol) ? '' : JSON.parse(flexCol)[logoKey] || '');
    }
    
    const jobCategoryPaths: Record<string, string> = {
        TEACHING_AND_RESEARCH: `/alumni/${model.id}/{work.id}/teaching-experience`,
        PROFESSIONAL_PRACTICE: `/alumni/${model.id}/{work.id}/professional-practice-experience`,
        DEFENCE: `/alumni/${model.id}/{work.id}/defence-experience`,
        IN_DEFENCE: `/alumni/${model.id}/{work.id}/defence-experience`,
        ENTREPRENEURSHIP: `/alumni/${model.id}/{work.id}/entrepreneurship`,
        SELF_EMPLOYED_AND_FREELANCING: `/alumni/${model.id}/{work.id}/freelancing-experience`,
        default: `/alumni/${model.id}/{work.id}/work`,
    };

    const workStatus = state.domain.get('WORK_STATUS_CODE')?.filter((status: any) =>
        status.code !== 'NOT_WORKING' && status.code !== 'HOME_MAKER'
    ) || [];
    
    const handleStatusChange = (status: string, event: React.MouseEvent) => {
        event.preventDefault();  
        setSelectedStatus(status);
        handleNavigate(status);
    };

    // const handleAddWork = () => {
    //     dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showWorkingStatusModal' } });
    // };

    const handleNavigate = (selectedStatus:any) => {
        const categoryPath = jobCategoryPaths[selectedStatus] || jobCategoryPaths['default'];
        const finalPath = categoryPath.replace('{work.id}', 'new');
        // const finalPath = (selectedStatus!=='ENTREPRENEURSHIP') ? categoryPath.replace('{work.id}', 'new') : categoryPath.replace('{work.id}', '');
        navigate(finalPath);
    };

    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div>
            <div className="workExperience-detail">
                <div className="blockHeader">
                    <div className="row g-2  align-items-center">
                        <div className="col">
                            {!model.isEditable && (
                                <div className="title">
                                    Companies{' '}
                                    <span>
                                        {`${model.firstName}`} {model.lastName ? `${model.lastName}` : ''}
                                    </span>{' '}
                                    has worked for
                                </div>
                            )}
                            {model.isEditable && <div className="title">My Work Experience</div>}
                        </div>
                        {model.isEditable && (
                            <div className="col-auto">
                              
                                 {/* <a role="button" onClick={handleAddWork} className="btn-add-section">
                                    <i></i> <span>ADD</span>
                                </a> */}
                                <div className="col-auto">
                                <div className="dropdown">
                                    <button
                                        className="btn-add-section"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        aria-expanded={isDropdownOpen ? 'true' : 'false'} 
                                        onClick={handleDropdownToggle} 
                                    ><i></i> <span>ADD</span></button>
                                    <ul className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuButton1">                                        {workStatus?.map((option: any) => (
                                            <li key={option.code}>
                                                <a 
                                                    className="dropdown-item" 
                                                    href="#"
                                                    onClick={(e) => handleStatusChange(option.code,e)}
                                                >{option.value}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            </div>
                        )}
                    </div>
                </div>
                <div className="workExperienceRow">
                    <div className="row g-4 g-md-5">
                        {model.workExperience.map((work: any) => (
                            <div key={`${work.id}-${work.companyName}`} className="col-md-6">
                                <div className="work-block">
                                    {/* added by Vp #1201 @18-11-24 */}
                                    <div className="icon workex-logo">
                                     <img className="img-fluid" 
                                     src={getImage(work.companyId,work.jobCategory,work.jobRoleCode)} 
                                     alt="" />
                                    </div>
                                    {/* end */}

                                    <div className="work-detail flex-row align-content-between">
                                        <div className="d-flex flex-column">
                                         <p className="work-designation">
                                            {work.designation
                                                ?.toLowerCase()  
                                                .replace(/_/g, ' ')  
                                                .replace(/\b\w/g, (char:any) => char.toUpperCase())  
                                            }
                                            </p>
                                            <div className='d-flex flex-wrap align-items-center'>
                                                <span className='flex-1'>
                                                    {/* {work.companyName}, {work.startYear} - {work.endYear} */}
                                                    {work.companyName}{work.startYear && (`, ${work.startYear}`)} {work.endYear && (` - ${work.endYear}`)}
                                                </span>
                                                {work?.isCampusPlacement && (  
                                                 <i className="icon-hat ms-2 blue-filter" data-bs-toggle="tooltip" data-bs-original-title="This was a campus placement" aria-label="This was a campus placement"></i>
                                                )}
                                            </div>
                                        </div>
                                        {model.isEditable && (
                                            <div className={`edit_section ${actionBtnStatus[work.id] ? 'open' : ''} ${isHidden ? 'hidden' : ''}`}>
                                                <button className="edit_section_toggle" onClick={() => {handleClick(work.id)}}></button>
                                                <div className="edit_section_inner">
                                                <a
                                                        role="button"
                                                        onClick={() => {
                                                            const categoryPath = jobCategoryPaths[work.jobCategory] || jobCategoryPaths['default'];
                                                            const finalPath = categoryPath.replace('{work.id}', work.id);
                                                            navigate(finalPath);
                                                        }}
                                                        className="edit_link"
                                                    >
                                                        Edit
                                                    </a>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
              {/* campus work ex #UAS-1201 by Vrinda @13-feb-2025 */}
              <div className="workExperience-detail border-d2d2d2 mb-4 mt-4">
                <div className="blockHeader">
                    <div className="row g-2  align-items-center">
                        <div className="col">
                            {!model.isEditable && (
                                <div className="title">
                                    Companies{' '}
                                    <span>
                                        {`${model.firstName}`} {model.lastName ? `${model.lastName}` : ''}
                                    </span>{' '}
                                    has worked for
                                </div>
                            )}
                            {model.isEditable && <div className="title">My Campus Placement Journey </div>}
                        </div>
                        {model.isEditable && (
                            <div className="col-auto">
                                <a role="button" onClick={() => navigate(`/alumni/${model.id}/job-outcome`)} className="btn-add-section">
                                    <i></i> <span>ADD</span>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
                <div className="workExperienceRow">
                    <div className="row g-4 g-md-5">
                        {state?.data['jobsApplied'].map((work: any) => (
                            <div key={`${work.id}`} className="col-md-6">
                                <div className="work-block">
                                    {/* added by Vp #1201 @18-11-24 */}
                                    <div className="icon workex-logo">
                                     <img className="img-fluid" 
                                     src={getImage(work.companyId,work.jobCategory,work.jobRoleCode)} 
                                     alt="" />
                                    </div>
                                    {/* end */}

                                    <div className="work-detail flex-row align-content-between">
                                      <div className="d-flex flex-column">
                                                <p className="work-designation mb-0">
                                                {isEmpty(work.companyId) ? work.otherCompany : getDomainValueForCode(work.companyId, 'COMPANY_CODE', state)}
                                                </p>                                                
                                                <div className="row g-2">
                                                <div className="col-auto">
                                                <div className="got_offer">
                                                        {work.recordTypeCode === 'GOT_OFFER_DID_NOT_JOIN' ? (
                                                        <i className="icon-like text-green me-1"></i>
                                                        ) : null}
                                                        <span className="text-dark">
                                                        {getDomainValueForCode(work.recordTypeCode, 'COLLEGE_APPLICATION_RESULT_CODE', state) ?? ''}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {model.isEditable && (
                                            <div className={`edit_section ${actionBtnStatus[work.id] ? 'open' : ''} ${isHidden ? 'hidden' : ''}`}>
                                                <button className="edit_section_toggle" onClick={() => {handleClick(work.id)}}></button>
                                                <div className="edit_section_inner">
                                                   <a
                                                        role="button"
                                                        onClick={() => navigate(`/alumni/${model.id}/job-outcome`)}
                                                        className="edit_link"
                                                    >Edit</a>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
              </div>
                {/* end campus placement */}
                {/* Modal for selecting status */}
                           {/* <div
                                    id="send-email"
                                    className={`modal fade ${showFlag ? 'show' : ''}`} 
                                    tabIndex={-1}
                                    role="dialog"
                                    style={{ display: showFlag ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                    <div className="modal-dialog modal-dialog-centered max-600 mx-auto">
                                        <div className="modal-content">
                                            <div className="modal-header p-4 height-70 border-0 bg-gray-f2">
                                                <h5 className="modal-title" id="staticBackdropLabel">
                                                    Add Work Experience
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="btn-close btn-close-small"
                                                    onClick={() => {
                                                        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showWorkingStatusModal' } });
                                                    }}
                                                    aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body p-4">
                                                <form className="needs-validation" noValidate>
                                                    <div className="row g-4">
                                                        <div className="col-md-12">
                                                            <label className="form-label">Type of Experience: </label>
                                                            <div className="row g-2">
                                                                <div className="col">
                                                                    <div>
                                                                        <div className="row">
                                                                            {workStatus?.map((option: any, index: number) => (
                                                                                <div key={option.code} className="col-6 form-check">
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="form-check-input"
                                                                                        id={`status-${option.code}`}
                                                                                        name="workingStatus"
                                                                                        value={option.code}
                                                                                        checked={selectedStatus === option.code}
                                                                                        onChange={(e) => handleStatusChange(e.target.value)}
                                                                                    />
                                                                                    <label className="form-check-label" htmlFor={`status-${option.code}`}>
                                                                                        {option.value}
                                                                                    </label>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
            {/* end od modal */}
            <WorkSimilarAlumni />
        </div>
    );
};

export default ProfileWorkExperience;
