import { isEmpty, isFormValid, setError } from "../../../../../library/Core/SmartFunctions";
import { ControlOnChangeArguments, DispatchEvent, State } from "../../../../../library/Core/SmartTypes";
import { AlumniProfileEditScreens } from "../../../../../services/screen-navigation.service";
import StudentService from "../../../../../services/student.service";
import { navigateToNextStep } from "../../../services/profile-next-step-navigator";

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    
    const inValidDate = isValidDate(state, dispatch);
    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);
    // let customValidationPassed = true;
    if (!isImpersonatedSession && ((!isFormValid(state, dispatch,  ['jobsApplied.*.companyId','entreprenurshipOutcome.companyId']) || !customValidationPassed) || !inValidDate)) {
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }
    
    let entreprenurshipOutcome = state?.data['entreprenurshipOutcome'];
    if (!Array.isArray(entreprenurshipOutcome)) {
        entreprenurshipOutcome = [entreprenurshipOutcome];
    }

    if (Array.isArray(entreprenurshipOutcome)) {
        entreprenurshipOutcome = entreprenurshipOutcome.map((job) => {
            const existingJob = state?.data?.jobsList?.find((existingJob: any) => existingJob.id == job.id);

            if (existingJob) {
                const updatedJob = {
                    ...existingJob, 
                    ...job,
                    recordTypeCode: existingJob.recordTypeCode || 'JOINED',
                    jobCategory: 'ENTREPRENEURSHIP',
                    isOutcome: 1,
                    designation:'Founder'
                };

                if (job.isCurrent) {
                    updatedJob.endDate = null;
                }

                return updatedJob;
            }

            const updatedJob = {
                ...job,
                recordTypeCode: isEmpty(job.recordTypeCode) ? 'JOINED' : job.recordTypeCode,
                jobCategory: 'ENTREPRENEURSHIP',
                isOutcome: 1,
                designation:'Founder'
            };

            if (job.isCurrent) {
                updatedJob.endDate = null;
            }

            return updatedJob;
        });

        const jobsNotentreprenurshipWork = state?.data?.jobsList?.filter((existingJob: any) => 
            !entreprenurshipOutcome.some((job: any) => job.id === existingJob.id)
        );
        entreprenurshipOutcome = [...entreprenurshipOutcome, ...jobsNotentreprenurshipWork];
    }

    const jobsApplied = handleJobsApplied(
        state?.data['profile'],
        state?.data['jobsApplied'],  
        state?.data?.jobsList,        
        true                         
    );
    let jobHistory = [...entreprenurshipOutcome,...jobsApplied];

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            jobs: jobHistory,
            profile: state?.data['profile'],
        })
    ).then((response) => {
        if (response?.status === 200) {
            navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch, sessionState, sessionDispatch)
        }
    });
};

const handleJobsApplied = (profile: any, jobsApplied: any[], existingJobs: any[], isOutcome: boolean) => {
    if (profile.appliedForThePlacement) {
        return jobsApplied.map((job: any) => {
            const existingJob = existingJobs.find((existingJob: any) => existingJob.id === job.id);
            if (existingJob) {
                return {
                    ...existingJob, 
                    ...job,  
                    isOutcome: isOutcome, 
                };
            } else {
                return {
                    ...job,
                    isOutcome: isOutcome,
                };
            }
        });
    }
    return [];
};


const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    let isFormInvalid = false;
    const jobs = state.data.jobsApplied;
    const entreprenurshipOutcome = state.data.entreprenurshipOutcome;

    if (isEmpty(entreprenurshipOutcome?.companyId) && isEmpty(entreprenurshipOutcome?.otherCompany)) {
        isFormInvalid = true;
        setError(`entreprenurshipOutcome.companyId`, [`Please enter "Name of the Company"`], dispatch);
    } else {
        setError(`entreprenurshipOutcome.companyId`, [], dispatch);
    }

    jobs.forEach((job: any, index: number) => {
        if (state?.data?.profile?.appliedForThePlacement) {
            if (isEmpty(job?.companyId) && isEmpty(job?.otherCompany)) {
                isFormInvalid = true;
                setError(`jobsApplied.${index}.companyId`, [`Please enter "Name of the Company"`], dispatch);
            } else {
                setError(`jobsApplied.${index}.companyId`, [], dispatch);
            }
        }else{
            setError(`jobsApplied.${index}.companyId`, [], dispatch); //clear error appliedForThePlacement set to false
        }
    });

    // Dispatch the validation errors to the state
    if ( isFormInvalid ) {
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'jobsApplied', errorMessages },
        });
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'entreprenurshipOutcome', errorMessages },
        });
    }
    
    return !isFormInvalid;
};

export const onCurrentJobSelectionChange = (props: ControlOnChangeArguments) => {
   if(!props.state.data.entreprenurshipOutcome?.isCurrent){
    props.dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey: `${props.parentDataKey}.industryCode`, value: [] } });
   }
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');;
};

const isValidDate = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const job = state?.data['entreprenurshipOutcome'];
    let isFormValid = true;

        if (isEmpty(job?.startDate)) {
            setError(`entreprenurshipOutcome.startDate`, [`Please enter "Start Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`entreprenurshipOutcome.startDate`, [], dispatch);
        }
    return isFormValid;
};

