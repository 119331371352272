// import config from '../config/config';
import { AxiosResponse } from 'axios';
import { isEmpty, isSchoolAdmin } from '../library/Core/SmartFunctions';
import logger from '../library/Core/logger';
import { axiosClient } from '../library/Service/axiosClient';
import PageConfigService from './page-config.service';
import PageConfigEducation from '../features/student/edit/education/PageConfigEducation.json';

class StudentService {
    static async pageLoader(params: any) {
        const state: any = {};

        const { id, pageName, includeAdditionalInfo, includeComputedFieldsJson,editId } = params;
        logger.log(`Loading ${pageName}`);
        console.log("editId",editId)

        return Promise.all([
            pageName === 'student-education' ? this.getPageConfig() : PageConfigService.get(pageName),
            await StudentService.get(id, includeAdditionalInfo, includeComputedFieldsJson,editId),
            await StudentService.getProgramDomainData(pageName, id),
        ])
            .then((values) => {
                logger.log(`Retrieved values successfully for ${pageName}`);
                state.formConfig = pageName === 'student-education' ? values[0] : values[0].data;
                state.data = values[1];
                state.routeInfo = { id, pageName };
                state.internal = values[2].data;
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static getPageConfig() {
        return Promise.resolve(PageConfigEducation);
    }

    static async getProgramDomainData(pageName: string, id: string = ''): Promise<any> {
        // const user = JSON.parse(sessionStorage.getItem('user') as string);
        const user = JSON.parse(localStorage.getItem('user') as string);
        const { instituteId, role, userTypeCode, institutes } = user;
        let institute = '';
        if (userTypeCode == 'SUPER_ADMIN') {
            institute = JSON.parse(localStorage.getItem('school_settings') as string)?.id;
            if (institute == undefined && id != '') {
                const profile = sessionStorage.getItem(`studentProfile-` + id) ?? '';
                const institutes = JSON.parse(profile)?.userInstitutes;
                const instituteIds = institutes.map((inst: any) => {
                    return inst.instituteId;
                });
                institute = instituteIds.join(',');
            }
        } else if (isSchoolAdmin()) {
            institute = institutes.join(',');
        } else {
            institute = instituteId;
        }

        return userTypeCode == 'SUPER_ADMIN'
            ? ''
            : axiosClient().get(
                  `${process.env.REACT_APP_PAGE_CONFIG_SERVER}/v1/appConfig/program_domain_data/${
                      // userTypeCode =='SUPER_ADMIN' ? JSON.parse(localStorage.getItem('school_settings') as string).id : isSchoolAdmin() ? institutes.join(',') : instituteId
                      institute
                  }`
              );
    }

    static async get(id: string, includeAdditionalInfo = false, includeComputedFieldsJson = false,editId:any): Promise<any> {
        // TODO: Fix the issue with sessionStorage
        let studentProfile = JSON.parse(sessionStorage.getItem(`studentProfile-XXXXXXXXXXXXXXXXXXXXXX-${id}`) as string);
        let studentInstituteProgramInfo = {} as any;
        // let userData = JSON.parse(sessionStorage.getItem(`user`) as string);
        let userData = JSON.parse(localStorage.getItem(`user`) as string);
        let userHighestProgramDataResp = (
            await axiosClient().get(`${process.env.REACT_APP_COMMON_API}/users/get-user-highest-program-data/${id}`)
        )?.data;
        let userHighestProgramData = userHighestProgramDataResp?.success == 1 ? userHighestProgramDataResp?.data : {};

        if (!studentProfile) {
            studentProfile = (await axiosClient().get(`${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/profile`))?.data;
            const instId =
                userData?.role === 'INSTITUTE_ADMIN'
                    ? userData.institutes.join(',')
                    : userData?.role === 'USER'
                    ? userData?.instituteId
                    : '';
            console.log('instId', instId);
            studentInstituteProgramInfo = (
                await axiosClient().get(`${process.env.REACT_APP_COMMON_API}/profile/get-program-data/${id}/${instId}`)
            )?.data?.data;
            studentProfile.programName = studentInstituteProgramInfo?.programName ?? '';
            studentProfile.userInstituteName = studentInstituteProgramInfo?.name ?? '';
            studentProfile.instituteTypeCode = studentInstituteProgramInfo?.instituteTypeCode ?? '';
            studentProfile.programLevel = studentInstituteProgramInfo?.programLevel ?? '';
            studentProfile.userTypeCode = studentInstituteProgramInfo?.userTypeCode ?? '';
            studentProfile.userHighestProgramData = userHighestProgramData;
            studentProfile.role =
                userData?.userTypeCode === 'SUPER_ADMIN'
                    ? 'ADMIN'
                    : userData?.userTypeCode === 'INSTITUTE_ADMIN'
                    ? 'INSTITUTE_ADMIN'
                    : 'USER';
            if (studentProfile.hasOwnProperty('profile')) {
                // studentProfile.profile.userTypeCode = userHighestProgramData?.userTypeCode
                studentProfile.profile = { ...studentProfile.profile, userTypeCode: userHighestProgramData?.userTypeCode };
            }

            studentProfile = StudentService.toStudentDto(studentProfile,editId);
            sessionStorage.setItem(`studentProfile-${id}`, JSON.stringify(studentProfile));
        }
        studentProfile.userHighestProgramData = userHighestProgramData;
        if (studentProfile.hasOwnProperty('profile')) {
            // studentProfile.profile.userTypeCode = userHighestProgramData?.userTypeCode;
            studentProfile.profile = { ...studentProfile.profile, userTypeCode: userHighestProgramData?.userTypeCode };
        }

        //#UAS-1076 @18Oct2024 by VK
        if (studentProfile.alumniBreak && Array.isArray(studentProfile.alumniBreak)) {
            const matchedBreakItem = studentProfile.alumniBreak.find((breakItem: any) => breakItem.instituteId === userData.instituteId);

            studentProfile.alumniBreak = matchedBreakItem ? { ...matchedBreakItem } : null;
        } else {
            studentProfile.alumniBreak = null;
        }
        //end #UAS-1076

        if (includeAdditionalInfo) {
            // Fetch student additional info
            let studentComputedFields: any = {};
            if (includeComputedFieldsJson) {
                studentComputedFields = await axiosClient().get(
                    `${process.env.REACT_APP_REPORTS_SERVER}/common/${id}/profile-computed-fields`
                );
            }

            const studentAdditionalInfo = (await axiosClient().get(`${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/additional-info`))
                ?.data;
            studentProfile = {
                ...studentProfile,
                ...StudentService.toStudentAdditionalInfoDto(studentAdditionalInfo),
                ...studentComputedFields?.data,
                marker: studentProfile?.marker ?? [],
                demographics: studentProfile?.demographics ?? [],
                positionStrength: studentProfile?.positionStrength ?? [],
                mediaMentions: studentProfile?.mediaMentions ?? [],
                affinity: studentProfile?.affinity ?? [],
                onlineReviews: studentProfile?.onlineReviews ?? [],
                influence: studentProfile?.influence ?? [],
                socialInfluence: studentProfile?.socialInfluence ?? [],
                networkingEvents: studentProfile?.networkingEvents ?? [],
                lAndDEvents: studentProfile?.lAndDEvents ?? [],
                recognitionbyInstitute: studentProfile?.recognitionbyInstitute ?? [],
            };
        }

        return Promise.resolve(studentProfile);
    }

    static async save(id: string, type: string, data: any): Promise<any> {
        let result;
        if (type === 'profile') {
            result = await axiosClient().put(`${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/profile`, this.toStudentEntity(data));
            sessionStorage.setItem(`studentProfile-${id}`, JSON.stringify(result));
        } else if (type === 'additional-info') {
            result = await axiosClient().put(`${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/additional-info`, data);
        }

        return Promise.resolve(result);
    }

    static checkJobOutcome(jobs: any[], jobCategory: string) {    
        const matchedJobs = jobs.filter(
            (job: any) =>
                (jobCategory==='TEACHING_AND_RESEARCH' ? (job.instituteId != null || job.otherInstitute != null)  : (job.companyId != null || job.otherCompany != null) ) &&
                job.recordTypeCode === 'JOINED' &&
                job.jobCategory === jobCategory &&
                job.isOutcome != null && job.isOutcome !== '' && job.isOutcome == 1
        );
        console.log(jobCategory, matchedJobs);
        if (matchedJobs.length === 0) {
            return jobCategory === 'ENTREPRENEURSHIP' ? {} : [];
        }
        
        // if (jobCategory === 'DEFENCE') {
        //     return matchedJobs.map((job: any) => {
        //         const designationRank = job?.designation || ''; 
        //         const { designation, ...rest } = job; 
        //         return {
        //             ...rest,
        //             designationRank,  
        //         };
        //     });
        // }

        // if (jobCategory === 'SELF_EMPLOYED_AND_FREELANCING') {
        //     return matchedJobs.map((job: any) => {
        //         const designationCredited = job?.designation || ''; 
        //         const { designation, ...rest } = job; 
        //         return {
        //             ...rest,
        //             designationCredited,  
        //         };
        //     });
        // }
        if(jobCategory === 'SELF_EMPLOYED_AND_FREELANCING' || jobCategory === 'DEFENCE'){
            return matchedJobs.map((job: any) => {
                let designationRank = '';
                let designationCredited = '';
                if (jobCategory === 'SELF_EMPLOYED_AND_FREELANCING') {
                    designationCredited = job?.designation || '';  
                } else if (jobCategory === 'DEFENCE') {
                    designationRank = job?.designation || ''; 
                }                
                const { designation, ...rest } = job; 
                return {
                    ...rest,
                    designationRank,
                    designationCredited,  
                };
            });
        }
    
        if (jobCategory === 'ENTREPRENEURSHIP') {
            return matchedJobs.length > 0 ? { ...matchedJobs[0] } : {};
        }
        
        return matchedJobs.map((job: any) => {
            const designationRank = job?.designation || ''; 
            const designationCredited = job?.designation || ''; 
            const { designation, ...rest } = job;  
            return {
                ...rest,
                designationRank, designationCredited 
            };
        });
    }

    static filterJobsByCategory(jobs: any[], jobCategory: string, editId: any) {
        console.log("jobs",jobs)
        const filteredJobs = (isEmpty(editId)) 
            ? jobs.filter((job: any) =>
                (jobCategory === 'TEACHING_AND_RESEARCH' ? (job.instituteId != null || job.otherInstitute != null) : (job.companyId != null || job.otherCompany != null)) &&
                job.recordTypeCode === 'JOINED' &&
                job.jobCategory === jobCategory &&
                job.isOutcome != 1
            )
            : (editId === 'new'
                ? [] 
                : jobs.filter((job: any) =>
                    (jobCategory === 'TEACHING_AND_RESEARCH' ? (job.instituteId != null || job.otherInstitute != null) : (job.companyId != null || job.otherCompany != null)) &&
                    job.recordTypeCode === 'JOINED' &&
                    job.jobCategory === jobCategory &&
                    job.id === parseInt(editId)
                )
            ) ?? [];

            console.log("jobCategory",jobCategory)
            console.log("filteredJobs",filteredJobs)
    
        if (jobCategory === 'DEFENCE') {
            return filteredJobs.map((job: any) => {
                const designationRank = job?.designation || ''; 
                const { designation, ...rest } = job; 
                return {
                    ...rest,
                    designationRank,  
                };
            });
        }

        if(jobCategory === 'SELF_EMPLOYED_AND_FREELANCING' || jobCategory === 'DEFENCE'){
            return filteredJobs.map((job: any) => {
                let designationRank = '';
                let designationCredited = '';
                if (jobCategory === 'SELF_EMPLOYED_AND_FREELANCING') {
                    designationCredited = job?.designation || '';  
                } else if (jobCategory === 'DEFENCE') {
                    designationRank = job?.designation || ''; 
                }                
                const { designation, ...rest } = job; 
                return {
                    ...rest,
                    designationRank,
                    designationCredited,  
                };
            });
        }

        if (jobCategory === 'ENTREPRENEURSHIP') {
            return filteredJobs.length > 0 ? { ...filteredJobs[0] } : {};  
        }
        return filteredJobs;
    }
    
    
    static toStudentDto(student: any,editId:any) {
        // const user = JSON.parse(sessionStorage.getItem('user') as string);
        const user = JSON.parse(localStorage.getItem('user') as string);
        let { userTypeCode, schoolGroupId, userInstituteId } = user;

        if (
            (userTypeCode === 'SUPER_ADMIN' || userTypeCode === 'INSTITUTE_ADMIN') &&
            localStorage.getItem('selectedInstitute') != undefined
        ) {
            const selectedInstitutestring: string = '' + localStorage.getItem('selectedInstitute');
            const selectedInstitutedata = JSON.parse(selectedInstitutestring);
            userInstituteId = selectedInstitutedata.userInstituteId;
        }

        // const userTypeCode = user.userTypeCode;
        student.profile = student.profile ?? {};
        student.underGradColleges = student.underGradColleges ?? [];
        student.schools = student.schools ?? [];
        // student.schools = (student.schools.length > 0) ? student.schools : [{"cieGrade":"{}"}];
        student.postGradCollege = student.postGradCollege ?? [];
        student.entranceTests = student.entranceTests ?? [];
        student.jobs = student.jobs ?? [];
        student.socialProfileLinks = student.socialProfileLinks ?? [];
        student.benefits = student?.benefits ?? {};

        if (isSchoolAdmin()) {
            if (student?.profile?.adminAdditionalInfo) {
                student.adminAdditionalInfo = JSON.parse(student.profile.adminAdditionalInfo);
            } else {
                student.adminAdditionalInfo = { timeSlots: [{}] };
            }
        }

        student.user = {
            firstName: student.firstName,
            lastName: student.lastName,
            classCode: student.classCode,
            curriculumCode: student.curriculumCode,
            mobileCountryCode: student.mobileCountryCode,
            mobileNumber: student.mobileNumber,
            registeredEmailId: student.registeredEmailId,
            isMobileVerified: student.isMobileVerified,
            isEmailVerified: student.isEmailVerified,
            isPeerReviewed: student.isPeerReviewed,
            schoolId: student.schoolId,
            programName: student?.programName,
            userInstituteName: student?.userInstituteName,
            schoolGroupId,
        };

        const selectedInstitute = student.userInstitutes.find((institute: any) => institute.id === userInstituteId);
        student.selectedInstitute = selectedInstitute;

        student.profile.schoolId = student.schoolId;
        // student.profile.contactEmailId = student.profile.contactEmailId ?? student.registeredEmailId;
        student.profile.contactEmailId = student.profile.contactEmailId
        student.profile.contactMobileCountryCode = student?.profile?.contactMobileCountryCode ?? student.mobileCountryCode;
        student.profile.contactMobileNumber = student?.profile?.contactMobileNumber ?? student.mobileNumber;
        student.profile.schoolGroupId = schoolGroupId;
        student.profile.userTypeCode = userTypeCode;

       
        // school
        if (student.schools) {
            student.schools = student.schools
                .map((school: any) => {
                    if (school && Object.keys(school).length > 0) {
                        const modifiedSchool = {
                            ...school,
                            cieGrade: Array.isArray(school.cieGrade)
                                ? school.cieGrade
                                : typeof school.cieGrade === 'object'
                                ? school.cieGrade
                                : JSON.parse(school.cieGrade),
                            recordTypeCode: `CLASS_${school.classCode}`
                        };
                        return modifiedSchool;
                    } else {
                        return school;
                    }
                })
                .filter((item: any) => Object.keys(item).length > 0);
        }

        // Undergraduate
        student.underGradCollegeJoined = student.underGradColleges.find((ug: any) => ug.recordTypeCode === 'JOINED') ?? {};
        student.underGradCollegeApplied =
            student.underGradColleges.filter(
                (ug: any) => (ug.universityId != null || ug.otherUniversity != null) && ug.recordTypeCode !== 'JOINED'
            ) ?? [];
        student.underGradCollegeApplied = student.underGradCollegeApplied.map((ug: any) => {
            if (isEmpty(ug.scholarshipAmount)) ug.didYouReceiveScholarship = false;
            return ug;
        });

        // student.postGradCollegeJoined = student.postGradCollege.find((pg: any) => pg.recordTypeCode === 'JOINED') ?? {};
        student.postGradCollegeJoined = student.postGradCollege.filter((pg: any) => pg.recordTypeCode === 'JOINED') ?? [];

        student.postGradCollegeApplied =
            student.postGradCollege.filter(
                (pg: any) => (pg.universityId != null || pg.otherUniversity != null) && pg.recordTypeCode !== 'JOINED'
            ) ?? [];

        // Entrance Tests
        //student.entranceTestForJoined = student.entranceTests.filter((test: any) => test.recordTypeCode === 'JOINED') ?? [];
        // student.entranceTestForApplied = student.entranceTests.filter((test: any) => test.recordTypeCode === 'APPLIED') ?? [];

        // student.targetSkills = student.skills.find((skill: any) => skill.recordTypeCode === '') ?? {};
        // student.currentSkills = student.skills.filter((skill: any) => skill.recordTypeCode !== '') ?? [];
     
        // Jobs->current/outcome form separate for work #UAS-1561,119 @11-mar-25 by VP
        student.entreprenurship = this.filterJobsByCategory(student.jobs, 'ENTREPRENEURSHIP', editId);
        student.teachingWork = this.filterJobsByCategory(student.jobs, 'TEACHING_AND_RESEARCH', editId);
        student.professionalPractice = this.filterJobsByCategory(student.jobs, 'PROFESSIONAL_PRACTICE', editId);
        student.defenceWork = this.filterJobsByCategory(student.jobs, 'DEFENCE', editId);
        student.freelancing = this.filterJobsByCategory(student.jobs, 'SELF_EMPLOYED_AND_FREELANCING', editId);


        // Jobs Outcome
        student.entreprenurshipOutcome = this.checkJobOutcome(student.jobs, 'ENTREPRENEURSHIP');
        student.teachingOutcomeWork = this.checkJobOutcome(student.jobs, 'TEACHING_AND_RESEARCH');
        student.professionalPracticeOutcome = this.checkJobOutcome(student.jobs, 'PROFESSIONAL_PRACTICE');
        student.defenceOutcomeWork =this.checkJobOutcome(student.jobs, 'DEFENCE');
        student.freelancingOutcome =this.checkJobOutcome(student.jobs, 'SELF_EMPLOYED_AND_FREELANCING');
      
        student.jobOutcome = student.jobs.find(
            (job: any) => (job.companyId != null || job.otherCompany != null) && job.recordTypeCode === 'JOINED' &&
           (job.jobCategory === 'ENTREPRENEURSHIP' || job.jobCategory === 'WORKING_FOR_A_COMPANY') && job.isOutcome == true
        ) ?? { isCampusPlacement: true };

        student.jobsApplied = 
        student.jobs.filter(
            (job: any) =>
                (job.companyId != null || job.otherCompany != null) &&
                !['ENTREPRENEURSHIP', 'PROFESSIONAL_PRACTICE', 'TEACHING_AND_RESEARCH', 'DEFENCE','SELF_EMPLOYED_AND_FREELANCING'].includes(job.jobCategory) &&
                job.recordTypeCode !== 'JOINED'
        ) ?? [];

        student.jobsList =
            student.jobs.filter((jobs: any) => {
                return jobs.recordTypeCode === 'JOINED';
            }) ?? [];

        student.jobs = (isEmpty(editId))
        ? student.jobs
            .filter((job: any) =>
                 job.recordTypeCode === 'JOINED' &&
                 !['ENTREPRENEURSHIP', 'PROFESSIONAL_PRACTICE', 'TEACHING_AND_RESEARCH', 'DEFENCE','SELF_EMPLOYED_AND_FREELANCING'].includes(job.jobCategory) &&
                job.isOutcome !=1 
            )
        : (editId === 'new'
            ? []  
            : student.jobs
                .filter((job: any) =>
                job.recordTypeCode === 'JOINED' &&
                !['ENTREPRENEURSHIP', 'PROFESSIONAL_PRACTICE', 'TEACHING_AND_RESEARCH', 'DEFENCE'].includes(job.jobCategory) &&
                job.id == parseInt(editId)
             )
        ) ?? []; 

        //Benefits
        student.benefits.filtersCountry =
            student?.benefits?.filtersCountry?.split(',').map((country: string) => ({ filtersCountry: country })) ?? [];
        student.benefits.filtersCourse =
            student?.benefits?.filtersCourse?.split(',').map((course: string) => ({ filtersCourse: course })) ?? [];
        student.benefits.filtersIndustry =
            student?.benefits?.filtersIndustry?.split(',').map((industry: string) => ({ filtersIndustry: industry })) ?? [];
        student.benefits.filtersFunctionalArea =
            student?.benefits?.filtersFunctionalArea?.split(',').map((area: string) => ({ filtersFunctionalArea: area })) ?? [];

        return student;
    }

    static toStudentEntity(student: any) {
        // const user = JSON.parse(sessionStorage.getItem('user') as string);
        const user = JSON.parse(localStorage.getItem('user') as string);
        let { userTypeCode, schoolGroupId, userInstituteId } = user;

        if (
            (userTypeCode === 'SUPER_ADMIN' || userTypeCode === 'INSTITUTE_ADMIN') &&
            localStorage.getItem('selectedInstitute') != undefined
        ) {
            const selectedInstitutestring: string = '' + localStorage.getItem('selectedInstitute');
            const selectedInstitutedata = JSON.parse(selectedInstitutestring);
            userInstituteId = selectedInstitutedata.userInstituteId;
        }

        if (student.user) {
            student.firstName = student.user['firstName'];
            student.lastName = student.user['lastName'];
            student.classCode = student.user['classCode'];
            student.curriculumCode = student.user['curriculumCode'];
            student.mobileCountryCode = student.user['mobileCountryCode'];
            student.mobileNumber = student.user['mobileNumber'];
            student.registeredEmailId = student.user['registeredEmailId'];
        }

        //added for management profile page
        if (isSchoolAdmin() && !isEmpty(student?.pageName) && student?.pageName === 'admin-additional-info') {
            let adminAdditionalInfo;
            if (student?.profile?.adminAdditionalInfo) {
                adminAdditionalInfo = JSON.parse(student.profile.adminAdditionalInfo);
            } else {
                adminAdditionalInfo = { timeSlots: [{}] };
            }
            console.log('student?.adminAdditionalInfo?.schoolJoinDate', student?.adminAdditionalInfo?.schoolJoinDate);
            adminAdditionalInfo.schoolJoinDate = student?.adminAdditionalInfo?.schoolJoinDate
                ? student?.adminAdditionalInfo?.schoolJoinDate
                : '';
            student = {
                ...student,
                profile: {
                    ...student.profile,
                    adminAdditionalInfo: JSON.stringify(adminAdditionalInfo),
                },
            };
        } else {
            student.adminAdditionalInfo = { timeSlots: [{}] };
        }

        if (student.userInstitutes) {
            student.userInstitutes = [
                ...student.userInstitutes.filter((institute: any) => institute.id !== userInstituteId),
                ...(student.selectedInstitute ? [student.selectedInstitute] : []),
            ];
        }

        //schools
        console.log('student.schools', student.schools);
        if (student.schools) {
            student.schools = student.schools
                .map((school: any) => {
                    if (school && Object.keys(school).length > 0) {
                        const modifiedSchool = {
                            ...school,
                            cieGrade: school.cieGrade === '{}' ? {} : JSON.stringify(school.cieGrade),
                            recordTypeCode: `CLASS_${school.classCode}`,
                            //cieGrade: Array.isArray(school.cieGrade) ? school.cieGrade : (typeof school.cieGrade === 'object' ? school.cieGrade : JSON.parse(school.cieGrade)),
                            SubjectWiseMarkstoggle: school.subjectWiseMarks == '{}' ? false : true,
                            // subjectWiseMarks: JSON.stringify(school.subjectWiseMarks) ?? {}
                            // subjectWiseMarks: school.subjectWiseMarks === '{}' ? {} : JSON.stringify(school.subjectWiseMarks),
                        };
                        return modifiedSchool;
                    } else {
                        return school;
                    }
                })
                .filter((item: any) => Object.keys(item).length > 0);
        }
        console.log('student.schools', student.schools);

        // if (student.schools) {
        //     const schoolCurrent =
        //         Object.keys(student.schoolCurrent).length > 0 ? { ...student.schoolCurrent, recordTypeCode: 'CURRENT' } : {};

        //     const schoolClass10 =
        //         student && student.schoolClass10 && Object.keys(student.schoolClass10).length > 0
        //             ? {
        //                   ...student.schoolClass10,
        //                   classCode: 10,
        //                   recordTypeCode: 'CLASS_10',
        //                   cieGrade: JSON.stringify(student.schoolClass10.cieGrade),
        //               }
        //             : {};

        //     const schoolClass12 =
        //         student && student.schoolClass12 && Object.keys(student.schoolClass12).length > 0
        //             ? {
        //                   ...student.schoolClass12,
        //                   classCode: 12,
        //                   recordTypeCode: 'CLASS_12',
        //                   cieGrade: JSON.stringify(student.schoolClass12.cieGrade),
        //               }
        //             : {};

        //     student.schools = [schoolCurrent, schoolClass10, schoolClass12]
        //         .flatMap((item) => item)
        //         .filter((item) => Object.keys(item).length > 0);
        // }

        if (student.underGradColleges) {
            const underGradCollegeJoined =
                Object.keys(student.underGradCollegeJoined).length > 0
                    ? { ...student.underGradCollegeJoined, recordTypeCode: 'JOINED' }
                    : {};

            const underGradCollegeApplied = student.underGradCollegeApplied.filter(
                (college: any) => !isEmpty(college.universityId) || !isEmpty(college?.otherUniversity)
            );

            student.underGradColleges = [underGradCollegeJoined, underGradCollegeApplied]
                .flatMap((item) => item)
                .filter((item) => Object.keys(item).length > 0);
        }

        // if (student.postGradCollege) {
        //     const postGradCollegeJoined =
        //         Object.keys(student.postGradCollegeJoined).length > 0 ? { ...student.postGradCollegeJoined, recordTypeCode: 'JOINED' } : {};

        //     const postGradCollegeApplied = student.postGradCollegeApplied.filter(
        //         (college: any) => !isEmpty(college.universityId) || !isEmpty(college?.otherUniversity)
        //     );

        //     student.postGradCollege = [postGradCollegeJoined, postGradCollegeApplied]
        //         .flatMap((item) => item)
        //         .filter((item) => Object.keys(item).length > 0);
        // }

        if (student.postGradCollege) {
            let postGradCollegeJoined = [];
            if (Array.isArray(student.postGradCollegeJoined)) {
                postGradCollegeJoined = student.postGradCollegeJoined.map((college: any) => {
                    return {
                        ...college,
                        recordTypeCode: 'JOINED',
                    };
                });
            }

            const postGradCollegeApplied = student.postGradCollegeApplied
                .filter((college: any) => !isEmpty(college.universityId) || !isEmpty(college?.otherUniversity))
                .map((college: any) => {
                    return college;
                });

            student.postGradCollege = [...postGradCollegeJoined, ...postGradCollegeApplied].filter((item) => Object.keys(item).length > 0);
        }

        if (student.entranceTests) {
            const entranceTestWithData = student.entranceTests.map((item: any) =>
                !isEmpty(item?.entranceTestCode) || !isEmpty(item?.otherExam) ? { ...item, recordTypeCode: 'APPLIED' } : {}
            );

            // const entranceTestForJoined = student.entranceTestForJoined.map((item: any) =>
            //     !isEmpty(item?.entranceTestCode) || !isEmpty(item?.otherExam) ? { ...item, recordTypeCode: 'JOINED' } : {}
            // );
            // Object.keys(student.entranceTestForJoined).length > 0 ? { ...student.entranceTestForJoined, recordTypeCode: 'JOINED' } : {};

            student.entranceTests = [...entranceTestWithData.filter((item: any) => !isEmpty(item))];
        }

        if (student.jobCompanyPreferences) {
            student.jobCompanyPreferences = student.jobCompanyPreferences.filter(
                (item: any) => (!isEmpty(item) && item?.companyId != null && item?.companyId != '') || !isEmpty(item?.otherCompany)
            );
        }

        if (student.educationCountryPreferences) {
            student.educationCountryPreferences = student.educationCountryPreferences
                .filter((item: any) => !isEmpty(item) && !isEmpty(item.countryCode))
                .map((item: any, index: number) => ({ ...item, preferenceOrder: index + 1 }));
        }

        if (student.educationFieldOfStudyPreferences) {
            student.educationFieldOfStudyPreferences = student.educationFieldOfStudyPreferences
                .filter((item: any) => !isEmpty(item))
                .map((item: any, index: number) => ({ ...item, preferenceOrder: index + 1 }));
        }

        if (student.educationUniversityPreferences) {
            student.educationUniversityPreferences = student.educationUniversityPreferences
                .filter((item: any) => !isEmpty(item) && (!isEmpty(item.universityId) || !isEmpty(item?.otherUniversity)))
                .map((item: any, index: number) => ({ ...item, preferenceOrder: index + 1 }));
        }

        //Benefits
        let benefits = { filtersCountry: '', filtersCourse: '', filtersIndustry: '', filtersFunctionalArea: '' };
        if (student.benefits) {
            benefits.filtersCountry = student?.benefits?.filtersCountry.map((country: any) => country.filtersCountry).join(',');
            benefits.filtersCourse = student?.benefits?.filtersCourse.map((country: any) => country.filtersCourse).join(',');
            benefits.filtersIndustry = student?.benefits?.filtersIndustry.map((country: any) => country.filtersIndustry).join(',');
            benefits.filtersFunctionalArea = student?.benefits?.filtersFunctionalArea
                .map((country: any) => country.filtersFunctionalArea)
                .join(',');
            student.benefits = { ...student.benefits, ...benefits };
        }

        if (student?.benefits && student?.educationPreferenceInfo && !isEmpty(student?.benefits?.eventsPursuePgDegree)) {
            student.educationPreferenceInfo = {
                ...student?.educationPreferenceInfo,
                doYouRequireApplicationSupportForPgAdmission: student?.benefits?.eventsPursuePgDegree,
            };
        }

        if (student?.benefits && student?.jobPreferenceInfo && !isEmpty(student?.benefits?.eventsNotifyJobReferrals)) {
            student.jobPreferenceInfo = {
                ...student?.jobPreferenceInfo,
                interestedInJobReferrals: student?.benefits?.eventsNotifyJobReferrals,
            };
        }

        //Skills
        if (student.skills) {
            let currentSkills = student.skills;
            const filteredSkills = currentSkills.filter((skill: any) => skill.skillCode !== undefined && skill.skillCode !== '');
            student.skills = filteredSkills;
        }

        if (student.skillInfo) {
            const targetSkillsCSV = student?.targetSkills
                ?.filter((skill: any) => skill.skillCode !== undefined && skill.skillCode !== '')
                ?.map((skill: any) => skill.skillCode)
                ?.join(',');

            student.skillInfo = { ...student.skillInfo, targetSkillCode: targetSkillsCSV ?? '' };
        }

        // let jobs: any = [];
        // student.jobs.forEach((item: any, idx: any) => {
        //     jobs[idx] = {...item, companyId:item.companyName}
        // });
        // student.jobs = jobs;
        console.log(student, 'student');
        return { ...student };
    }

    static toStudentAdditionalInfoDto(studentAdditionalInfo: any) {
        //studentAdditionalInfo.targetSkills = [];
        //studentAdditionalInfo.existingSkills = [];
        studentAdditionalInfo.skillInfo = studentAdditionalInfo.skillInfo ?? {};
        studentAdditionalInfo.skills = studentAdditionalInfo?.skills ?? [];

        //studentAdditionalInfo.skillInfo = studentAdditionalInfo?.skillInfo ?? {};
        //studentAdditionalInfo.skills = studentAdditionalInfo?.skills ?? {};
        studentAdditionalInfo.educationPreferenceInfo = studentAdditionalInfo?.educationPreferenceInfo ?? {};
        studentAdditionalInfo.jobPreferenceInfo = studentAdditionalInfo?.jobPreferenceInfo ?? {};
        studentAdditionalInfo.mentoringInterests = studentAdditionalInfo?.mentoringInterests ?? {};

        studentAdditionalInfo.targetSkills = studentAdditionalInfo.skillInfo?.targetSkillCode
            ?.split(',')
            ?.map((skill: any) => ({ skillCode: skill }));

        studentAdditionalInfo.educationCountryPreferences = studentAdditionalInfo.educationCountryPreferences.sort(
            (a: any, b: any) => a.preferenceOrder - b.preferenceOrder
        );

        studentAdditionalInfo.educationFieldOfStudyPreferences = studentAdditionalInfo.educationFieldOfStudyPreferences.sort(
            (a: any, b: any) => a.preferenceOrder - b.preferenceOrder
        );

        studentAdditionalInfo.educationUniversityPreferences = studentAdditionalInfo.educationUniversityPreferences.sort(
            (a: any, b: any) => a.preferenceOrder - b.preferenceOrder
        );

        studentAdditionalInfo.currentSkills = studentAdditionalInfo.skills.filter((skill: any) => skill.skillLevelCode != null) ?? [];

        return studentAdditionalInfo;
    }

    static async pageSave(id: string, pageName: string, data: any): Promise<AxiosResponse<any, any>> {
        // TODO: Refactor this to depend on teh nodes rather than the pages
        const pagesBasedOnStudentProfile = [
            'alumni-basic-info',
            'alumni-program-info',
            'alumni-profile-picture',
            'alumni-undergraduate',
            'alumni-scholarship',
            'alumni-entrance-test',
            'alumni-postgraduate',
            'alumni-professional-work',
            'alumni-entrepreneurship',
            'alumni-entrepreneurship-outcome',
            'alumni-outcome-work',
            'alumni-benefits',
            'alumni-mentoring-interests',
            'student-basic-info',
            'student-education',
            'student-entrance-exams',
            'admin-basic-info',
            'admin-additional-info',
            'admin-preferences',
            'student-took-a-break',
            'alumni-program-outcomes',
            'student-school-outcomes',
            'alumni-pg-details',
            'student-education-subjectwise-marks',
            'alumni-teaching-work',
            'alumni-teaching-outcome-work',
            'alumni-professional-practice',
            'alumni-professional-practice-outcome',
            'defence-work-experience',
            'defence-experience-outcome',
            'alumni-freelancing',
            'alumni-freelancing-outcome'
        ];

        const url = pagesBasedOnStudentProfile.includes(pageName)
            ? `${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/profile`
            : `${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/additional-info`;

        if (['alumni-benefits', 'alumni-mentoring-interests'].includes(pageName)) {
            axiosClient().put(`${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/additional-info`, data);
        }

        return axiosClient().put(url, data);
    }

    static async SaveProfileImagePath(state: any): Promise<AxiosResponse<any, any>> {
        const imagePath = state.data.profile.profilePictureFilePath;
        const id = state.routeInfo.id;
        const data = { id: id, imagePath: imagePath };
        const url = `${process.env.REACT_APP_COMMON_API}/users/update-profile-image-path`;
        return axiosClient().post(`${url}`, data);
    }

    // static getStudentPassOutYear = (student: any) => student?.profile?.passOutYear ?? 2000;
    static getStudentPassOutYear = (student: any) => {
        const defaultPassoutYear = 1980;
        if (!student?.userInstitutes || student.userInstitutes.length === 0) {
            return student?.profile?.passOutYear ?? defaultPassoutYear;
        }

        const minPassOutYear = student.userInstitutes.reduce((minYear: number | null, institute: any) => {
            if (institute.passOutYear && (!minYear || institute.passOutYear < minYear)) {
                return institute.passOutYear;
            }
            return minYear;
        }, null);

        return minPassOutYear ?? student?.profile?.passOutYear ?? defaultPassoutYear;
    };
}
export default StudentService;
