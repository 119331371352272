import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import {
    handleCompleteLater,
    handleNext,
    onCurrentJobSelectionChange,
} from './DefenceWorkActions';
import DefenceWorkLayout from './DefenceWorkLayout';

const DefenceWork = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState('');

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            NEXT: handleNext,
            I_LL_DO_THIS_LATER: handleCompleteLater,
            ROUTER_NAVIGATE: navigate,
            isCurrent: onCurrentJobSelectionChange,
            REDIRECT: setRedirectUrl
        },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);

        const jobRoleCodeDomain = domain.get('JOB_ROLE_CODE');
        if (jobRoleCodeDomain) {
            const filteredJobRoleCode = jobRoleCodeDomain.filter((role) => role.code !== 'VISITING_FACULTY' && role.code !== 'FREELANCER');
            domain.set('JOB_ROLE_CODE', filteredJobRoleCode);
        }

            const defenceCompanies = domain.get('COMPANY_CODE');
            if (defenceCompanies) {
                const filteredJobRoleCode = defenceCompanies.filter((company) => {
                    const flexCol = company.flexCol;
                    return flexCol && JSON.parse(flexCol).is_defence == 1;
                });
                domain.set('COMPANY_CODE', filteredJobRoleCode);
            }
                
            dispatch({
                    type: 'DATA_INIT',
                    payload: { formConfig, data, domain, routeInfo },
                });
            }, []);

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    let message = `In your work status, you mentioned that you are currently working in defence. Please provide the details of your service.`

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <DefenceWorkLayout>{!state.flags.isDataLoading && <PageBuilder data={message}/>}</DefenceWorkLayout>
        </SmartContext.Provider>
    );
};

export default DefenceWork;
