import axios from 'axios';
import { activateUserSession, isEmpty, isFormValid, setError } from '../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { axiosClient } from '../../../../library/Service/axiosClient';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
// import { toStudentEntity } from '../../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';
import { isValidIsCurrentAsPerWorkingStatus } from '../professional-work/ProfessionalWorkActions';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';
    const isFormValidState = isFormValid(state, dispatch, ['jobsApplied.*.companyId', 'entreprenurship.companyId']);
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);
    const inValidDate = isValidDate(state, dispatch);

    if (!isImpersonatedSession && (!isFormValidState || !customValidationPassed || !inValidDate)) {
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    const entreprenurship = Array.isArray(state?.data['entreprenurship'])
        ? state.data['entreprenurship']
        : [state.data['entreprenurship']];

    const updatedEntreprenurship = entreprenurship.map((job) => {
        const existingJob = state?.data?.jobsList?.find((existingJob: any) => existingJob.id === job.id);
        const updatedJob = {
            ...job,
            recordTypeCode: existingJob?.recordTypeCode || job.recordTypeCode || 'JOINED',
            jobCategory: 'ENTREPRENEURSHIP',
            designation: 'Founder',
            endDate: job.isCurrent ? null : job.endDate
        };

        return updatedJob;
    });

    const jobsNotInEntreprenurship = state?.data?.jobsList?.filter((existingJob: any) =>
        !entreprenurship.some((job: any) => job.id === existingJob.id)
    );

    const jobHistory = [
        ...updatedEntreprenurship,
        ...jobsNotInEntreprenurship,
        ...state?.data?.jobsApplied || []
    ];

     //For checking is_current as per WorkingStatus
    const isValidIsCurrent = isValidIsCurrentAsPerWorkingStatus('entreprenurship.startDate',jobHistory,state, dispatch);
    if (!isValidIsCurrent) {
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }
    // Send event to Moengage
    //sendMoEngageEvent(state?.data);

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            jobs: jobHistory,
            profile: state?.data['profile'],
        })
    ).then((response) => {
        if (response?.status === 200) {
            navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch, sessionState, sessionDispatch);
        }
    });
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    let isFormInvalid = false;
    const jobs = state.data.jobsApplied;
    const entreprenurship = state.data.entreprenurship;

    if (isEmpty(entreprenurship?.companyId) && isEmpty(entreprenurship?.otherCompany)) {
        isFormInvalid = true;
        setError(`entreprenurship.companyId`, [`Please enter "Name of the Company"`], dispatch);
    } else {
        setError(`entreprenurship.companyId`, [], dispatch);
    }

    jobs.forEach((job: any, index: number) => {
        if (state?.data?.profile?.appliedForThePlacement) {
            if (isEmpty(job?.companyId) && isEmpty(job?.otherCompany)) {
                isFormInvalid = true;
                setError(`jobsApplied.${index}.companyId`, [`Please enter "Name of the Company"`], dispatch);
            } else {
                setError(`jobsApplied.${index}.companyId`, [], dispatch);
            }
        }else{
            setError(`jobsApplied.${index}.companyId`, [], dispatch); //clear error appliedForThePlacement set to false
        }
    });

    // Dispatch the validation errors to the state
    if ( isFormInvalid ) {
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'jobsApplied', errorMessages },
        });
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'entreprenurship', errorMessages },
        });
    }
    
    return !isFormInvalid;
};

export const onCurrentJobSelectionChange = (props: ControlOnChangeArguments) => {
    if(!props.state.data.entreprenurship?.isCurrent){
     setError(`entreprenurship.startDate`, [], props.dispatch);
     props.dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey: `${props.parentDataKey}.industryCode`, value: [] } });
    }
}
 
export const onEndDateChange = (props: ControlOnChangeArguments) => {
    const errorMessages =
        new Date(props.value) < new Date(props.row.startDate) ? ['End year should be greater than or equal to Start year'] : [];
    props.errorMessages.push(errorMessages);
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
};

const isValidDate = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const job = state?.data['entreprenurship'];
    let isFormValid = true;

        if (isEmpty(job?.startDate)) {
            setError(`entreprenurship.startDate`, [`Please enter "Start Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`entreprenurship.startDate`, [], dispatch);
        }

        // if ((job?.isCurrent === false || isEmpty(job?.isCurrent)) && isEmpty(job?.endDate)) {
        //     setError(`entreprenurship.endDate`, [`Please enter "End Date" `], dispatch);
        //     isFormValid = false;
        // } else {
        //     setError(`entreprenurship.endDate`, [], dispatch);
        // }

        // if (new Date(job?.startDate) >= new Date(job?.endDate)) {
        //     console.log('Invalid date');
        // }
        // if (job?.isCurrent === false || isEmpty(job?.isCurrent)) {
        //     if (new Date(job?.startDate) > new Date(job?.endDate)) {
        //         setError(`entreprenurship.endDate`, [`End year should be greater than or equal to Start year`], dispatch);
        //         isFormValid = false;
        //     } else {
        //         setError(`entreprenurship.endDate`, [], dispatch);
        //     }
        // }

    return isFormValid;
};

const sendMoEngageEvent = async (user: any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-work-experience`, {
            userInfo: user,
        });
        // console.log('Response:', response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
