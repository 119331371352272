import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import { handleCompleteLater, handleNext, onCurrentJobSelectionChange } from './JobOutcomeActions';
import JobOutcomeLayout from './JobOutcomeLayout';
import { getDomainValueForCode } from '../../../../library/Core/SmartFunctions';

const JobOutcome = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState('');

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            NEXT: handleNext,
            I_LL_DO_THIS_LATER: handleCompleteLater,
            ROUTER_NAVIGATE: navigate,
            isCurrent: onCurrentJobSelectionChange,
            REDIRECT: setRedirectUrl
            //startDate: onStartDateChange,
            //endDate: onEndDateChange,
        },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    let program_name = getDomainValueForCode(state?.data?.selectedInstitute?.programId,'ALL_PROGRAM_CODE',state);
    let institute_name = getDomainValueForCode(state?.data?.selectedInstitute?.instituteId,'INSTITUTE_DOMAIN',state);
    let message = `After Completing ${program_name} from ${institute_name}, you mentioned that you joined an organization. Please provide the details of first organization you joined after your program completion from the institute`

    useEffect(() => {
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        data['jobOutcome'] = data['jobOutcome'] || {};
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <JobOutcomeLayout>{!state.flags.isDataLoading && <PageBuilder data={message}/>}</JobOutcomeLayout>
        </SmartContext.Provider>
    );
};

export default JobOutcome;
