import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

const AddSpotlights: React.FC = () => {
    const [primaryColor, setPrimaryColor] = useState('#ff0000'); // Default to red
    const [secondaryColor, setSecondaryColor] = useState('#ffffff'); // Default to white
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState<{ id: number; name: string; profilePicture: string }[]>([]);
    const jwtToken = localStorage.getItem('user-jwt-token');
    const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedName, setSelectedName] = useState('User Name');
    const [schoolImage, setSchoolImage] = useState<string>(localStorage.getItem('school-image') || '');
    const [headline, setHeadline] = useState<string>('Profile Headline Mentioning For Spotlight');
    const divRef = useRef<HTMLDivElement>(null);
    const previewRef = useRef<Window | null>(null);
    const sesinstituteId = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')!).primaryInstitute : 0;
    const defaultProfileImage = 'https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/profile_pic.png';
    const [profileImage, setProfileImage] = useState<string>(defaultProfileImage); // Logs the stored value

    const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);

        if (query.trim().length > 3) {
            setLoading(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/pre-signup/get-student-name`, {
                    name: query,
                    id: sesinstituteId,
                });

                if (response.data?.success && Array.isArray(response.data.result)) {
                    const parsedSuggestions = response.data.result.map(
                        (
                            item: { first_name: string | null; last_name: string | null; profile_picture_file_path: string | null },
                            index: number
                        ) => ({
                            id: index,
                            name: `${item.first_name || ''} ${item.last_name || ''}`.trim() || 'Unknown',
                            profilePicture: item.profile_picture_file_path || defaultProfileImage, // Use default if null
                        })
                    );
                    setSuggestions(parsedSuggestions);
                    setIsSuggestionsVisible(true);
                } else {
                    setSuggestions([]);
                    setIsSuggestionsVisible(false);
                }
            } catch (error) {
                console.error('Error fetching suggestions:', error);
                setSuggestions([]);
                setIsSuggestionsVisible(false);
            } finally {
                setLoading(false);
            }
        } else {
            setSuggestions([]);
            setIsSuggestionsVisible(false);
        }
    };
    useEffect(() => {
        const fetchSchoolLogo = async () => {
            try {
                const user = sessionStorage.getItem('user');
                const primaryInstitute = user ? JSON.parse(user).primaryInstitute : 0;

                const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/pre-signup/get-institute-name`, {
                    id: primaryInstitute,
                });

                if (response.data?.success && response.data.result?.[0]?.logo) {
                    const logoUrl = response.data.result[0].logo;
                    setSchoolImage(logoUrl);
                } else {
                    console.warn('No school logo returned from the API. Using fallback image.');
                }
            } catch (error) {
                console.error('Error fetching school logo:', error);
            }
        };

        fetchSchoolLogo();
    }, []);
    const handleSuggestionClick = (name: string, profilePicture: string) => {
        setSearchQuery(name);
        setSelectedName(name);
        setProfileImage(profilePicture); // Set the profile image dynamically
        setIsSuggestionsVisible(false);
    };
    useEffect(() => {
        const userJwtToken = sessionStorage.getItem('user-jwt-token');

        if (!userJwtToken) {
            window.location.href = '/404'; // Redirect to 404 page
        }
    }, []);
    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) {
            alert('No file selected.');
            return;
        }

        const fileType = file.type.toLowerCase();
        if (fileType === 'image/jpeg' || fileType === 'image/jpg') {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target?.result) {
                    setProfileImage(e.target.result as string);
                }
            };
            reader.onerror = () => {
                alert('Failed to read the file. Please try again.');
            };
            reader.readAsDataURL(file);
        } else {
            alert('Only JPG or JPEG images are allowed.');
        }
    };

    const handleDownload = async () => {
        if (!divRef.current) {
            console.error('Element not found!');
            return;
        }

        const images = Array.from(divRef.current.querySelectorAll('img')) as HTMLImageElement[];

        try {
            await Promise.all(
                images.map((img) => {
                    return new Promise((resolve, reject) => {
                        if (img.complete && img.naturalWidth > 0) {
                            resolve(true);
                        } else {
                            img.onload = () => resolve(true);
                            img.onerror = () => reject(false);
                        }
                    });
                })
            );

            const { default: html2canvas } = await import('html2canvas');
            const scaleFactor = 2; // Increase scale factor for better quality (400px / 400px = 2.7, so 3 is safe)
            const canvas = await html2canvas(divRef.current, {
                backgroundColor: secondaryColor,
                scale: scaleFactor, // Increase scale factor
                useCORS: true,
                allowTaint: false,
            });

            const scaledCanvas = document.createElement('canvas');
            scaledCanvas.width = 400; // Set desired width
            scaledCanvas.height = 400; // Set desired height
            const scaledContext = scaledCanvas.getContext('2d');

            if (scaledContext) {
                scaledContext.fillStyle = '#ffffff';
                scaledContext.fillRect(0, 0, 400, 400);
                scaledContext.drawImage(canvas, 0, 0, 400, 400);

                const dataUrl = scaledCanvas.toDataURL('image/png', 1.0); // Use maximum quality
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'spotlights-pic.jpg'; // Updated file name
                link.click();
            }
        } catch (error) {
            console.error('Error generating image:', error);
        }
    };

    const handlePreview = async () => {
        if (!divRef.current) {
            console.error('Element not found!');
            return;
        }

        const { default: html2canvas } = await import('html2canvas');
        const scaleFactor = 3; // Increase scale factor for better quality
        const canvas = await html2canvas(divRef.current, {
            backgroundColor: secondaryColor,
            scale: scaleFactor, // Increase scale factor
            useCORS: true,
            allowTaint: false,
        });

        if (!previewRef.current || previewRef.current.closed) {
            const screenWidth = window.screen.width;
            const screenHeight = window.screen.height;
            const popupWidth = 400; // Set desired width
            const popupHeight = 400; // Set desired height
            const left = (screenWidth - popupWidth) / 2;
            const top = (screenHeight - popupHeight) / 2;
            previewRef.current = window.open('', 'Preview', `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`);
        }

        if (previewRef.current) {
            previewRef.current.document.body.innerHTML = '';
            previewRef.current.document.write(
                '<html><head><title>Preview</title></head><body style="margin: 0; display: flex; align-items: center; justify-content: center; background: #ffffff;"></body></html>'
            );
            const img = document.createElement('img');
            img.src = canvas.toDataURL('image/png', 1.0); // Use maximum quality
            img.style.width = '100%';
            img.style.height = 'auto';
            previewRef.current.document.body.appendChild(img);
        }
    };

    const handleShareOnSocialMedia = async () => {
        if (!divRef.current) {
            console.error('Element not found!');
            return;
        }

        const { default: html2canvas } = await import('html2canvas');
        const scaleFactor = 3; // Increase scale factor for better quality
        const canvas = await html2canvas(divRef.current, {
            backgroundColor: secondaryColor,
            scale: scaleFactor,
            useCORS: true,
            allowTaint: false,
        });

        const dataUrl = canvas.toDataURL('image/png', 1.0); // Use maximum quality
        const imageBlob = dataURLtoBlob(dataUrl); // Convert base64 to Blob

        // Step 2: Upload the image to S3 via your API
        const apiUrl = `${process.env.REACT_APP_BROADCAST_SERVER}/upload/get-attached`;
        const formData = new FormData();
        formData.append('pdf_doc', imageBlob, 'spotlight_image.png');

        try {
            // Include the token in the headers for authentication
            const response = await axios.post(apiUrl, formData, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`, // Include the token in the Authorization header
                },
            });

            const link = response.data.link;

            if (response.data.success) {
                // Step 3: Store the S3 URL in sessionStorage
                sessionStorage.setItem('spotlight_post_image_url', link);

                // Redirect to the "get-image" page
                window.location.href = '/socialmedia/socialpost?social_post=1'; // Adjust this to your route for displaying the image
            } else {
                alert('Error uploading image to S3');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            alert('Failed to upload image.');
        }
    };

    // Utility function to convert data URL to Blob
    function dataURLtoBlob(dataUrl: string): Blob {
        const [metadata, base64] = dataUrl.split(',');
        const byteString = atob(base64);
        const mimeString = metadata.split(':')[1].split(';')[0];

        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }

        return new Blob([uintArray], { type: mimeString });
    }

    const styles = {
        dropdownContainer: {
            position: 'relative' as 'relative',
            width: '100%',

            margin: '20px auto',
        },
        suggestionsDropdown: {
            position: 'absolute' as 'absolute',
            top: '100%',
            left: '0',
            width: '100%',
            backgroundColor: '#ffffff',
            border: '1px solid #ccc',
            borderRadius: '4px',
            zIndex: 1000,
            maxHeight: '200px',
            overflowY: 'auto' as 'auto',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
        },
        suggestionItem: {
            padding: '10px',
            cursor: 'pointer',
            transition: 'background-color 0.2s',
        },
        loading: {
            marginTop: '5px',
            color: '#999',
            fontSize: '14px',
        },
    };

    return (
        <div className="main flex-1 bg-white">
            <section className="alumni_directory_details">
                <div className="max-1140 mx-auto pt-5"></div>
                <div className="max-1140 pb-5 overflow-hidden mx-auto">
                    <div className="row g-4">
                        <div className="col-sm-12">
                            <div className="bg-gray-F2F4F7 border-radius-6 p-3 p-sm-4 px-md-5 text-center font-24 font-500">
                                Alumni in Spotlight
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="row g-2 justify-content-between pb-2">
                                <div className="col-auto font-20 font-500">
                                    Category <span className="font-18 font-400">Success Stories</span>
                                </div>
                                <div className="col-auto font-20 font-500">
                                    Size <span className="font-18 font-400">Card Post Size- 400 x 400 px</span>
                                </div>
                            </div>
                            <div className="bg-gray-F2F4F7 border-radius-6 p-3 p-sm-4">
                                <div className="row g-4">
                                    <div className="col-md-6">
                                        <div className="w-100 h-100">
                                            <div className="bg-white border-radius-6 p-4 w-100 border h-100 d-flex justify-content-center align-items-center">
                                                <div className="alumni-card-wrap p-0 w-100">
                                                    <div className="alumni-card-slider">
                                                        <div
                                                            className="alumni-card"
                                                            style={{ backgroundColor: secondaryColor }}
                                                            ref={divRef}>
                                                            <i className="curve" style={{ backgroundColor: primaryColor }}></i>
                                                            <div className="alumni-detail">
                                                                <div className="alumni-img-wrap">
                                                                    <div className="alumni-img">
                                                                        <img
                                                                            src={profileImage}
                                                                            width="80"
                                                                            className="img-fluid"
                                                                            alt="Profile Pic"
                                                                            crossOrigin="anonymous"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <h3>{selectedName}</h3>
                                                                <h4>{headline}</h4>
                                                            </div>
                                                            <div className="alumni-logo">
                                                                <a href="javascript:;" className="alumni-link">
                                                                    <img
                                                                        src={schoolImage} // Fallback to `undefined` if `base64Image` is `null`
                                                                        width="90"
                                                                        alt="Logo"
                                                                        crossOrigin="anonymous"
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="bg-white border-radius-6 p-4 h-100">
                                            <div className="h-100 pt-3">
                                                <form className="row g-3">
                                                    <div className="col-md-12">
                                                        <div className="row g-2 align-items-center">
                                                            <div className="col">
                                                                <label className="form-label m-0 mb-0 font-16 font-600">
                                                                    ADD ALUMNI PROFILE
                                                                </label>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div style={styles.dropdownContainer}>
                                                                    <label htmlFor="search">Search by Alumni</label>
                                                                    <input
                                                                        id="search"
                                                                        type="text"
                                                                        value={searchQuery}
                                                                        onChange={handleSearchChange}
                                                                        className="form-control"
                                                                        placeholder="Enter Alumni name / Email ID"
                                                                        style={{
                                                                            width: '100%',
                                                                            padding: '10px',
                                                                            borderRadius: '4px',
                                                                            border: '1px solid #ccc',
                                                                        }}
                                                                    />
                                                                    {loading && <div style={styles.loading}>Loading...</div>}
                                                                    {isSuggestionsVisible && suggestions.length > 0 && (
                                                                        <div style={styles.suggestionsDropdown}>
                                                                            {suggestions.map((suggestion) => (
                                                                                <div
                                                                                    key={suggestion.id}
                                                                                    className="suggestion-item"
                                                                                    style={styles.suggestionItem}
                                                                                    onClick={() =>
                                                                                        handleSuggestionClick(
                                                                                            suggestion.name,
                                                                                            suggestion.profilePicture
                                                                                        )
                                                                                    }>
                                                                                    {suggestion.name}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 text-center">OR</div>
                                                    <div className="col-12">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Alumni Name"
                                                            value={selectedName}
                                                            onChange={(e) => setSelectedName(e.target.value)}
                                                            style={{
                                                                marginTop: '20px',
                                                                width: '100%',
                                                                padding: '10px',
                                                                borderRadius: '4px',
                                                                border: '1px solid #ccc',
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Profile Headline mentioning reason for spotlight "
                                                            value={headline}
                                                            onChange={(e) => setHeadline(e.target.value)}
                                                            maxLength={65}
                                                        />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="upload_section">
                                                            <div className="row g-2 align-items-center justify-content-center">
                                                                <div className="col-sm d-flex align-items-center justify-content-center">
                                                                    <div className="cloud-wrap px-3">
                                                                        <div className="cloud-img"></div>
                                                                        <div className="cloud-text d-flex align-items-center">
                                                                            <label
                                                                                htmlFor="uploadImage"
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    padding: '10px 20px',
                                                                                    backgroundColor: '#007bff',
                                                                                    color: '#fff',
                                                                                    borderRadius: '4px',
                                                                                    textDecoration: 'none',
                                                                                    display: 'inline-block',
                                                                                }}>
                                                                                Upload Profile Picture
                                                                            </label>
                                                                            <input
                                                                                type="file"
                                                                                id="uploadImage"
                                                                                accept=".jpg, .jpeg,.png"
                                                                                onChange={handleImageUpload}
                                                                                style={{ display: 'none' }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label className="form-label m-0 mb-1 font-16 font-500">Primary Color Shade</label>
                                                        <input
                                                            type="color"
                                                            value={primaryColor}
                                                            onChange={(e) => setPrimaryColor(e.target.value)}
                                                            className="form-control form-search radius-25"
                                                        />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label className="form-label m-0 mb-1 font-16 font-500">
                                                            Secondary Color Shade
                                                        </label>
                                                        <input
                                                            type="color"
                                                            value={secondaryColor}
                                                            onChange={(e) => setSecondaryColor(e.target.value)}
                                                            className="form-control form-search radius-25"
                                                        />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="action_btns max-100per mt-0">
                                <button type="button" className="nextbtn my-2 my-sm-0 mx-1" onClick={handlePreview}>
                                    <span className="">Preview</span>
                                </button>
                                <button type="button" className="btn btn-green my-2 my-sm-0 mx-1 height-45" onClick={handleDownload}>
                                    <span className="">Save as Image</span>
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-border-gray my-2 my-sm-0 mx-1 height-45"
                                    onClick={handleShareOnSocialMedia}>
                                    <span className="px-3">Share on Social Media</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AddSpotlights;
