import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { onSignUp } from './SignupSocialActions';
import SignupSocialLayout from './SignupSocialLayout';
import formConfig from './socialSignupFormConfig.json';
import { getInstituteProgramDomain } from '../../../library/Service/domainService';
import SessionContext from '../../../library/Core/SessionContext';
import { GlobalContext } from '../../../library/Core/GlobalContext';

const SignupSocial = () => {
    const navigate = useNavigate();
    const { globalState } = useContext(GlobalContext);
    const { sessionState, sessionDispatch } = useContext(SessionContext);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { SIGN_UP: onSignUp, ROUTER_NAVIGATE: navigate },
    } as any);

    const { domain, internal, routeInfo } = useLoaderData() as State;

    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    const email = localStorage.getItem('email');
    const mobileNumber = localStorage.getItem('mobileNumber');
    const profilePicture = localStorage.getItem('profilePicture');
    const linkedInId = localStorage.getItem('linkedInId');
    const linkedInToken = localStorage.getItem('linkedInToken');
    const facebookId = localStorage.getItem('facebookId');
    const facebookToken = localStorage.getItem('facebookToken');
    const googleId = localStorage.getItem('googleId');
    const googleToken = localStorage.getItem('googleToken');
    const loggedInPlatform = localStorage.getItem('loggedInPlatform');
    const invitedBy = localStorage.getItem('invitedBy');
    const invitedUserID = localStorage.getItem('invitedBy') ? atob(localStorage.getItem('invitedBy') ?? '')?.split('##')[0] : '';

    useEffect(() => {
        const loadSchoolSettings = async () => {
            let schoolId = '';
            const schoolData = JSON.parse(localStorage.getItem('school_settings') ?? '');
            const programs = JSON.parse(localStorage.getItem('school_programs') ?? '');
            sessionStorage.setItem('school-image', schoolData.logo);
            
            if (localStorage.getItem('user-logged-out') != 'TRUE') {
                sessionDispatch({ type: 'SET', payload: { schoolSetting: schoolData, programs: programs } });
            }
            schoolId = schoolData.id;

            if (schoolId == null) alert('School not found. Please contact your school admin.');

            const domain = new Map([...globalState.domain]);
            domain.set('INSTITUTE_PROGRAM_CODE', getInstituteProgramDomain(programs));

            dispatch({
                type: 'DATA_INIT',
                payload: {
                    formConfig,
                    data: { user: { 
                        schoolId: schoolId as any,
                        provideUpdatesOnWhatsapp: true,
                        agreeToUnivariety: false,
                        firstName : firstName,
                        lastName : lastName,
                        registeredEmailId : email,
                        mobileNumber : mobileNumber,
                        profilePicture : profilePicture,
                        linkedInId : linkedInId,
                        linkedInToken : linkedInToken,
                        facebookId : facebookId,
                        facebookToken : facebookToken,
                        googleId : googleId,
                        googleToken : googleToken,
                        loggedInPlatform : loggedInPlatform,
                        invitedBy: invitedBy,
                        invitedUserID: invitedUserID
                    } },
                    domain,
                    routeInfo: { },
                },
            });
        };

        loadSchoolSettings();
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <SignupSocialLayout>{!state.flags.isDataLoading && <PageBuilder />}</SignupSocialLayout>
        </SmartContext.Provider>
    );
};

export default SignupSocial;