import { activateUserSession, getDomainValueForCode, isEmpty, isFormValid, setError, syncProfileMarker } from '../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { axiosClient } from '../../../../library/Service/axiosClient';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
// import { toStudentEntity } from '../../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';
import { isValidIsCurrentAsPerWorkingStatus } from '../professional-work/ProfessionalWorkActions';
import levelOfSeniorityData from './levelOfSeniority.json';
type RankLevels = {
    [rank: string]: string; 
};
  
type LevelOfSeniorityData = {
    [companyName: string]: RankLevels;  
};
const data = levelOfSeniorityData as LevelOfSeniorityData;

interface DomainItem {
    categoryCode: string;
    value: string;
    code: string;
}

interface Domain {
    [key: string]: DomainItem[];
}

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const inValidDate = isValidDate(state, dispatch);
    const isValidDesignation = isValidDesignationRank(state, dispatch);
    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);
    
    if (!isImpersonatedSession && (!customValidationPassed || !inValidDate || !isValidDesignation || !isFormValid(state, dispatch))) {
        console.log("Form validation errors:", state?.formValidationErrors);
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    let defenceWork = Array.isArray(state?.data['defenceWork']) 
        ? state?.data['defenceWork']
        : state?.data['defenceWork'] && typeof state?.data['defenceWork'] === 'object' 
            ? [state?.data['defenceWork']] 
            : [];

    defenceWork = defenceWork.filter((defence: any) => defence?.companyId);

    const IndustryData = Array.from(globalState?.domain || [])
        .filter(([key, value]: any) => value.some((item: any) => item.categoryCode === 'INDUSTRY_CODE'))
        .reduce((result: any, [key, value]: any) => {
            value.forEach((item: any) => {
                if (item.categoryCode === 'INDUSTRY_CODE') {
                    result[item.value] = item.code;
                }
            });
            return result;
        }, {});

    const updatedDefenceWork = defenceWork.map((defence: any) => {
        const existingJob = state?.data?.jobsList?.find((existingJob: any) => existingJob.id === defence.id);
        return updateJobDetails(defence, existingJob, IndustryData, state);
    });

    const jobsNotInDefenceWork = state?.data?.jobsList?.filter((existingJob: any) => 
        !defenceWork.some((job: any) => job.id === existingJob.id)
    );
    defenceWork = [...updatedDefenceWork, ...jobsNotInDefenceWork];

    const jobAppliedRecords = state?.data?.jobsApplied ?? [];
    const jobHistory = [...defenceWork, ...jobAppliedRecords];

     //For checking is_current as per WorkingStatus
    const isValidIsCurrent = isValidIsCurrentAsPerWorkingStatus('defenceWork',jobHistory,state, dispatch);
    if (!isValidIsCurrent) {
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            jobs: jobHistory,
            profile: state?.data['profile'],
        })
    ).then((response) => {
        if (response?.status === 200) {
            syncProfileMarker(state.routeInfo.id).then(() => {
                navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch, sessionState, sessionDispatch);
            });
        }
    });
};

const updateJobDetails = (defence: any, existingJob: any, IndustryData: any, state: State) => {
    const { designationRank, companyId, ...rest } = defence;
    const mappedIndustry = 'Law Enforcement and Security Services';
    const industryCode = IndustryData[mappedIndustry] ?? null;

    const seniorityLevelCode = getSeniorityLevelCode(
        getDomainValueForCode(companyId, 'COMPANY_CODE', state),
        getDomainValueForCode(designationRank, 'DEFENCE_RANK', state)
    );

    const updatedJob = {
        ...existingJob,
        ...rest,
        recordTypeCode: 'JOINED',
        jobCategory: 'DEFENCE',
        industryCode,
        designation: designationRank || '',
        seniorityLevelCode: seniorityLevelCode || '',
        companyId:companyId
    };
    if (defence.isCurrent) {
        updatedJob.endDate = null;
    }
    return updatedJob;
};


const getSeniorityLevelCode=(companyName:any,rank:any)=>{
    console.log("companyName",companyName);
    console.log("rank",rank);
    const companyData = data[companyName];
    if (!companyData) {
        return '';
    }
    const seniorityLevel = companyData[rank];
    if (!seniorityLevel) {
      return '';
    }
    return seniorityLevel;
}

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    let isFormInvalid = false;
    const defenceWork = state.data.defenceWork;
    defenceWork.forEach((defenceWork: any, index: number) => {
        if (isEmpty(defenceWork?.companyId) && isEmpty(defenceWork?.otherCompany)) {
            isFormInvalid = true;
            setError(`defenceWork.${index}.companyId`, [`Please enter "Name of the Company"`], dispatch);
        } else {
            setError(`defenceWork.${index}.companyId`, [], dispatch);
        }
    });

    // Dispatch the validation errors to the state
    if (isFormInvalid) {
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'defenceWork', errorMessages },
        });
    }
    return !isFormInvalid;
};

export const onCurrentJobSelectionChange = (props: ControlOnChangeArguments) => {
    const rowIndex = parseInt(props.parentDataKey.split('.').pop() as string);
    const jobs = props.state.data.defenceWork.map((row: any, index: number) => {
        setError(`defenceWork.${index}.endDate`, [], props.dispatch);
        return index == rowIndex ? { ...row, endYear: '', isCurrent: false } : { ...row, isCurrent: false };
    });
    props.dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey: `${props.parentDataKey}.jobStartAndEndDates`, value: [] } });

    props.dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'defenceWork', value: jobs } });
};

export const onStartDateChange = (props: ControlOnChangeArguments) => {
    alert(props.row.endDate);
    const errorMessages =
        new Date(props.value) > new Date(props.row.endDate) ? ['Start year should be less than or equal to End year'] : [];
    // props.dispatch({
    //     type: 'SET_FIELD_VALIDATION_ERRORS',
    //     payload: { dataKey: `${props.parentDataKey}.startDate`, errorMessages },
    // });

    props.errorMessages.push(errorMessages);
};

export const onEndDateChange = (props: ControlOnChangeArguments) => {
    const errorMessages =
        new Date(props.value) < new Date(props.row.startDate) ? ['End year should be greater than or equal to Start year'] : [];
    props.errorMessages.push(errorMessages);
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
    // state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch);
};

const isValidDate = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const defenceWorkData = state?.data['defenceWork'];
    let isFormValid = true;
    defenceWorkData.forEach((defenceWork: any, index: number) => {
        if (isEmpty(defenceWork?.startDate)) {
            setError(`defenceWork.${index}.startDate`, [`Please enter "Start Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`defenceWork.${index}.startDate`, [], dispatch);
        }

        if (
            (defenceWork?.isCurrent === false || isEmpty(defenceWork?.isCurrent)) &&
            isEmpty(defenceWork?.endDate)
        ) {
            setError(`defenceWork.${index}.endDate`, [`Please enter "End Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`defenceWork.${index}.endDate`, [], dispatch);
        }

        if (new Date(defenceWork?.startDate) >= new Date(defenceWork?.endDate)) {
            console.log('Invalid date');
        }

        if (defenceWork?.isCurrent === false || isEmpty(defenceWork?.isCurrent)) {
            if (
                isEmpty(defenceWork?.endDate) ||
                new Date(defenceWork?.startDate) > new Date(defenceWork?.endDate)
            ) {
                setError(`defenceWork.${index}.endDate`, [`End year should be greater than or equal to Start year`], dispatch);
                isFormValid = false;
            } else {
                setError(`defenceWork.${index}.endDate`, [], dispatch);
            }
        }
    });

    return isFormValid;
};


const isValidDesignationRank = (
    state: State, 
    dispatch: (dispatchEvent: DispatchEvent) => void
): boolean => {
    const defenceWorkData = state?.data['defenceWork'];
    let isFormValid = true;
    defenceWorkData.forEach((defenceWork: any, index: number) => {
        const companyId = defenceWork?.companyId; 
        const designationRank = defenceWork?.designationRank;
        const filteredRanks = state?.domain.get('DEFENCE_RANK')?.filter(rank => rank.parentCode === companyId);
        if (designationRank) {
            const isDesignationRankValid = filteredRanks?.some(rank => rank.code === designationRank);
            console.log(`Checking designationRank for index ${index}:`, designationRank, isDesignationRankValid);
            console.log("isDesignationRankValid",isDesignationRankValid)
            if (!isDesignationRankValid) {
                setError(`defenceWork.${index}.designationRank`, [`Please enter Designation Rank`], dispatch);
                isFormValid = false;
            } else {
                setError(`defenceWork.${index}.designationRank`, [], dispatch);
            }
        } else {
            setError(`defenceWork.${index}.designationRank`, [`Please enter Designation Rank`], dispatch);
            isFormValid = false;
        }
    });

    return isFormValid;
};

