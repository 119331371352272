import React, { useContext, useEffect , useState, useLayoutEffect } from 'react';
import { useLoaderData ,useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { SmartContext } from '../../library/Core/SmartContext';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import DebugBarControl from '../../library/SimpleControls/DebugBarControl';
import SessionContext from '../../library/Core/SessionContext';
import QnaApiService from './QnaApiService';
import { GlobalContext } from '../../library/Core/GlobalContext';
import { Tooltip } from 'bootstrap';
import { getDomainValueForCode } from '../../library/Core/SmartFunctions';

interface Vote {
    vote_type: number;
    answer_user_id: string;
}

interface Flag {
    is_appropriate: number;
    red_flag_user_id: string;
}

interface AlumniAllQueriesProps {
    selectedTime: string;
    selectedTag: string;
}

const AlumniAllQueries: React.FC<AlumniAllQueriesProps> = ({ selectedTime, selectedTag }) => {
      
    const { ids } = useParams<{ ids: string }>();
    const navigate = useNavigate();
    const { sessionState }: any = useContext(SessionContext);
    const { state, dispatch } = useContext(SmartContext);
    const { formConfig, data, internal } = useLoaderData() as State;
    const { globalState } = useContext(GlobalContext);
    const instituteGroupId = sessionState?.instituteGroupId;
    const userType = sessionState?.userTypeCode;

    const getUserId = (userType:any, sessionState:any) => {
        return userType === 'SUPER_ADMIN' 
        ? sessionState?.id 
        : (sessionState?.studentId ?? sessionState?.userId) ?? null;
    };
    const user_id = getUserId(userType, sessionState);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        getStudentAllQuery({});
    }, [selectedTime, selectedTag]);

    const getStudentAllQuery = async (searchVal: any): Promise<void> => {
        setLoading(true);
        setError(null);
        const params = `?selectedTime=${selectedTime}&selectedTag=${selectedTag}&instituteGroupId=${instituteGroupId}`;
        try {
            const response = await QnaApiService.getStudentAllQuery(params);
            dispatch({
                type: 'DATA_INIT',
                payload: {
                    formConfig,
                    data: { ...data, studenAllQuery: response?.data.data.questions ?? [] },
                    domain: globalState.domain,
                    internal,
                    flags: { isDataLoading: false }
                },
            });
        } catch (error) {
            console.error('Error fetching student queries:', error);
            setError('Failed to fetch queries. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    const studenAllQuery = state.data?.studenAllQuery || [];
 

    // Format date function
    const formatDate = (isoDateString: string): string => {
        const createdDate = new Date(isoDateString); // Convert ISO string to Date object

        // Format the date as per your requirement
        const formattedDate = `${createdDate.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        })} | ${createdDate.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        })}`;

        return formattedDate;
    };
    
    const handleAnswerQuestionClick = (question_id: string) => {
        navigate(`/answer-question?question_id=${question_id}`);
    };

    const handleViewMoreAnswer = (question_id: string) => {
        navigate(`/view-more-question?question_id=${question_id}`);
    };

    const handleRating = async (answer_id: string, type: string) => {
        const payload = {
            user_id,
            answer_id,
            type
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_QNA_API}v1/question/likeDislike`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const result = await response.json();
                getStudentAllQuery({});
                console.log('Rating submitted successfully:', result);
            } else {
                const result = await response.json();
                console.error('Failed to submit rating:', result.message);
            }
        } catch (error) {
            console.error('Error submitting rating:', error);
        }
    };


    const handleRedFlag = async (red_answer_id: string, is_appropriate: string) => {
        console.log('red_answer_id' ,red_answer_id);
        const payload = {
            user_id,
            red_answer_id,
            is_appropriate
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_QNA_API}v1/question/redFlag`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const result = await response.json();
                getStudentAllQuery({});
                console.log('Red Flag submitted successfully:', result);
            } else {
                const result = await response.json();
                console.error('Failed to submit rating:', result.message);
            }
        } catch (error) {
            console.error('Error submitting rating:', error);
        }
    };

    interface Student {
        user_type_code: 'STUDENT' | 'ALUMNI';
        program_name: string;
        completion_year: string;
        marks: string;
    }
    useLayoutEffect(() => {
        // Check if there are elements to initialize tooltips for
        if (studenAllQuery.some((query: Student) => query.user_type_code === 'STUDENT')) {
          const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
          const tooltipList = Array.from(tooltipTriggerList).map((tooltipTriggerEl) => {
            const tooltipInstance = new Tooltip(tooltipTriggerEl, {
              customClass: 'tooltip-html',
              trigger: 'hover',
              html: true,
            });
            return tooltipInstance;
          });
      
          // Clean up tooltips on component unmount
          return () => {
            tooltipList.forEach((tooltip) => tooltip.dispose());
          };
        }
      }, [studenAllQuery]); // Effect will run whenever studenAllQuery changes
      

    if (error) {
        return <div>{error}</div>;
    }

    const calculateCIEGrade = (cieMarks:any) => {
        const grades = [
            cieMarks?.AStar && `${cieMarks?.AStar}A*`,
            cieMarks?.A && `${cieMarks?.A}A`,
            cieMarks?.B && `${cieMarks?.B}B`,
            cieMarks?.C && `${cieMarks?.C}C`,
            cieMarks?.D && `${cieMarks?.D}D`,
            cieMarks?.E && `${cieMarks?.E}E`,
            cieMarks?.F && `${cieMarks?.F}F`,
        ];

        return grades.filter(Boolean).join(',');
    };
    
    return (
            <div className="post-wrap">
                {studenAllQuery.map((query: any) => (
                    <div className="post-block">
                        <div className="row g-3">
                            <div className="col-auto">
                                <div className="post-img">
                                    <img src={query.profile_picture_file_path} width="60" alt={query.first_name} />
                                </div>
                            </div>
                            <div className="col">
                                <div className="row g-2">
                                    <div className="col-12">
                                        <div className="row g-2">
                                            <div className="col-sm">
                                                <div className="post-head">POSTED BY</div>
                                                <div className="post-alumni-name">
                                                    {query.first_name} {query.last_name} 
                                                    {query.user_type_code === 'STUDENT' && (() => {
                                                            const displayMarks = (getDomainValueForCode(query.curriculumCode, 'SCHOOL_CURRICULUM_CODE', state)==='CAIE')  
                                                                ? calculateCIEGrade(query.cieGrade)  
                                                                : query.marks || 'NA'; 

                                                            const tooltipContent = `
                                                                <ul class='font-500'>
                                                                    <li>Program Name<span class='font-600 d-block text-black'>${query.program_name || 'NA'}</span></li>
                                                                    <li>Completion Year<span class='font-600 d-block text-black'>${query.completion_year || 'NA'}</span></li>
                                                                    <li>Marks and Grades<span class='font-600 d-block text-black'>${displayMarks}</span></li>
                                                                    <li>Country Name<span class='font-600 d-block text-black'>${query.country_name || 'NA'}</span></li>
                                                                    <li>College Name <span class='font-600 d-block text-black'>${query.inst_name || 'NA'}</span></li>
                                                                    <li>Course Name<span class='font-600 d-block text-black'>${query.study_name || 'NA'}</span></li>
                                                                </ul>`;

                                                            return (
                                                                <div
                                                                    className="btn btn-border-gray btn-html ms-3 radius-25 px-3 py-0 font-500"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-html="true"
                                                                    title={tooltipContent}>
                                                                    <span>Info</span>
                                                                </div>
                                                            );
                                                        })()}

                                                </div>
                                               
                                                <div className="post-alumni-batch">{query.program_name}, {query.pass_out_year}</div>
                                            </div>
                                            <div className="col-sm-auto">
                                                <div className="d-flex flex-column">
                                                    <span className="font-14 mb-3">{formatDate(query.created)}</span>
                                                    <button className="btn btn-dark-blue text-white height-40 w-auto" onClick={() => handleAnswerQuestionClick(query.question_id)}><span>Wish to Contribute</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="post-brife-answer">
                                            <div className="row g-2">
                                                <div className="col-sm">
                                                    <p className="brife">{query.description}</p>
                                                </div>
                                                <div className="col-12">
                                                    <hr />
                                                </div>
                                                {query.answers && query.answers.length > 0 && (

                                                    <div className="col-12">
                                                        <div className="answered_wrap">
                                                            <div className="post-head">ANSWERED BY</div>
                                                            <div className="row g-2">
                                                            {query.answers?.slice(0, 3).map((answer: any) => (
                                                                answer.answer_red_flag == 0 && (
                                                                    <div key={answer.id} className="row g-2">
                                                                        <div className="col-auto">
                                                                            <div className="post-img">
                                                                            <img src={answer.profile_picture_file_path} width="40" alt={answer.first_name} />
                                                                            </div>
                                                                        </div>
                                                                       
                                                                        <div className="col">
                                                                            <div className="row g-2 align-items-center">
                                                                                <div className="col-12">
                                                                                    <div className="row g-2">
                                                                                    {(answer.user_type === 'ALUMNI' || answer.user_type === 'STUDENT') && (    
                                                                                    <div className="col-sm">
                                                                                        <div className="post-alumni-name">{answer.first_name} {answer.user_type}</div>
                                                                                        <div className="post-alumni-batch">{answer.program_name}, {answer.pass_out_year}</div>
                                                                                    </div>
                                                                                     )}
                                                                                     {(answer.user_type === 'SUPER_ADMIN') && (  
                                                                                        <div className="col-sm">
                                                                                            <div className="post-alumni-name">Counsellor</div>
                                                                                        </div>
                                                                                      )}
                                                                                      {(answer.user_type === 'INSTITUTE_ADMIN') && (  
                                                                                        <div className="col-sm">
                                                                                            <div className="post-alumni-name">{answer.designation}</div>
                                                                                        </div>
                                                                                      )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <p>
                                                                                    {answer.answer}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-auto">
                                                                                    <div className="message_date">
                                                                                        <input 
                                                                                            type="radio" 
                                                                                            className="d-none sss" 
                                                                                            name={`post_rating_${answer.answer_id}`} 
                                                                                            value="like" 
                                                                                            id={`like${answer.answer_id}`} 
                                                                                            onClick={() => handleRating(answer.answer_id, '1')} 
                                                                                            checked={answer.votes.some((vote: Vote) => vote.vote_type === 1 && vote.answer_user_id === user_id)}
                                                                                        />
                                                                                        <label className="icon-like font-20 like me-1" htmlFor={`like${answer.answer_id}`}></label>
                                                                                        <input 
                                                                                            type="radio" 
                                                                                            className="d-none" 
                                                                                            name={`post_rating_${answer.answer_id}`} 
                                                                                            value="dislike" 
                                                                                            id={`dislike${answer.answer_id}`} 
                                                                                            onClick={() => handleRating(answer.answer_id, '2')} 
                                                                                            checked={answer.votes.some((vote: Vote) => vote.vote_type === 2 && vote.answer_user_id === user_id)}
                                                                                        />
                                                                                        <label className="icon-like font-20 dislike" htmlFor={`dislike${answer.answer_id}`}></label>
                                                                                    </div>
                                                                                </div>
                                                                                    
                                                                                <div className="col-auto"><span className="horizontal-divider"></span></div>
                                                                                <div className="col-auto">
                                                                                    <div className="mark-flag">
                                                                                        <input 
                                                                                            type="checkbox" 
                                                                                            id={`mark-${answer.answer_id}`} 
                                                                                            className="d-none"
                                                                                            onChange={() => handleRedFlag(answer.answer_id, '1')} 
                                                                                            checked={answer.red_flag.some((flag: Flag) => flag.is_appropriate === 1 && flag.red_flag_user_id === user_id)}
                                                                                        />
                                                                                        <label htmlFor={`mark-${answer.answer_id}`}>
                                                                                            <i className="icon-flag"></i>
                                                                                            <span>Flag as inappropriate</span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            ))}

                                                                <div className="col-12">
                                                                    <div className="row g-2">
                                                                        
                                                                                <div className="col-sm">
                                                                                    {query.answers?.length > 3 && (
                                                                                    <>
                                                                                    <a href="javascript:;" className="more-answer" onClick={() => handleViewMoreAnswer(query.question_id)}>-View {query.answers?.length - 3} More Answers</a>
                                                                                    </>
                                                                        
                                                                                    )}
                                                                                </div>
                                                                            
                                                                        
                                                                        <div className="col-sm-auto d-flex justify-content-end">
                                                                            <div className="tags-wrap d-flex flex-wrap">
                                                                            {query.tags.split(', ').map((tag: string, index: number) => (
                                                                                <span key={index}>{tag}</span>
                                                                            ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
    );
};

export default AlumniAllQueries;
