import { Dropdown,Tooltip } from 'bootstrap';
import { Modal } from 'bootstrap';
import moment from 'moment';
import { format } from 'date-fns';
import { useContext, useEffect, useRef, useState } from 'react';
import useSmartState from '../../../common/hooks/useSmartState';
import { SmartContext } from '../../../library/Core/SmartContext';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';
import { renderToString } from 'react-dom/server';
import { axiosClient } from '../../../library/Service/axiosClient';
import ActionSettings from '../../../library/SimpleControls/ActionSettings';
import ManageEventService from './ManagePsyLinkService';
//Popup list

import { REPORT_CONFIG } from '../../admin/reports/report-config';

const BATCH_SIZE = 10;
const DEFAULT_SORT_COLUMN = 'createdAt';
const DEFAULT_SORT_DIRECTION = 'desc';


const ManageEventGrid = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useSmartState();
    const [sortedUser, setSortedUser] = useState<string[]>([]);
    const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
    const [bulkAction, setBulkAction] = useState<string>('' as any);
    const [visibleUser, setVisibleUser] = useState(state.data?.userList?.rows?.slice(0, 10));    
    const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
    const loadMoreRef: any = useRef();
    const { gridColumns, actions } = state.data.reportConfig;
    const dropdownRef = useRef(null);
    const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN);
    const [sortDirection, setSortDirection] = useState(DEFAULT_SORT_DIRECTION);
    const [allRows, setallRows] = useState([...state.data.userList.rows] as any[]);
   
    const config = REPORT_CONFIG[state.routeInfo.pageName];
    console.log(state.data.searchCriteria, 'state.data.searchCriteria 39')
    useEffect(() => {
        if (dropdownRef.current) {

            new (Dropdown as any)(dropdownRef.current);
        }
    }, []);
    useEffect(() => {
        const sorted = [...allRows].sort(compareFunction);
        console.log(sorted, 'sorted Events 48');
        setSortedUser(sorted);
    }, [state.data.userList, sortColumn, sortDirection]);

    useEffect(() => {
            ManageEventService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) =>{
                console.log(response.data, 'response data 54 ')
                setStateValue('eventList', response.data)
                setallRows(response.data.rows)
            }
                
            );

            console.log(state.data?.eventList, 'state.data?.eventList 57')
    }, [state.data.searchCriteria]);
    
    useEffect(() => {
        setVisibleUser(sortedUser.slice(0, BATCH_SIZE));
    }, [setVisibleUser]);

    useEffect(() => {
        console.log(setVisibleUser, 'setVisibleUser');
        const sorted = [...allRows].sort(compareFunction);
        setVisibleUser(sorted);
    }, [state.data, sortColumn, sortDirection]);

    

    useEffect(() => {
        const loadMoreObserver = new IntersectionObserver((entries) => {
            // Check if all students are already visible
           
            if (sortedUser.length <= visibleUser.length) {
                if (loadMoreRef.current) {                    
                    loadMoreObserver.unobserve(loadMoreRef.current); // Stop observing
                }
                return;
            }
            
            if (entries[0].isIntersecting) {
                const nextBatch = sortedUser.slice(0, visibleUser.length + BATCH_SIZE);
                setVisibleUser([...nextBatch]);
            }

            
        });

        if (loadMoreRef.current) loadMoreObserver.observe(loadMoreRef.current);
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });

        return () => {
            if (loadMoreRef.current) {
                loadMoreObserver.unobserve(loadMoreRef.current);
            }
        };
    }, [loadMoreRef, visibleUser]);

   
    // Utility to determine if the column is a string type.
    const isStringColumn = typeof visibleUser[0]?.[sortColumn] === 'string';

    const compareFunction = (a: any, b: any) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (isStringColumn) {
            return sortDirection === 'asc'
                ? aValue?.localeCompare(bValue, undefined, { sensitivity: 'accent' })
                : bValue?.localeCompare(aValue, undefined, { sensitivity: 'accent' });
        } else {
            return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
        }
    };

    const copyToClipboard = (userId : any, testCode : any) => {
        let link = `${process.env.REACT_APP_APP_BASE_URL}/psychometric_test/KeytwosuccessTest/${userId}/${testCode}`
        let psyName = ''
        if(testCode === '101'){
            psyName = 'Learning Style'
        } else if(testCode === '104'){
            psyName = 'Personality '
        } else if(testCode === '100'){
            psyName = 'Multiple Intelligence '
        }

        navigator.clipboard.writeText(link).then(() => {
            alert(`${psyName} Link copied to clipboard!`);
        }).catch(err => {
            console.error("Failed to copy link:", err);
        });
    };




    return (
        <> 
         <div>
            <div className="ActiveListings_present">
                <div className="d-flex flex-wrap align-items-center mb-3 w-100 justify-content-between mailerLinks-parent">
                <div className="d-flex flex-wrap align-items-center flex-1 mailerLinks my-2">
                    <div className="d-flex justify-content-start align-items-center customselect_parnt">
                        <div className="d-flex justify-content-start align-items-center customselect_parnt">
                            <select className="form-select w-120 me-2" id="BulkAction" onChange={(e) => setBulkAction(e.target.value)}>
                                <option value="">Bulk Action</option>
                                
                            </select>
                           
                        </div>
                    </div>
                    </div>
                    <div className="d-flex flex-wrap align-items-center justify-content-end">
                        <h3 className="font-14 font-500 mb-0 me-2">Total {allRows.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} records</h3>
                    </div>
                </div>
                <div className="table-responsive attendee_dtl">
                    <table className="table" style={{ minWidth: '1042px' }}>
                        <thead>
                            <tr>
                                <th>
                                <div>
                                    
                                    User Name/Email
                                </div>
                                </th>
                                <th>Personality link</th>
                                <th>Multiple Intelligence Link</th>
                                <th>Learning Style Link</th>
                                <th>Created On</th>
                            </tr>
                        </thead>
                        <tbody>
                        {sortedUser.length > 0 ? (
                        sortedUser.map((row: any) => (
                            <tr key={row.id}>
                                <td>
                                    <div className="d-flex flex-wrap">   
                                         
                                         <div className="name d-flex flex-column line-height-18 flex-1">

                                            <span><span>Name </span> - <b className="font-600">{row.firstName} {row.lastName}</b></span>
                                            <span><span>Email </span> - <b className="font-600">{row.email}</b></span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                <button
                                    className="btn btn-green height-40 d-inline-flex text-white font-14 px-3 m-0 text-decoration-none"
                                    onClick={() => copyToClipboard(row.id, '104')}
                                >
                                    <span>Copy Link</span>
                                </button>
                                </td>
                                <td>
                                <button
                                    className="btn btn-green height-40 d-inline-flex text-white font-14 px-3 m-0 text-decoration-none"
                                    onClick={() => copyToClipboard(row.id, '100')}
                                >
                                    <span>Copy Link</span>
                                </button>
                                </td>
                                <td>
                                <button
                                    className="btn btn-green height-40 d-inline-flex text-white font-14 px-3 m-0 text-decoration-none"
                                    onClick={() => copyToClipboard(row.id, '101')}
                                >
                                    <span>Copy Link</span>
                                </button>
                                </td>

                                <td>
                                    <div className="font-14 font-500 mb-1">{row.addedOn ? moment(row.addedOn).format('DD-MMM-YYYY') : 'N/A'}</div>
                                    
                                </td>
                            </tr>
                          ))
                          ) : (
                            <tr>
                              <td colSpan={7} className="text-center"> {/* Fallback message with colspan */}
                                No records found
                              </td>
                            </tr>
                          )}
                        <tr ref={loadMoreRef} />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        
        
        
        </>
        );
    };
    
export default ManageEventGrid;
