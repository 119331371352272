import { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty } from '../../../library/Core/SmartFunctions';

const Layout = (props: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { eventId } = useParams();
    const [selectedTab, setSelectedTab] = useState(getSelectedTabOnPageLoad());
    const navigate = useNavigate();
    
    function getSelectedTabOnPageLoad () {
        let tab: string = '';
        switch (state?.routeInfo?.pageName) {
            case 'add-event':
                tab = 'add';
            break;

            case 'update-event-speaker':
                tab = 'speaker-info';
            break;

            case 'update-audience':
                tab = 'audience-category';
            break;

            case 'update-theme':
                tab = 'theme-options';
            break;
        }

        return tab;
    }

    const handleTabClick = (tab: string) => {
        if ( tab == selectedTab) {
            return false;
        }

        setSelectedTab(tab);
        if (eventId) {
            navigate(`/event/${tab}/${eventId}`);
        } else {
            navigate(`/event/${tab}`);
        }
    };

    return (
        <div className="main flex-1">
            <section className="updatebasicinformation">
                <div className="container max-1140 px-lg-0">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block white-block-notopborderradius p-0 h-100">
                                
                                <div className="row m-0 pt-4 mt-2 JobInternshipListing">
                                    <div className="flex-1 polarcontsec tab-content" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="basic-details"
                                            role="tabpanel"
                                            aria-labelledby="basic-details-tab">
                                            <form className="lstCustForm w-100">
                                                <div className="row g-4">{props.children}</div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Layout;
