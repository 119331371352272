import React from 'react';
import LoginWithFacebook from './LoginWithFacebook';
import LoginWithInstagram from './LoginWithInstagram';
import LoginWithLinkedin from './LoginWithLinkedin';

import { SmartContext } from '../../library/Core/SmartContext';
import { useImmerReducer } from 'use-immer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import smartReducer from '../../library/Core/SmartReducer';
import { axiosClient } from '../../library/Service/axiosClient';
import Swal from 'sweetalert2';
// import { useNavigate } from 'react-router-dom';

// const accountStatusMap = {
//     active: 'Active',
//     inactive: 'De-active',
// };

const defaultDisplayImage = 'https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/yellow-student-boy.svg';

function AuthorizedAccounts(props: any) {
    const { networkType, selectedPage, data, fetchData } = props;
    const [addNewNetworkType, setAddNewNetworkType] = React.useState('');
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);
    
    // const navigate = useNavigate();

    const hendleDisconnect = async (id: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'This account will be disconnected!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Proceed!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await axiosClient().get(`${process.env.REACT_APP_COMMON_API}/social/disconnect/${id}`);
                Swal.fire(``, `${response?.data?.message}`, `${response?.data?.success == 1 ? 'success' : 'warning'}`);
                fetchData();
            }
        });
    };

    // async function reAuthorizeAccount(id:any) {
    //     const response = await axiosClient().get(`${process.env.REACT_APP_COMMON_API}/social/re-authorize/${id}`);
    //     Swal.fire(``, `${response?.data?.message}`, `${response?.data?.success == 1 ? 'success' : 'warning'}`);
    //     // navigate(`/socialmedia/social-integration`);
    //     response?.data?.success == 1 && fetchData();
    // }

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <div
                className={`tab-pane fade ${selectedPage == networkType ? 'active show' : ''}`}
                id={networkType}
                key={networkType}
                role="tabpanel"
                aria-labelledby={`${networkType}-tab`}
                tabIndex={0}>
                <div className="row g-4">
                    <div className="col-md-3 col-sm-6">
                        <div className="authorized_accounts_block new_account">
                            <a
                                href="javascript:;"
                                data-bs-toggle="modal"
                                data-bs-target="#login_popup"
                                // onClick={() => props.onAddNewAccount(networkType)}>
                                onClick={() => setAddNewNetworkType(networkType)}>
                                <i className="plus"></i>
                                <span>Add New</span>
                            </a>
                        </div>
                    </div>

                    {data?.map((account: any, index: number) => (
                        <div className="col-md-3 col-sm-6" key={`${networkType}-${index}`}>
                            <div className="authorized_accounts_block">
                                <div
                                    className={`authorized_accounts_status ${
                                        account?.status?.toLowerCase() == 'expired' ? 'deactive' : 'active'
                                    }`}>
                                    <i></i> <span>{account?.status}</span>
                                </div>
                                <div className="authorized_accounts_profile_img">
                                    <img src={`${account?.display_image || defaultDisplayImage}`} alt="" />
                                </div>
                                <div className="authorized_accounts_detail">
                                    <h3>{account?.page_name}</h3>
                                    <p>{account?.category}</p>
                                </div>
                                {account?.status?.toLowerCase() == 'active' && (
                                    <a className="btn-disconnect" onClick={() => hendleDisconnect(account?.id)} href="javascript:;">
                                        Disconnect
                                    </a>
                                )}
                                {account?.status?.toLowerCase() == 'expired' && (
                                    <a
                                        className="btn-authorize"
                                        // onClick={() => reAuthorizeAccount(account?.id)}
                                        onClick={() => setAddNewNetworkType(networkType)}
                                        href="javascript:;">
                                        Re-authorize
                                    </a>
                                )}
                                {/* // <a className="btn-disconnect" href="javascript:;">
                                //     Disconnect
                                // </a> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div
                className="modal fade"
                id="login_popup"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered max-450">
                    <div className="modal-content bg-transparent border-0">
                        <div className="modal-body p-0">
                            {addNewNetworkType == 'facebook' && <LoginWithFacebook />}
                            {addNewNetworkType == 'instagram' && <LoginWithInstagram />}
                            {addNewNetworkType == 'linkedin' && <LoginWithLinkedin />}
                        </div>
                    </div>
                </div>
            </div>
        </SmartContext.Provider>
    );
}

export default AuthorizedAccounts;
