import { isEmpty, isFormValid, setError, syncProfileMarker } from "../../../../../library/Core/SmartFunctions";
import { ControlOnChangeArguments, DispatchEvent, State } from "../../../../../library/Core/SmartTypes";
import { AlumniProfileEditScreens } from "../../../../../services/screen-navigation.service";
import StudentService from "../../../../../services/student.service";
import { navigateToNextStep } from "../../../services/profile-next-step-navigator";

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';
    if (!isImpersonatedSession && (!isFormValidBasedOnCustomValidation(state, dispatch) || !isValidDate(state, dispatch) || !isFormValid(state, dispatch))) {
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    const updatedTeachingOutcomeWork = (state?.data['teachingOutcomeWork'] ?? []).map((job:any) => {
        const existingJob = state?.data?.jobsList?.find((existingJob: any) => existingJob.id === job.id);
        const updatedJob = {
            ...existingJob,
            ...job,
            recordTypeCode: existingJob?.recordTypeCode || 'JOINED',
            jobCategory: 'TEACHING_AND_RESEARCH',
            isOutcome: 1,
            industryCode: 7,
            functionalAreaCode: 6,
            endDate: job.isCurrent ? null : existingJob?.endDate || job.endDate,
        };
        return updatedJob;
    });

    const jobsNotInTeachingOutcomeWork = state?.data?.jobsList?.filter((existingJob: any) => 
        !updatedTeachingOutcomeWork.some((job: any) => job.id === existingJob.id)
    );

    const teachingOutcomeWork = [...updatedTeachingOutcomeWork, ...jobsNotInTeachingOutcomeWork];
    const jobAppliedRecords = state?.data?.jobsApplied ?? [];
    const jobHistory = [...teachingOutcomeWork, ...jobAppliedRecords];

    // Save to StudentService
    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            jobs: jobHistory,
            profile: state?.data['profile'],
        })
    ).then((response) => {
        if (response?.status === 200) {
            syncProfileMarker(state.routeInfo.id).then(() => {
                navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch, sessionState, sessionDispatch);
            });
        }
    });
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    let isFormInvalid = false;
    const jobs = state.data.jobsApplied;
    const teachingOutcomeWork = state.data.teachingOutcomeWork;

    teachingOutcomeWork.forEach((teachingOutcomeWork: any, index: number) => {
        if (isEmpty(teachingOutcomeWork?.instituteId) && isEmpty(teachingOutcomeWork?.otherInstitute)) {
            isFormInvalid = true;
            setError(`teachingOutcomeWork.${index}.instituteId`, [`Please enter "Name of Institution"`], dispatch);
        } else {
            setError(`teachingOutcomeWork.${index}.instituteId`, [], dispatch);
        }
    });

    // Dispatch the validation errors to the state
    if (isFormInvalid) {
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'teachingOutcomeWork', errorMessages },
        });
    }
    return !isFormInvalid;
};

export const onCurrentJobSelectionChange = (props: ControlOnChangeArguments) => {
    const rowIndex = parseInt(props.parentDataKey.split('.').pop() as string);
    const jobs = props.state.data.teachingOutcomeWork.map((row: any, index: number) => {
        setError(`teachingOutcomeWork.${index}.endDate`, [], props.dispatch);
        return index == rowIndex ? { ...row, endYear: '', isCurrent: false } : { ...row, isCurrent: false };
    });
    props.dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey: `${props.parentDataKey}.jobStartAndEndDates`, value: [] } });

    props.dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'teachingOutcomeWork', value: jobs } });
};

export const onStartDateChange = (props: ControlOnChangeArguments) => {
    alert(props.row.endDate);
    const errorMessages =
        new Date(props.value) > new Date(props.row.endDate) ? ['Start year should be less than or equal to End year'] : [];
    
    props.errorMessages.push(errorMessages);
};

export const onEndDateChange = (props: ControlOnChangeArguments) => {
    const errorMessages =
        new Date(props.value) < new Date(props.row.startDate) ? ['End year should be greater than or equal to Start year'] : [];
    props.errorMessages.push(errorMessages);
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
};

const isValidDate = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const teachingWorkdata = state?.data['teachingOutcomeWork'];
    let isFormValid = true;
    teachingWorkdata.forEach((teachingjob: any, index: number) => {
        console.log(teachingjob);

        if (isEmpty(teachingjob?.startDate)) {
            setError(`teachingOutcomeWork.${index}.startDate`, [`Please enter "Start Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`teachingOutcomeWork.${index}.startDate`, [], dispatch);
        }

        if ((teachingjob?.isCurrent === false || isEmpty(teachingjob?.isCurrent)) && isEmpty(teachingjob?.endDate)) {
            setError(`teachingOutcomeWork.${index}.endDate`, [`Please enter "End Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`teachingOutcomeWork.${index}.endDate`, [], dispatch);
        }

        if (new Date(teachingjob?.startDate) >= new Date(teachingjob?.endDate)) {
            console.log('Invalid date');
        }

        if (teachingjob?.isCurrent === false || isEmpty(teachingjob?.isCurrent)) {
            if (isEmpty(teachingjob?.endDate) || new Date(teachingjob?.startDate) > new Date(teachingjob?.endDate)) {
                setError(`teachingOutcomeWork.${index}.endDate`, [`End year should be greater than or equal to Start year`], dispatch);
                isFormValid = false;
            } else {
                setError(`teachingOutcomeWork.${index}.endDate`, [], dispatch);
            }
        }
    });

    return isFormValid;
};
