import axios from 'axios';
import { isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../library/Core/SmartTypes';
import logger from '../../../library/Core/logger';
import Swal from 'sweetalert2';
import { axiosClient } from '../../../library/Service/axiosClient';

const isValidEmail = (email: string, emailRegex: any) => emailRegex.test(email);

// Helper function to validate password length
const isValidPasswordLength = (password: string, minLength: number, maxLength: number) => {
    const length = password ? password.length : 0;
    return length >= (minLength || 6) && length <= (maxLength || 50);
};

// Extracts regex pattern from state
const extractEmailRegex = (state: any) => {
    const pattern = state?.formConfig?.sectionRepository[0]?.controlGroup[0]?.props?.pattern;
    return pattern ? new RegExp(pattern) : /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
};

// Extracts password constraints from state
const extractPasswordConstraints = (state: any) => ({
    minLength: state?.formConfig?.sectionRepository[0]?.controlGroup[1]?.props?.minLength,
    maxLength: state?.formConfig?.sectionRepository[0]?.controlGroup[1]?.props?.maxLength,
});

const getinstituteTypeCode = async (instituteGroupId: any) => {
    const response = await axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/admin/get-institute-type`, {
        groupId: instituteGroupId,
    });
    if (response.status == 200) {
        return response.data.instituteTypeCode;
    }
};

export const onLogIn = async (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void,
    setLoginClicked: any
) => {
    event.preventDefault();

    const isFormValidated = isFormValid(state, dispatch);
    logger.log(isFormValidated ? 'Form is valid' : 'Form is invalid. Please correct the errors.');

    const isAggreeToUnivarietyClicked = isAggreeToUnivariety(state, dispatch);

    // if (!isFormValidated) return alert('Please fill in the user name and password');
    if (!isFormValidated || isAggreeToUnivarietyClicked) {
        // return Swal.fire("Please fill in the Email and Password!");
        // return Swal.fire("Please fix the Validation errors");
        return;
    }

    const { registeredEmailId: email, password } = state?.data?.user || {};
    const emailRegex = extractEmailRegex(state);

    if (!isValidEmail(email, emailRegex)) {
        return alert('Please enter a valid email address');
    }

    const { minLength, maxLength } = extractPasswordConstraints(state);
    if (!isValidPasswordLength(password, minLength, maxLength)) {
        return alert('Please enter a valid password');
    }

    try {
        const response = await axios.post(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/authenticate`, {
            registeredEmailId: email,
            password,
        });

        if (!response.data.token) {
            dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [response.data] });
            return;
        }

        const profileCount = response?.data?.institutes?.filter((institute: any) => institute?.userStatusCode !== 'INACTIVE').length ?? 0;

        if (profileCount == 0) {
            dispatch({
                type: 'SET_BUSINESS_VALIDATION_ERRORS',
                payload: [{ message: 'Your profile is not active, Please contact administrator..!' }],
            });
            return;
        }

        // Send event to Moengage signin
        sendMoEngageEvent(response.data, 'signin');

        sessionStorage.setItem('user', JSON.stringify(response.data));
        sessionStorage.setItem('user-jwt-token', response.data.token);
        sessionStorage.setItem('isEmailVerified', response.data.isEmailVerified);

        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem('user-jwt-token', response.data.token);
        localStorage.setItem('isEmailVerified', response.data.isEmailVerified);
        localStorage.setItem('user-logged-out', 'FALSE');
        state.actions['loginClick'](true);

        let instituteTypeCode = '';
        if (response.data.userTypeCode == 'INSTITUTE_ADMIN') {
            instituteTypeCode = await getinstituteTypeCode(response.data.instituteGroupId);
        }
        sessionDispatch({
            type: 'SET',
            payload: {
                ...response.data,
                instituteLogo: localStorage.getItem('school-image'),
                instituteTypeCode: instituteTypeCode,
            },
        });
        // state.actions['ROUTER_NAVIGATE']('/select-institute');
    } catch (error) {
        console.error('Login error', error);
    }
};

export const onSignUp = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const isNotValidMobile = isMobileNotValid(state, dispatch);
    const isNotValidEmail = isEmailNotValid(state, dispatch);
    const isNotValidPassword = isPasswordNotValid(state, dispatch);
    const isAggreeToUnivarietyClicked = isAggreeToUnivariety(state, dispatch);
    if (!isFormValid(state, dispatch) || isNotValidMobile || isNotValidEmail || isNotValidPassword || isAggreeToUnivarietyClicked) {
        // return Swal.fire("Please fix the Validation errors");
        return;
    }

    dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [] });

    let signupLink = `${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/register`;
    let directSignup = false;
    let userStatusCode = 'PENDING_EMAIL_VERIFICATION';

    let invitedBy: string = '111';
    // if registration is done using invitation then set another endpoint for registration
    if (state.data.user?.invitedBy?.length > 0) {
        signupLink = `${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/admin/create-user`;
        directSignup = true;
        userStatusCode = 'PROFILE_IN_PROGRESS';
        let invitedData = atob(state.data.user?.invitedBy);
        const inviteInstituteId = invitedData.split('##')[1];
        invitedBy = invitedData.split('##')[0];
        if (inviteInstituteId != state.data?.user?.schoolId) {
            // return Swal.fire("You are not invited to this institute");
            return Swal.fire('Invite link not valid for this institute');
        }
    }

    const user = state.data.user;

    axios
        .post(`${signupLink}`, {
            ...user,
            userPrograms: [{ programId: user.programId }],
            userInstitutes: [{ instituteId: user.schoolId, userTypeCode: user.userTypeCode }],
            schoolDomain: state.routeInfo.schoolDomain,
            invitedUserID: invitedBy,
        })
        .then((res: any) => {
            const responseUserData = res.data as any;
            // if (state.data.user?.invitedBy?.length > 0) {
            //     if (!isEmpty(responseUserData.id) && !isEmpty(responseUserData.token)) {
            //         sessionStorage.setItem('user', JSON.stringify(responseUserData));
            //         sessionStorage.setItem('user-jwt-token', responseUserData.token);

            //         localStorage.setItem('user', JSON.stringify(responseUserData));
            //         localStorage.setItem('user-logged-out', 'FALSE');
            //         state.actions['loginClick'](true);
            //         sessionDispatch({ type: 'SET', payload: responseUserData });
            //     }
            // }

            dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [responseUserData] });

            if (responseUserData.success === false) {
                return;
            }

            // if mode was invitation signup then expect token and redirect to info page else show the message only and verify email
            // if (directSignup == true) {
            //     if (!responseUserData.token) return alert('There is some issue in registration. Please try after sometime!');
            // } else {
            // Send event to Moengage signup
            sendMoEngageEvent(user, 'signup');
            const schoolDomain = state?.routeInfo?.schoolDomain;
            // state.actions['ROUTER_NAVIGATE'](`/alumni/${schoolDomain}/login`);
            var loginTab = document.querySelectorAll('#existing-user-tab') as NodeListOf<HTMLElement>;
            state.actions['ROUTER_NAVIGATE'](`/user/${schoolDomain}/login`);
            if (loginTab.length > 0) {
                loginTab[0].click(); // Assuming you want to click the first element if found
            }
            dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [responseUserData] });
            return;
            // }
        })
        .catch((err: any) => {
            if (err.response && err.response.status === 409) {
                // Handle conflict error (HTTP 409)
                alert('The user already exists.');
            } else {
                // Handle other errors
                console.error(err);
                if (err.response.data.message) {
                    alert(err.response.data.message);
                } else {
                    alert('An error occurred during registration. Please try again later.');
                }
            }
        });
};

const isEmailNotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const email = state?.data?.user?.registeredEmailId;

    const validRegex = state?.formConfig?.sectionRepository[0]?.controlGroup[2]?.props?.pattern
        ? new RegExp(state?.formConfig?.sectionRepository[0]?.controlGroup[2]?.props?.pattern)
        : /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (validRegex.test(email ? email : '') == false) {
        setError('user.registeredEmailId', ['Please enter a valid email address'], dispatch);
        return true;
    }

    return false;
};

const isPasswordNotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const password = state?.data?.user?.password;

    // const passwordMinLength = state?.formConfig?.sectionRepository[0]?.controlGroup[3]?.props?.minLength;
    // const passwordMaxLength = state?.formConfig?.sectionRepository[0]?.controlGroup[3]?.props?.maxLength;
    // if (
    //     (password ? password?.length : 0) < (passwordMinLength ? passwordMinLength : 6) ||
    //     (password ? password?.length : 0) > (passwordMaxLength ? passwordMaxLength : 50)
    // ) {
    //     setError('user.password', ['Please enter a valid password'], dispatch);
    //     return true;
    // }

    const passwordPattern = state?.formConfig?.sectionRepository[0]?.controlGroup[3]?.props.pattern;
    if (passwordPattern && !new RegExp(passwordPattern).test(password)) {
        setError(
            'user.password',
            ['Please enter a password containing at least one uppercase, one lowercase, one number and one special character'],
            dispatch
        );
    }

    return false;
};

const isMobileNotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    if (isEmpty(state?.data?.user?.mobileCountryCode)) {
        setError('user.mobileCountryCode', ['Please enter mobile code'], dispatch);
        return true;
    }

    if (isEmpty(state?.data?.user?.mobileNumber)) {
        setError('user.mobileNumber', ['Please enter mobile number'], dispatch);
        return true;
    }

    if (
        (state?.data?.user?.mobileNumber?.length > 10 ||
            state?.data?.user?.mobileNumber?.length < 10 ||
            parseInt(state?.data?.user?.mobileNumber.charAt(0)) < 6) &&
        state?.data?.user?.mobileCountryCode == 91
    ) {
        setError('user.mobileNumber', ['Please enter a valid mobile number'], dispatch);
        return true;
    }

    if (
        (state?.data?.user?.mobileNumber?.length > 15 ||
            state?.data?.user?.mobileNumber?.length < 7 ||
            parseInt(state?.data?.user?.mobileNumber.charAt(0)) < 1) &&
        state?.data?.user?.mobileCountryCode != 91
    ) {
        setError('user.mobileNumber', ['Please enter a valid mobile number'], dispatch);
        return true;
    }

    return false;
};

export const onMobileNumberChange = (props: ControlOnChangeArguments) => {
    // console.log(props.value);
    const mobileCountryCode = props.row['mobileCountryCode'];
    const mobileNumber = props.value;

    if (mobileNumber?.length == 0) {
        props.errorMessages.push('Please enter mobile number');
        return;
    }

    //if (mobileCountryCode != '91') return;

    const errorMessage = 'Please enter a valid mobile number';

    if ((mobileNumber?.length > 10 || mobileNumber?.length < 10 || parseInt(props.value.charAt(0)) < 6) && mobileCountryCode == 91) {
        props.errorMessages.push(errorMessage);
    }

    if ((mobileNumber?.length > 15 || mobileNumber?.length < 7 || parseInt(props.value.charAt(0)) < 1) && mobileCountryCode != 91) {
        props.errorMessages.push(errorMessage);
    }
};

export const onPasswordChange = (props: ControlOnChangeArguments) => {
    // check password matxh with pattern validation or not
    const password = props.value;
    const passwordPattern = props.control.props.pattern;
    if (passwordPattern && !new RegExp(passwordPattern).test(password)) {
        //remove esisting error message
        const index = props.errorMessages.indexOf('Please enter valid value for "Password"');
        if (index > -1) {
            props.errorMessages.splice(index, 1);
        }
        props.errorMessages.push(
            'Please enter a password containing at least one uppercase, one lowercase, one number and one special character'
        );
    }
};

const isAggreeToUnivariety = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    if (state?.data?.user?.agreeToUnivariety === false) {
        setError('user.agreeToUnivariety', ['Please agree to univariety'], dispatch);
        dispatch({ type: 'SHOW_ERRORS' });
        return true;
    }

    return false;
};

const sendMoEngageEvent = async (user: any, apiType: any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-${apiType}`, {
            userInfo: user,
        });
        // console.log('Response:', response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
