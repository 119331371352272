import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import { initNextBtnClickOnEnterEvent } from '../../../library/Core/SmartFunctions';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import SignupLayout from './SignupLayout';
import { onLogIn } from './loginAction';
import SessionContext from '../../../library/Core/SessionContext';

const ManagementLogin = () => {
    const { globalState } = useContext(GlobalContext);
    const { schoolDomain, invitedBy } = useParams();
    const [loginClicked, setLoginClicked] = useState(false);
    // to get the page name
    const location = useLocation();
    const pageName = 'school-management-login';
    const navigate = useNavigate();

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { LOG_IN: onLogIn, ROUTER_NAVIGATE: navigate, loginClick: setLoginClicked },
    } as any);

    let { formConfig } = useLoaderData() as State;
    const { sessionState, sessionDispatch } = useContext(SessionContext);

    if (formConfig) {
        // Traverse to the sectionRepository for the 'password' field
        formConfig.sectionRepository.forEach((section: any) => {
            if (section.id === 'user') {
                section.controlGroup.forEach((control: any) => {
                    if (control.id === 'password') {
                        // Remove the pattern from the password field

                        try {
                            if (control?.id === 'password') {
                                delete control?.props?.pattern;
                                delete control?.props?.maxLength;
                                delete control?.props?.minLength;
                            }
                        } catch (error) {
                            console.error('An error occurred while deleting the props:', error);
                        }
                    }
                });
            }
        });
    }

    useEffect(() => {
        localStorage.setItem('user-logged-out', 'TRUE');
        // Reset Local Storage
        const localStorageSchoolSettings = localStorage.getItem('school_settings');
        const localStorageschoolImage = localStorage.getItem('school-image');
        const domainData = localStorage.getItem('app-domain-data');
        const domainDataTimestamp = localStorage.getItem('app-domain-data-timestamp');
        localStorage.clear();
        localStorage.setItem('school_settings', localStorageSchoolSettings ?? '');
        localStorage.setItem('school-image', localStorageschoolImage ?? '');
        localStorage.setItem('app-domain-data', domainData ?? '');
        localStorage.setItem('app-domain-data-timestamp', domainDataTimestamp ?? '');

        // Reset Session Storage
        const sessionStorageBenefitsConfig = sessionStorage.getItem('benefits-config');
        const sessionStorageCarouselData = sessionStorage.getItem('carousel-data');
        const sessionStorageSchoolImage = sessionStorage.getItem('school-image');
        const sessionStorageActivityConfig = sessionStorage.getItem('activity-config');
        sessionStorage.clear();
        sessionStorage.setItem('benefits-config', sessionStorageBenefitsConfig ?? '');
        sessionStorage.setItem('carousel-data', sessionStorageCarouselData ?? '');
        sessionStorage.setItem('school-image', sessionStorageSchoolImage ?? '');
        sessionStorage.setItem('activity-config', sessionStorageActivityConfig ?? '');
    }, []);

    useEffect(() => {
        initNextBtnClickOnEnterEvent(); // @AGS-586 @dt 19th.Sep,2K23 @by RG;

        const loadSchoolSettings = async () => {
            let schoolId = '';
            const response = await axios.get(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/school-info/${schoolDomain}`);
            // localStorage.setItem('school_settings', JSON.stringify(response.data));
            // localStorage.setItem('school-image', response.data.logo);
            // sessionStorage.setItem('school-image', response.data.logo);

            localStorage.setItem('school_settings', JSON.stringify(response.data?.institute));
            localStorage.setItem('school-image', response.data.institute.logo);
            sessionStorage.setItem('school-image', response.data.institute.logo);

            schoolId = response.data.institute.id;

            if (schoolId == null) {
                alert('School not found. Please contact your school admin.');
            }
            const domain = globalState.domain;
            dispatch({
                type: 'DATA_INIT',
                payload: {
                    formConfig,
                    data: { user: { schoolId: schoolId as any, invitedBy, provideUpdatesOnWhatsapp: true } },
                    domain,
                    routeInfo: { pageName, schoolDomain },
                },
            });
        };

        loadSchoolSettings();
    }, []);

    useEffect(() => {
        if (sessionState?.userId && sessionState?.role === 'INSTITUTE_ADMIN' && loginClicked == true) navigate('/management/dashboard');
        else if (sessionState?.studentId && loginClicked == true) navigate('/select-institute');
    }, [sessionState, navigate]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <SignupLayout>{!state.flags.isDataLoading && <PageBuilder />}</SignupLayout>
        </SmartContext.Provider>
    );
};

export default ManagementLogin;
