import React, { useContext } from 'react';
import { SmartContext } from '../../Core/SmartContext';
import { getDataKeyForSection, getSectionConfig } from '../../Core/SmartFunctions';
import { FormSection } from '../../Core/SmartTypes';
import logger from '../../Core/logger';
import ButtonPalletControl from '../../SimpleControls/ButtonPalletControl';
import DebugBarControl from '../../SimpleControls/DebugBarControl';
import PageTitleControl from '../../SimpleControls/PageTitleControl/PageTitleControl';
import FormBuilder from '../FormBuilder';

const PageBuilder = (data:any) => {
    const { state, dispatch } = useContext(SmartContext);

    const handlePrintState = (event: React.SyntheticEvent, key: string) => {
        event.preventDefault();
        if (key === 'data') logger.log(state['data']);
        if (key === 'formValidationErrors') logger.log(state['formValidationErrors']);
        if (key === 'formConfig') logger.log(state['formConfig']);
        if (key === 'ALL') logger.log(state);
    };

    return (
        <div>
            {state?.flags.isDataLoading && <div>Loading...</div>}

            {!state?.flags.isDataLoading && (
                <div className="form needs-validation max-1140 mx-auto" id="login-form">
                    <PageTitleControl message={data?.data}/>
                    <div className="container-fluid p-0">
                        {state?.formConfig?.sections.map((section) => {
                            const sectionConfig = getSectionConfig(section, state) as FormSection;
                            return <FormBuilder key={section} section={sectionConfig} dataKey={getDataKeyForSection(section, state)} />;
                        })}
                    </div>
                    <div className="action_btns mt-4 mb-3" id="buttonPallet">
                        <ButtonPalletControl actions={state?.formConfig?.buttons as string} />
                    </div>
                    {/* <button className="btn btn-light mt-3" onClick={handlePrintState}>
                        DBG
                    </button> */}
                    <DebugBarControl />
                </div>
            )}
        </div>
    );
};

export default PageBuilder;
