import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import SessionContext from '../../../library/Core/SessionContext';
import { arrayColumn, isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
import Layout from './Layout';
import { format, isValid } from 'date-fns';
import Swal from 'sweetalert2';

const AddNew = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const { routeInfo, formConfig, data } = useLoaderData() as State;
    const { sessionState } = useContext(SessionContext);
    
    const userType = sessionState?.userTypeCode;
    const userId = sessionState?.id ?? sessionState?.userId;

    const initialState = {
        psyFirstName: '', // Ensure this is defined
        psyLastName: '',
        psyEmail: '',
        psyMobile: ''
        // other properties
      };
    
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        data: {
            adduser: { // Ensure this object exists to prevent undefined errors
                psyFirstName: '',
                psyLastName: '',
                psyEmail: '',
                psyMobile: ''
            }
        },
        actions: {
            SAVE: handleCreate,
            SAVE_AND_NEXT: handleSaveAndNext,
        },
    } as any);

    async function handleSaveAndNext(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();

        try {
            const resp = await handleCreate(event, state, dispatch, globalState, globalDispatch);
            if ( ! isEmpty(resp?.id) && resp?.success == 1 ) {
                navigate(`/event/speaker-info/${resp?.id}`);
            }
        } catch (error) {
            console.error("Error while save & next:", error);
        }
    }


    const isMobileNotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {

        const psyMobile = state?.data?.adduser?.psyMobile;

        if (isEmpty(psyMobile)) {
            setError('adduser.psyMobile', ['Please enter mobile number'], dispatch);
            return true;
        }

        

        if (
            psyMobile.length !== 10 || psyMobile < 6000000000
        ) {
            setError('adduser.psyMobile', ['Please enter Valid mobile number'], dispatch);
            return true;
        }

        return false;
    };

    const isEmailNotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
        const email = state?.data?.adduser?.psyEmail;

        const validRegex = state?.formConfig?.sectionRepository[0]?.controlGroup[2]?.props?.pattern
            ? new RegExp(state?.formConfig?.sectionRepository[0]?.controlGroup[2]?.props?.pattern)
            : /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (validRegex.test(email ? email : '') == false) {
            setError('adduser.psyEmail', ['Please enter a valid email address'], dispatch);
            return true;
        }

        return false;
    };

    async function handleCreate(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();

        let retData = {
            id: '',
            success: 0,
            message: `Oops..! Something went wrong, Please try again in a while..!!`
        }

        const isnotvalidEmail = isEmailNotValid(state, dispatch);

        const isnotvalidMobile = isMobileNotValid(state, dispatch);

        if (!isFormValid(state, dispatch) || isnotvalidEmail || isnotvalidMobile) {
            return;
        }

        let eventAdd = `${process.env.REACT_APP_WIDGET_API_SERVER}/psychometricLink/savepsyusers`;

       
        await axiosClient()
            .post(`${eventAdd}`, {
                psyFirstName: state.data.adduser.psyFirstName, 
                psyLastName: state.data.adduser.psyLastName,
                psyEmail: state.data.adduser.psyEmail,
                psyMobile: state.data.adduser.psyMobile
            })
            .then((res: any) => {
                retData.id = res.data.id;
                retData.message = res.data.message;
                retData.success = 1;

                if (!res.data.id) {
                    retData.message = retData.message ?? 'There is some issue in event management. Please try after sometime!';
                    retData.success = 0;
                }

                Swal.close();

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    },
                });
                Toast.fire({
                    icon: `${retData.success == 1 ? 'success' : 'warning'}`,
                    title: `${retData.message}`,
                });

                if (retData.success == 1) {
                    navigate(`/psychometriclink/psychometric-links`);
                }
            });


            return retData;
    }

    useEffect(() => {
        const domain = new Map([...globalState.domain]);
        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                data: { addevent : data  },
                domain,
                routeInfo,
            },
        });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>            
            <Layout>{!state.flags.isDataLoading && <PageBuilder />}</Layout>
        </SmartContext.Provider>
    );
};

export default AddNew;
