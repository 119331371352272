import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../../../library/Core/GlobalContext';
import smartReducer from '../../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../../library/Core/SmartTypes';
import { handleCompleteLater, handleNext, onCurrentJobSelectionChange } from './ProfessionalPracticeOutcomeActions';
import { addUserSpecificDomains } from '../../../../../library/Service/domainService';
import ProfessionalPracticeLayout from '../../professional-practice/ProfessionalPracticeLayout';
import PageBuilder from '../../../../../library/Builders/PageBuilder/PageBuilder';
import { SmartContext } from '../../../../../library/Core/SmartContext';
import { getDomainValueForCode } from '../../../../../library/Core/SmartFunctions';


const ProfessionalPracticeOutcome = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState('');

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            NEXT: handleNext,
            I_LL_DO_THIS_LATER: handleCompleteLater,
            ROUTER_NAVIGATE: navigate,
            isCurrent: onCurrentJobSelectionChange,
            REDIRECT: setRedirectUrl,
        },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);

        const jobRoleCodeDomain = domain.get('JOB_ROLE_CODE');
        if (jobRoleCodeDomain) {
            const filteredJobRoleCode = jobRoleCodeDomain.filter((role) => role.code !== 'VISITING_FACULTY');
            domain.set('JOB_ROLE_CODE', filteredJobRoleCode);
        }

        data['professionalPracticeOutcome'] = data['professionalPracticeOutcome'] ?? {};
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    let program_name = getDomainValueForCode(state?.data?.selectedInstitute?.programId,'ALL_PROGRAM_CODE',state);
    let institute_name = getDomainValueForCode(state?.data?.selectedInstitute?.instituteId,'INSTITUTE_DOMAIN',state);
    let message = `After Completing ${program_name} from ${institute_name}, you mentioned that you started your professional practice. Please provide the details of the practice you started after your program completion from the institute`

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <ProfessionalPracticeLayout>{!state.flags.isDataLoading && <PageBuilder data={message}/>}</ProfessionalPracticeLayout>
        </SmartContext.Provider>
    );
};

export default ProfessionalPracticeOutcome;
