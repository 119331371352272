import React, { useEffect, useRef, useState } from 'react';
import { axiosClient } from '../../../library/Service/axiosClient';
import HESClient from './HESClient';
import { useParams } from "react-router-dom";

const KeytwosuccessTest = () => {
  const [buttonCondition, setbuttonCondition] = useState('1');
  const [viewreportlink, setviewReportLink] = useState();
  const [continueassesment, setContinueAssesment] = useState();
  const [userid, setUserId] = useState();
  const [assessmentid, setassessmentId] = useState();
  interface TestResponse {
    userId: string;
    assessmentId: string;
    token: string;
    testcode?: string;
  }

  const [testResponse, setTestResponse] = useState<TestResponse | null>(null);
  const [StudentId, setStudentId] = useState();
  const [studentemail, setStudentEmail] = useState();
  const [studentfirst, setStudentFirst] = useState();
  const [studentlast, setStudentLast] = useState();
  const [showModal, setShowModal] = useState(false);
  const { userId, testCode } = useParams<{ userId: string; testCode: string }>();


  const handleModalClose = () => {
    setShowModal(false);
    window.location.reload();
  };

  useEffect(() => {
    const fetchData = async () => {
          
  

      console.log(userId, testCode, 'userId of user')
      const payload = {
        'userId': userId,
        'testCode': testCode,
      }

      const response = await axiosClient().post(
        `${process.env.REACT_APP_WIDGET_API_SERVER}/psychometricLink/getPsyLinksData`,payload
      );
      
      console.log(response, 'response data');
      // const userData = response.data;

      setTestResponse(response.data.data);

      
    }
    fetchData();
  }, []); 


  useEffect(() => {

    console.log(testResponse, 'testResponse data in json')
  }, [testResponse])


  return (
    <div className="d-flex flex-column min-vh-100">
    {/* Check if any required field is missing */}
    {(!testResponse || !testResponse.userId || !testResponse.assessmentId || !testResponse.token) ? (
      <div
        className="modal show"
        style={{ display: "block", background: "rgba(0,0,0,0.5)" }}
        id="take_test"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabelvideo"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered max-1040 mx-auto" role="document">
          <div className="modal-content radius-20 overflow-hidden">
            <div className="modal-body p-4 text-capitalize">
              <button
                type="button"
                className="btn-close btn-modal"
                onClick={handleModalClose}
                aria-label="Close"
              ></button>
              <span>Wait please it is loading ....</span>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <HESClient testResponse={testResponse} />
    )}
  </div>
  

  );
};

export default KeytwosuccessTest;
