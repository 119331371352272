import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { axiosClient } from '../../library/Service/axiosClient';
import { useLocation } from 'react-router-dom';
import SessionContext from '../../library/Core/SessionContext';
import { SmartContext } from '../../library/Core/SmartContext';
import { useNavigate, useParams } from 'react-router';
import { DispatchEvent, SimpleFormControlArguments, State } from '../../library/Core/SmartTypes';
import ReactQuill from 'react-quill';
import DatePicker, { setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';
import { formatLocalDate, getAudioMetadata, getContentType, getHeaders, getVideoCodec, getVideoMetadata, initTooltip, isEmpty, setError } from '../../library/Core/SmartFunctions';
import html2canvas from 'html2canvas';
import { Tooltip } from 'bootstrap';
import ErrorControl from '../../library/SimpleControls/ErrorControl';
import smartReducer from '../../library/Core/SmartReducer';
import { useImmerReducer } from 'use-immer';

const POST_TITLE_MAX_LENGTH = 60;
const POST_TITLE_MIN_LENGTH = 10;
const LOADER_IMG = `https://ags-qa-bucket.s3.ap-south-1.amazonaws.com/c3d5fa96-7392-4e21-871f-49090185dd25_159.gif`;

const uploadImg = `${process.env.REACT_APP_IMAGE_BASEPATH}/upload.svg`;
const editorStyle = {
    // Adjust the height as needed
};

interface Account {
    access_token: string;
    name: string;
    id: string;
}

const SocialPost = (args: SimpleFormControlArguments) => {
    //variable initialization
    const { id } = useParams();
    // const { state, dispatch } = useContext(SmartContext);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    // const { formConfig, data, routeInfo } = useLoaderData() as State;

    const navigate = useNavigate();
    const [image, setImage] = useState('');
	const [fileType, setFileType] = useState('image');
    const location = useLocation(); // Get the current location, including state
    const submittedData = location.state; // Retrieve the passed data
    const [postText, setPostText] = useState<string>('');
    const [postTitle, setPostTitle] = useState<string>('');

    const defaultPage = 'facebook';
    const [networkType, setNetworkType] = useState('');
    const [selectedPageData, setSelectedPageData] = useState<any | null>(null); // State to store the selected page details
    const [pageData, setPageData] = useState<any[]>([]);
    const [socialNetworkPages, setSocialNetworkPages] = useState<any[]>([]);
    const [invalidPageSelection, setInvalidPageSelection] = useState(false);
    const [uniqueNetworkTypes, setUniqueNetworkTypes]: any = useState<any[]>([]);
    const [selectedPageId, setSelectedPageId] = useState<string>('');
    // const [justLoaded, setJustLoaded] = useState(true);
    const [isLoading, setIsLoading] = useState(true); // Add a loading state
    const [scheduleDate, setScheduleDate] = useState<Date | null>(getAdjustedDate(new Date()));
    const [isScheduledForLater, setIsScheduledForLater] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const social_Post = queryParams.get('social_post');
    const networkTypeMap: any = {
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
        twitter: 'Twitter',
    };

    const { sessionState } = useContext(SessionContext);
    useEffect(() => {
        // Get the query parameters from the URL
        const queryParams = new URLSearchParams(location.search);

        // Check if social_post=1 is present in the URL query parameters
        const socialPost = queryParams.get('social_post');

        if (socialPost === '1') {
            // If social_post=1, set the image from sessionStorage
            const sessionImageUrl = sessionStorage.getItem('spotlight_post_image_url');
            if (sessionImageUrl) {
                setImage(sessionImageUrl); // Set image from sessionStorage if it exists
            }
        }
    }, [location.search]); 
    useEffect(() => {
        const fetchData = async () => {
            const response: any = await getPageData();
            // Filter only active records before processing
            const activeRecords = response.data?.filter((item: any) => item.status === 'Active') || [];

            // Set the filtered social network pages data
            setSocialNetworkPages(activeRecords);

            // Extract unique network types from the active records
            const uniquePages = Array.from(new Set(activeRecords.map((item: any) => item.network_type)));
            setUniqueNetworkTypes(uniquePages);
            setNetworkType(defaultPage);

            if (submittedData !== null) {
                fetchSharedData();
            }

            setIsLoading(false); // Data fetching is complete

            if (uniquePages.length <= 0) {
                Swal.fire({
                    title: 'Oops!',
                    text: "You don't have added any social pages, Please authorize atleast one social media page in social integrations page.",
                    icon: 'warning',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        navigate(`/socialmedia/social-integration`);
                    }
                });
            }
        };

        fetchData();
    }, []);

    const fetchSocialPostData = async (id: any) => {
        try {
            const response = await axiosClient().get(`${process.env.REACT_APP_COMMON_API}/social/get-socialmedia-posts-by-id/${id}`);
            const responseData = await response.data?.data;
            // const page = pageData.find((page) => String(page.page_id) == String(responseData.page_id));

            setNetworkType(responseData?.network_type);
            setSelectedPageId(String(responseData.page_id));
            setPostText(responseData?.post_message);
            setPostTitle(responseData?.post_title);
            setImage(responseData?.input_image);
			const contentType = await getContentType(responseData?.input_image);
			
			if (contentType && contentType.startsWith('video/')) {
				setFileType('video');
			}
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        }
    };

    useEffect(() => {
        if (!isLoading) {
            initTooltip(Tooltip);
        }

        if (!isLoading && !isEmpty(id)) {
            fetchSocialPostData(id);
        }
    }, [isLoading, id]);

    useEffect(() => {
        if (!isEmpty(selectedPageId) && pageData.length > 0) {
            const page = pageData.find((page) => String(page.page_id) == String(selectedPageId));
            setSelectedPageData(page);
        } else {
            const matchingPages = socialNetworkPages.filter((page: any) => page.network_type == networkType);
            setPageData(matchingPages);
            const page = matchingPages.find((page) => String(page.page_id) == String(selectedPageId));
            setSelectedPageData(page);
        }
    }, [selectedPageId]);

    useEffect(() => {
        const matchingPages = socialNetworkPages.filter((page: any) => page.network_type == networkType);
        setPageData(matchingPages);
    }, [networkType]);

    const fetchSharedData = async () => {
        try {
            if (!submittedData) {
                console.log('No data received');
            } else {
                let response = await axiosClient().get(
                    `${process.env.REACT_APP_LEAD_SERVER}/userinfo/getuserinfo/${submittedData['studentID']}`
                );

                const alumniName = response?.data.userinfo.first_name ? response?.data.userinfo.first_name : '';
                const alumnipic = response?.data.userinfo.profile_picture_file_path
                    ? response?.data.userinfo.profile_picture_file_path
                    : '';
                const programName = response?.data.userinfo.display_name ? response?.data.userinfo.display_name : '';
                const batch = response?.data.userinfo.passOutYear ? response?.data.userinfo.passOutYear : '';
                let AchiDetail = '';
                if (!response?.data.achievement || response?.data.achievement === '') {
                    AchiDetail = ''; // If there's no achievement, set to empty
                } else {
                    // If achievement exists, get the title or set to empty if undefined
                    AchiDetail = response?.data.achievement.title || '';
                }
                const instituteName = response?.data.userinfo.institutename ? response?.data.userinfo.institutename : '';
                let universityName = '';
                if (response?.data.pginfo === 'undefined' || response?.data.pginfo === '') {
                    universityName = response?.data.pginfo.display_name ? response?.data.pginfo.display_name : '';
                } else {
                    universityName = response?.data.uginfo.display_name ? response?.data.uginfo.display_name : '';
                }

                const currentCompanyName = '';

                if (submittedData['category'] === '1') {
                    setPostText(
                        `We\'re thrilled to highlight the accomplishments of ${alumniName} from the ${programName} Batch of ${batch}. In their recent achievement of ${AchiDetail}, they made the institute proud. ${alumniName}, we miss your time at the institute! <p> It\'s moments like these that truly make the legacy of ${instituteName} shine with pride. <p> #ProudMoment #AlumniAchievements #AlumniImpactProgram #StudentAlumniNetwork #AlumniNetwork `
                    );
                    let link = alumnipic;
                    setImage(link);
                } else if (submittedData['category'] === '2') {
                    setPostText(
                        `Introducing ${alumniName}, our exceptional alumni from the ${programName} Batch of ${batch}! Started the journey from our institute\'s halls to excelling at the ${universityName} and has now reached remarkable heights at ${currentCompanyName}. Their success story inspires us all. <p> ${alumniName}, we take immense pride in having such remarkable alumni. All the best for your future Endeavors! <p> #AlumniSuccessStories #AlumniImpactProgram #StudentAlumniNetwork #AlumniNetwork `
                    );
                    let link = alumnipic;
                    setImage(link);
                } else if (submittedData['category'] === '3') {
                    setPostText(
                        `Meet ${alumniName} from the ${programName} Batch of ${batch} who is now a student of ${universityName}. We are always proud to see our alumni succeed in their personal and professional lives. We remember ${alumniName}\'s time at institute and couldn\'t be more proud! <p> ${alumniName}, we can\'t wait to see what you\'ll do next! All the best for your future endeavors. <p> #PrestigiousAlumni #AlumniImpactProgram #StudentAlumniNetwork #AlumniNetwork `
                    );
                    let link = alumnipic;
                    setImage(link);
                }
            }
        } catch (error) {
            console.error('Error fetching template data:', error);
        }
    };

    const getPageData = async () => {
        let institute_id = sessionState?.instituteId;
        if (isEmpty(institute_id)) {
            institute_id = sessionState?.institutes?.join(',');
        }
        try {
            const params = {
                // 'institute_id': sessionState?.institutes?.join(',')
                institute_id: `${institute_id}`,
            };
            // const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/profile/get-social-network-pages`, params);
            const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/social/get-pages`, params);

            const responseData = await response.data;
            return responseData;
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        }
    };

    const handleOnNetworkTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNetworkType(event.target.value);
        setSelectedPageId('');
    };

    const [isMessageFilled, setIsMessageFilled] = useState(false);

    useEffect(() => {
        // setIsMessageFilled(postText.trim() != '' && postText.trim() != '<p></p>' && postText.trim() != '<p><br></p>');
        setIsMessageFilled(() => {
            let flag = false;
            flag = postText.trim() != '' && postText.trim() != '<p></p>' && postText.trim() != '<p><br></p>' && postTitle.trim() != '';
            return flag;
        });
    }, [postText, postTitle]);

    // function to get access tokenk
    async function getAccessToken(): Promise<string | null> {
        const accessToken =
            'EAAfzEIFqUtoBOxk6xsenBX7S65zjHjnABIzYza4xqQQMdI6TKIPxDW3UvhWg7OrFb1emX9zqJZABUQQ1XJfE46ihHnqgWYegh3gUDN0v3sHbk2riDfsWEX5Tw3IL8zn66uDZCr3pCFlCRbQVp5QLZAfw6WuwaV9ES4a3ym8cUAXJN1uSZA5K4osKUySClVZADC4ddVHOafF9hNX1PPKkZAMLOC3UwAwZCN8SXWXVFh7icMhB1A1uApiTW0QbObx'; //For testing
        return accessToken;

        // const clientId = '2237577053229786';
        // const clientSecret = 'aa316e7020610c83ec231eea91847f54';
        // const redirectUri = 'http://localhost:3000/share-profile';

        // const url = `https://graph.facebook.com/v16.0/oauth/access_token`;
        // const params = {
        //   client_id: clientId,
        //   client_secret: clientSecret,
        //   redirect_uri: redirectUri,
        //   code: code_url,
        // };

        // try {
        //   const response = await axios.get(url, { params });

        //   const accessToken= response.data.access_token;

        //   return accessToken;
        // } catch (error) {
        //   console.error('Error getting access token:', error);
        //   return null;
        // }
    }

    //function to get userid
    async function getUserId() {
        const accessToken = await getAccessToken();
        if (accessToken) {
            const fields = 'id';
            const url = `https://graph.facebook.com/v16.0/me`;
            const params = {
                client_id: fields,
                access_token: accessToken,
            };

            try {
                const response = await axios.get(url, { params });
                const id = response.data.id;
                return id;
            } catch (error) {
                console.error('Error getting access token:', error);
                return null;
            }
        }
    }

    //function to get pageid
    async function getPagesId() {
        const accessToken = await getAccessToken();
        const id = await getUserId();
        if (accessToken) {
            const url = `https://graph.facebook.com/v16.0/` + id + `/accounts`;
            const params = {
                access_token: accessToken,
            };

            try {
                const response = await axios.get(url, { params });
                const accounts = response.data.data.map((account: any) => {
                    return {
                        access_token: account.access_token,
                        name: account.name,
                        id: account.id,
                    };
                });

                return accounts;
            } catch (error) {
                console.error('Error getting accounts:', error);
                return [];
            }
        }
    }

    function decodeHtmlEntities(text: any) {
        const element = document.createElement('div');
        element.innerHTML = text;
        return element.innerText || element.textContent;
    }

    useEffect(() => {
        if (invalidPageSelection) {
            const timer = setTimeout(() => {
                setInvalidPageSelection(false);
            }, 3000);

            // Cleanup function to clear the timer when the component is unmounted
            return () => clearTimeout(timer);
        }
    }, [invalidPageSelection]);

    const handleSaveAsDraft = () => {
        handlePublish('draft');
    };

    const handleValidations = async () => {
        let retData = {
            isFormValid: true,
            message: '',
        };
        const page = pageData.find((page) => String(page.page_id) == String(selectedPageId));
        const snpId = page?.id;

        if (isEmpty(selectedPageId) || isEmpty(snpId)) {
            retData.isFormValid = false;
            retData.message = `Please select the page to publish..!`;
            setInvalidPageSelection(true);
            setError(`page_id`, [retData.message], dispatch);
            window.scrollTo({ top: 250, behavior: 'smooth' });
            return retData;
        }

        if (isEmpty(postTitle)) {
            retData.isFormValid = false;
            retData.message = `Please enter post title..!`;
            setError(`post_title`, [retData.message], dispatch);

            return retData;
        } else {
            setError(`post_title`, [''], dispatch);
        }

        if (postTitle.length > POST_TITLE_MAX_LENGTH) {
            retData.isFormValid = false;
            retData.message = `Title cannot exceed ${POST_TITLE_MAX_LENGTH} characters `;
            setError(`post_title`, [retData.message], dispatch);

            return retData;
        } else {
            setError(`post_title`, [''], dispatch);
        }

        if (postTitle.length < POST_TITLE_MIN_LENGTH) {
            retData.isFormValid = false;
            retData.message = `Almost there! Post title needs at least ${POST_TITLE_MIN_LENGTH} characters `;
            setError(`post_title`, [retData.message], dispatch);

            return retData;
        } else {
            setError(`post_title`, [''], dispatch);
        }

        if (isEmpty(image) && ['facebook', 'instagram'].includes(networkType)) {
            // if (isEmpty(image)) {
            retData.isFormValid = false;
            retData.message = `Please upload media..!`;
            setError(`post_image`, [retData.message], dispatch);

            return retData;
        } else {
            setError(`post_image`, [''], dispatch);
        }

        return retData;
    };

    const handlePublish = async (status = 'scheduled') => {
        const validationData = await handleValidations();
        if (!validationData.isFormValid) {
            dispatch({ type: 'SHOW_ERRORS' });
            console.log(state.formValidationErrors);

            return false;
        }

        const page = pageData.find((page) => String(page.page_id) == String(selectedPageId));
        const snpId = page?.id;

        let payload: any = {};
        if (!isEmpty(id)) {
            payload.id = id;
        }

        payload.networkType = networkType;
        // payload.snpId = selectedPageData?.id;
        payload.snpId = snpId;

        payload.isScheduled = isScheduledForLater;
        payload.scheduleTime =
            status == 'scheduled' ? (isScheduledForLater ? formatLocalDate(scheduleDate as Date) : formatLocalDate(new Date())) : null;
        payload.status = status;
        payload.postTitle = postTitle;

        const loader = Swal.fire({
            title: 'Preparing your post...',
            html: 'Please wait while we prepare your post!',

            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading(); // Show loading spinner
            },
        });
		const fileHeaders = await getHeaders(image);
		const contentType = fileHeaders?.['content-type'];
		payload.contentType = contentType;

        switch (networkType) {
            case 'facebook':
            case 'linkedin':
                payload.photoUrl = image;
                payload.message = postText;
                break;

            case 'instagram':
                const minAspectRatio = 4 / 5; // 0.8 (Portrait)
                const maxAspectRatio = 1.91 / 1; // 1.91 (Landscape)

				const minVideoAspectRatio = 9 / 16; // 0.5625 (minimum)
				const maxVideoAspectRatio = 1.91 / 1; // 1.91 (maximum)
				
                let maxFileSizeMB = 30;
				if (contentType && contentType.startsWith('video/')) {
					maxFileSizeMB = 1024;
				}
                payload.photoUrl = image;
                payload.message = decodeHtmlEntities(postText); // Assuming postText contains HTML entities to decode
                // payload.generatedFile = await getSnap('post_content');

                try {
					// const contentType = await getContentType(payload.photoUrl);

					if (contentType && contentType.startsWith('image/')) {
						// Fetch the image data (as a Blob) from the S3 URL
						// const response = await fetch(payload.generatedFile);
						const response = await fetch(payload.photoUrl);
						const blob = await response.blob();

						// Check the file size
						const fileSizeInMB = blob.size / (1024 * 1024); // Convert bytes to MB
						if (fileSizeInMB > maxFileSizeMB) {
							await Swal.fire('File Too Large', `The file size should be less than ${maxFileSizeMB} MB.`, 'warning');
							return false;
						}

						// Create an Image object and load the Blob URL for aspect ratio check
						const img = new Image();
						const objectURL = URL.createObjectURL(blob);

						// Await image load to check the aspect ratio
						await new Promise((resolve, reject) => {
							img.onload = () => {
								const { width, height } = img;
								const aspectRatio = width / height;
								
								// Validate aspect ratio within range
								if (networkType == 'instagram' && (aspectRatio < minAspectRatio || aspectRatio > maxAspectRatio)) {
									reject(new Error(`Aspect ratio must be between 4:5 (0.8) and 1.91:1 (1.91).||Invalid Aspect Ratio`));
								} else {
									resolve(`Valid Image. Size: ${fileSizeInMB.toFixed(2)} MB, Aspect Ratio: ${aspectRatio.toFixed(2)}`);
								}

								// Clean up the object URL after use
								URL.revokeObjectURL(objectURL);
							};

							img.onerror = () => {
								reject(new Error('Failed to load the image'));
							};

							img.src = objectURL;
						});
					} else {
						const fileSizeInMB = fileHeaders?.['content-length'] / (1024 * 1024); // Convert bytes to MB
						if (fileSizeInMB > maxFileSizeMB) {
							await Swal.fire('File Too Large', `The file size should be less than ${maxFileSizeMB} MB.`, 'warning');
							return false;
						}

						const video = document.createElement('video');
						video.preload = 'metadata';
						await new Promise((resolve, reject) => {
							video.onloadedmetadata = async () => {
								// validateAspectRatio(video.videoWidth, video.videoHeight);
								const ratio = video.videoWidth / video.videoHeight;
								console.log(ratio, " video aspect ratio ")
								if (ratio < minVideoAspectRatio || ratio > maxVideoAspectRatio) {
									reject(new Error(`Aspect ratio must be between 9:16 (0.56) and 1.91:1 (1.91).||Invalid Aspect Ratio`));
								} else {
									let message = `Valid Video. Size: ${fileSizeInMB.toFixed(2)} MB, Aspect Ratio: ${ratio.toFixed(2)}`;
									console.log(message);
									resolve(message);
								}
								
								URL.revokeObjectURL(payload.photoUrl);
							};
							video.onerror = async() => {
								// setError('Error loading video.');
								await Swal.fire('Oops..!', `Unable to load the video due to unexpected error..!!`, 'warning');
								URL.revokeObjectURL(payload.photoUrl);
							};
							video.src = payload.photoUrl;
						});

                        const validationResponse = await getInstaVideoValidations(payload.photoUrl);
                        console.log(validationResponse, ' validationResponse ========================== ')
                        if ( ! validationResponse.data.success && validationResponse.data.errors.length > 0 ) {
                            console.log('ERROR Occured')
                            await Swal.fire({
                                title: 'There were some errors:',
                                html: `<ul>${validationResponse.data.errors.map((msg: any) => `<li>${msg}</li>`).join('')}</ul>`,
                                icon: 'error'
                            });
                            return;
                        }
					}

                } catch (error: any) {
                    console.error('Error:', error.message);
                    const errArr = error.message.split('||');
                    await Swal.fire(`${errArr[1] ? errArr[1] : 'Invalid file'}`, errArr[0], 'warning');
                    return false;
                }
                break;
        }

        try {
            Swal.close();
            let title = 'Please wait..!';
            let html = 'Your post is being saved!';
            switch (status) {
                case 'draft':
                    html = 'Your post is being saved!';
                    break;

                case 'inprogress':
                    html = 'Your post is being published!';
                    break;

                case 'scheduled':
                    html = 'Your post is being scheduled!';
                    break;
            }

            const loader = Swal.fire({
                title: title,
                html: html,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading(); // Show loading spinner
                },
            });
            const apiUrl = `${process.env.REACT_APP_COMMON_API}/social/publish`;
            // const postData = {
            // 	'id': selectedPageData.id,
            // 	'imageUrl': imgUrl,
            // 	'caption': message
            // }
            const response = await axiosClient().post(`${apiUrl}`, payload);
            Swal.close();
            Swal.fire('', `${response?.data?.message}`, `${response?.data?.success == 1 ? 'success' : 'warning'}`);

            if (response?.data?.success == -1000) {
                //Token expired
                navigate(`/socialmedia/social-integration`);
                return false;
            }

            if (!isEmpty(response?.data?.id)) {
				let slug = status;
				if (status == 'draft') {
					slug = 'drafts';
				}
                navigate(`/socialmedia/posts/${slug}`);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        } catch (error: any) {
            // In case axios throws an error, handle it here
            if (error?.response?.data?.error) {
                Swal.fire({
                    title: `Oops..!`,
                    // text: `Something went wrong, Please try again in a while..!!`,
                    text: error?.response?.data?.error,
                    icon: 'error',
                });
            } else {
                // Handle unexpected errors here
                console.error('Unexpected error:', error);
                Swal.fire({
                    title: 'An unexpected error occurred',
                    text: error.message,
                    icon: 'error',
                });
            }
        }
    };

    async function convertImagesToBase64(element: HTMLElement) {
        const images = element.getElementsByTagName('img');
        const promises = Array.from(images).map((img) => {
            if (!img.currentSrc) {
                console.warn('Skipping empty image src:', img);
                return Promise.resolve();
            }

            return new Promise<void>((resolve, reject) => {
                const imageURL = img.src;
                const imgElement = new Image();
                imgElement.crossOrigin = 'Anonymous';

                imgElement.onload = function () {
                    const canvas = document.createElement('canvas');
                    canvas.width = imgElement.width;
                    canvas.height = imgElement.height;
                    const ctx = canvas.getContext('2d');

                    if (ctx) {
                        ctx.drawImage(imgElement, 0, 0);
                        const dataURL = canvas.toDataURL('image/png');
                        img.src = dataURL;
                        resolve();
                    } else {
                        reject(new Error('Canvas context is null'));
                    }
                };

                imgElement.onerror = function (error) {
                    console.error('Image failed to load:', imageURL, error);
                    reject(error);
                };

                imgElement.src = imageURL;
            });
        });

        return Promise.allSettled(promises);
    }

    async function getSnap(elId: string = ''): Promise<string | null> {
        const element = document.getElementById(elId);

        // Check if the element exists before capturing the screenshot
        if (!element) {
            console.error('Element not found.');
            return null;
        }

        let imageUrl: string | null = null;

        // Convert all images to base64 before capturing the canvas
        await convertImagesToBase64(element);
        // Capture the screenshot with the complete scroll area
        const canvas = await html2canvas(element, {
            scrollX: 0,
            scrollY: 0,
            useCORS: false,
            windowWidth: element.scrollWidth,
            windowHeight: element.scrollHeight,
        });

        // Create a new Promise to handle the asynchronous `toBlob` and API call
        imageUrl = await new Promise((resolve, reject) => {
            canvas.toBlob(async (blob) => {
                if (blob) {
                    // Create a FormData object and append the blob (file)
                    const formData = new FormData();
                    formData.append('image', blob, `screenshot-${Date.now()}.jpg`);
                    const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/image`;

                    try {
                        const response = await axiosClient().post(apiUrl, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        });

                        resolve(response?.data?.link); // Resolve the Promise with the image URL
                    } catch (error) {
                        console.error('Error uploading file', error);
                        reject(error); // Reject the Promise on error
                    }
                } else {
                    console.error('Blob creation failed.');
                    reject(new Error('Blob creation failed'));
                }
            }, 'image/jpeg');
        });

        return imageUrl;
    }

    const handleSocialPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValue: any = event.target.value;
        setSelectedPageId(String(selectedValue));
    };

	const handleFileUpload = async (e: any) => {
		let link = '';
		let maxFileSize = 10 * 1024 * 1024; // Default 10 MB
		let maxVideoFileSize = maxFileSize;
		const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
	
		// Defaults for image dimensions and aspect ratios
		let aspectRatioFlag = false;
		let minAspectRatio = 1 / 1; // 0.8 (Portrait)
		let maxAspectRatio = 19.1 / 1; // 1.91 (Landscape)

		let minVideoAspectRatio = 0;
		let maxVideoAspectRatio = 0;
		let allowedVideoCodec: any = [];
	
		switch (networkType) {
			case 'facebook':
				maxFileSize = 10 * 1024 * 1024;
				maxVideoFileSize = 1 * 1024 * 1024 * 1024; // 1GB;
				allowedFileTypes.push('image/webp');
				allowedFileTypes.push('video/mp4');
				break;
	
			case 'instagram':
				maxFileSize = 30 * 1024 * 1024; // 30 MB
				aspectRatioFlag = true;
				minAspectRatio = 4 / 5; // Portrait 4:5
				maxAspectRatio = 1.91 / 1; // Landscape 1.91:1
				allowedFileTypes.push('video/mp4');
				maxVideoFileSize = 4 * 1024 * 1024 * 1024; // 4GB;
				allowedVideoCodec = ['H.264', 'H.265'];
						
				let minVideoAspectRatio = 9 / 16; // 0.5625 (minimum)
				let maxVideoAspectRatio = 1.91 / 1; // 1.91 (maximum)


				break;
	
			case 'linkedin':
				// allowedFileTypes.push('video/mp4');
				break;
		}
	
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			console.log(file, " FILE ")
			// Check if the file type is allowed
			if (!allowedFileTypes.includes(file.type)) {
				console.log("File type not allowed:", file.type);
				await Swal.fire(
					'Unsupported File Format',
					`Please upload a valid file (e.g., ${allowedFileTypes
						.map((type) => type.split('/')[1].toUpperCase())
						.join(', ')}).`,
					'warning'
				);
				e.target.value = '';
				return;
			}
	
			if (file.type.startsWith('image/')) {
				// Check if the file size exceeds the limit defined earlier
				if (file.size > maxFileSize) {
					await Swal.fire('File Too Large', `The file size should be less than ${maxFileSize / 1024 / 1024} MB.`, 'warning');
					e.target.value = '';
					return;
				}
				// Image specific validations
				const img = new Image();
				img.src = URL.createObjectURL(file);
				img.onload = async function () {
					const width = img.width;
					const height = img.height;
	
					// Aspect ratio validation for images
					if (aspectRatioFlag) {
						const aspectRatio = width / height;
	
						if (aspectRatio < minAspectRatio || aspectRatio > maxAspectRatio) {
							await Swal.fire(
								'Invalid Aspect Ratio',
								`Please upload an image with a valid aspect ratio between 4:5 (0.8) and 1.91:1 (1.91).`,
								'warning'
							);
							e.target.value = '';
							return;
						}
					}
	
					// Continue with the upload after passing image validations
					const loader = Swal.fire({
						title: 'Please wait...',
						html: 'Your file is being uploaded..! ',
						allowOutsideClick: false,
						didOpen: () => {
							Swal.showLoading(); // Show loading spinner
						},
					});
					setFileType('image');
					setImage(LOADER_IMG);
					const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/get-attached`;
					const formData = new FormData();
					formData.append('pdf_doc', file);
					const response = await axiosClient().post(apiUrl, formData);
	
					link = response.data.link;
					setImage(link);
					Swal.close();
				};
			} else if (file.type.startsWith('video/')) {
				// Video specific validation
				const loader = Swal.fire({
					title: 'Please wait...',
					html: 'Processing your video..!',
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading(); // Show loading spinner
					},
				});
				// Check if the file size exceeds the limit defined earlier
				if (file.size > maxVideoFileSize) {
					await Swal.fire('File Too Large', `The file size should be less than ${maxVideoFileSize / 1024 / 1024} MB.`, 'warning');
					e.target.value = '';
					return;
				}

				if (file.type === 'video/mp4') {				
					const video = document.createElement('video');
					video.src = URL.createObjectURL(file);
	
					video.onloadedmetadata = async function () {
						// If you want to check for video dimensions
						const width = video.videoWidth;
						const height = video.videoHeight;
	
						const ratio = video.videoWidth / video.videoHeight;
						console.log(ratio, " video aspect ratio ")
						if ( (minVideoAspectRatio > 0 && maxVideoAspectRatio > 0) && (ratio < minVideoAspectRatio || ratio > maxVideoAspectRatio) ) {
							await Swal.fire('Invalid Aspect Ratio', `Aspect ratio must be between 9:16 (0.56) and 1.91:1 (1.91).`, 'warning');
							e.target.value = '';
							return;
						} else {
							let message = `Valid Video. Size: ${(file.size/1024/1024).toFixed(2)} MB, Aspect Ratio: ${ratio.toFixed(2)}`;
							console.log(message);
						}

						// Continue with video upload
						const loader = Swal.fire({
							title: 'Please wait...',
							html: 'Your video is being uploaded..!',
							allowOutsideClick: false,
							didOpen: () => {
								Swal.showLoading(); // Show loading spinner
							},
						});
						setFileType('image');
						setImage(LOADER_IMG); // You may want to change this if showing video thumbnails
						
						// const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/get-attached`;
						const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/upload-social-file`;
                        
						const formData = new FormData();
						formData.append('pdf_doc', file);
						const response = await axiosClient().post(apiUrl, formData);
	
						link = response.data.link;

						// ===========================================================================================
						if ( networkType == 'instagram') {
							Swal.fire({
								title: 'Please wait...',
								html: 'Your video is being validated..!',
								allowOutsideClick: false,
								didOpen: () => {
									Swal.showLoading(); // Show loading spinner
								},
							});
							const validationResponse = await getInstaVideoValidations(link);
							console.log(validationResponse, ' validationResponse ========================== ')
							if ( ! validationResponse.data.success && validationResponse.data.errors.length > 0 ) {
								console.log('ERROR Occured')
								await Swal.fire({
									title: 'There were some errors:',
									html: `<ul>${validationResponse.data.errors.map((msg: any) => `<li>${msg}</li>`).join('')}</ul>`,
									icon: 'error'
								});
								e.target.value = '';
								setImage('');
								return;
							}
						}
						// ===========================================================================================
			
						setImage(link);
						setFileType('video');
						Swal.close();
					};
				} else {
					await Swal.fire('Unsupported Video Format', 'Please upload an mp4 video file.', 'warning');
					e.target.value = '';
					return;
				}
			}
		}
	};	

	const getInstaVideoValidations = async (link: any) => {
		const validationConfig = {
			general: {
				minDuration: 3,
				maxDuration: 90,
				minFrameRate: 24,
				maxFrameRate: 60,
			},
			video: {
				chromaSubsampling: '4:2:0',
                maxBitRate: 25 * 1024 * 1024, // 25Mbps
				supportedCodecs: [
					{
						name: 'H.264',
						format: ['AVC', 'H.264'],
						codecId: ['avc1'],
						encoderKeywords: ['x264'], // Keywords in Encoded_Library
					},
					{
						name: 'H.265',
						format: ['HEVC', 'H.265'],
						codecId: ['hvc1', 'hev1'],
						encoderKeywords: ['x265', 'hevc'],
					},
					{
						name: 'VP9',
						format: ['VP9'],
						codecId: ['vp09'],
						encoderKeywords: ['vp9', 'libvpx-vp9'],
					},
					{
						name: 'AV1',
						format: ['AV1'],
						codecId: ['av01'],
						encoderKeywords: ['av1', 'libaom-av1', 'svt-av1'],
					},
				],
				scan: 'progressive',
				frameRateType: 'CFR', //Constant Frame Rate => fixed
				GOP: 'fixed', // 2-5 seconds
				minGOPinSeconds: 2,
				maxGOPinSeconds: 5,
			},
			audio: {
				codec: 'aac',
				minBitRate: 128000,
				sampleRate: 48000,
				channels: 2, //sterio
			},
		};
		const params = {
			url: `${link}`,
			validationConfig: validationConfig,
		};
		// const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/profile/get-social-network-pages`, params);
		const validationResponse = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/upload/validate-file`, params);
		return validationResponse;
	}

    const handleDateChange = (date: Date | null): void => {
        // setScheduleDate(date);
        const now = getAdjustedDate(new Date());

        // If selected time is in past, reset to current time
        if (date && date < now) {
            setScheduleDate(now);
        } else {
            setScheduleDate(date);
        }
    };

    const handleDiscard = async () => {
        Swal.fire({
            title: 'Are you sure you want to discard this post?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then(async (result) => {
            if (result.isConfirmed) {
                let success = 0;
                if (isEmpty(id)) {
                    success = 1;
                } else {
                    const response = await axiosClient().get(`${process.env.REACT_APP_COMMON_API}/social/delete/${id}`);
                    success = response?.data?.success;
                    await Swal.fire(`${response?.data?.message}`, '', `${response?.data?.success == 1 ? 'success' : 'warning'}`);
                }

                if (success == 1) {
                    navigate(`/socialmedia/posts`);
                }
            }
        });
    };

    function getAdjustedDate(currentDate: Date) {
        const adjustedDate = new Date(currentDate);
        const minutes = adjustedDate.getMinutes();

        if (minutes < 30) {
            // Set to the next 30-minute mark
            adjustedDate.setMinutes(30);
        } else {
            // Set to the next hour
            adjustedDate.setMinutes(0);
            adjustedDate.setHours(adjustedDate.getHours() + 1);
        }

        // Reset seconds and milliseconds
        adjustedDate.setSeconds(0);
        adjustedDate.setMilliseconds(0);

        return adjustedDate;
    }
	
    return (
        <>
            {isLoading ? (
                <div>{/* Loading... */}</div>
            ) : (
                <SmartContext.Provider value={{ state, dispatch }}>
                    <div className="main flex-1 bg-white">
                        <div className="max-1140 pt-3 pt-sm-4 px-lg-0 pb-5 overflow-hidden mx-auto">
                            <div className="d-flex justify-content-between align-items-center mb-3 pb-3 custom-bottom-liteblue">
                                <h3
                                    data-aos="fade-left"
                                    data-aos-delay="600"
                                    className="font-35 font-600 mb-0 block-title position-relative">
                                    Social Post <span className="custom-tool">Create New</span>
                                </h3>
                            </div>
                            <div className="ActiveListings_present">
                                <div className="row g-4">
                                    <div className="col-md-6">
                                        <form action="" className="w-100 mx-auto">
                                            <div className="row g-3">
                                                <div className="col-md-12">
                                                    <label className="form-label m-0 mb-1 font-16 font-500 w-100" htmlFor="BroadcastTitle">
                                                        Post To
                                                    </label>
                                                    <div className="postOption">
                                                        <ul>
                                                            {uniqueNetworkTypes.map((eachNetworkType: any) => (
                                                                <li className="mt-2" key={eachNetworkType}>
                                                                    <input
                                                                        checked={eachNetworkType === networkType}
                                                                        type="radio"
                                                                        name="social"
                                                                        value={eachNetworkType}
                                                                        id={eachNetworkType}
                                                                        className="d-none"
                                                                        onChange={handleOnNetworkTypeChange}
                                                                    />
                                                                    <label htmlFor={eachNetworkType}>
                                                                        <i></i>
                                                                        <span>{networkTypeMap[eachNetworkType]}</span>
                                                                        <b></b>
                                                                    </label>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="pagename">
                                                            <div className={`row g-3 mt-1 ${invalidPageSelection ? 'shake-err' : ''}`}>
                                                                {pageData.map((page: any) => (
                                                                    <div className="col-auto" key={page.id}>
                                                                        <div className="form-check">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="flexRadioDefault"
                                                                                value={page.page_id}
                                                                                id={`flexRadioDefault${page.id}`}
                                                                                // checked={selectedPageData?.page_id == page.page_id}
                                                                                checked={selectedPageId == page.page_id}
                                                                                onChange={handleSocialPageChange}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`flexRadioDefault${page.id}`}>
                                                                                <strong>{page.page_name}</strong>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <ErrorControl errorMessages={state?.formValidationErrors['page_id']} />
                                                            </div>
                                                            {/* <div className="text-danger mb-3">Please enter "Heading"</div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <label className="form-label m-0 mb-1 font-16 font-500 w-100" htmlFor="post_title">
                                                        Title<span className="text-danger">*</span>{' '}
                                                        <i
                                                            className="bi bi-info-circle-fill"
                                                            data-bs-toggle="tooltip"
                                                            title={`This is a title for the post and will not be published`}></i>
                                                    </label>
                                                    <input
                                                        id="post_title"
                                                        type="text"
                                                        className={`form-control pl-0`}
                                                        placeholder={`Please enter the Title`}
                                                        value={postTitle || ''}
                                                        required={true}
                                                        onChange={(event) => {
                                                            setPostTitle(event.target.value);
                                                        }}
                                                        minLength={10}
                                                        maxLength={60}
                                                        // ref={formControlRef}
                                                    />
                                                    <ErrorControl errorMessages={state?.formValidationErrors['post_title']} />
                                                </div>
                                                <div className="col-md-12">
                                                    <label className="form-label m-0 mb-1 font-16 font-500 w-100" htmlFor="Message">
                                                        Post Message<span className="text-danger">*</span>
                                                    </label>
                                                    <ReactQuill
                                                        style={editorStyle}
                                                        value={postText}
                                                        onChange={setPostText} // No need to use e.target.value, just the parameter value
                                                        placeholder="Compose your message..."
                                                        className="editor"
                                                    />
                                                    <ErrorControl errorMessages={state?.formValidationErrors['post_message']} />
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="row mb-4">
                                                        <div className="col-md-12 inputfile_parent m-0">
                                                            <div className="file file--upload w-100">
                                                                <label htmlFor="choose_image">
                                                                    <i className="material-icons">
                                                                        <img src={uploadImg} />
                                                                    </i>{' '}
                                                                    Browse files
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    name="post_image"
                                                                    className="d-none"
                                                                    id="choose_image"
                                                                    onChange={handleFileUpload}
                                                                    // accept="image/*"
                                                                    style={{ opacity: '0.3' }}
                                                                />
                                                            </div>
                                                            <ErrorControl errorMessages={state?.formValidationErrors['post_image']} />

                                                            <div className="docselect">
                                                                <span className="me-1">
                                                                    <img src="images/doc_icon.svg" alt="" />
                                                                </span>
                                                                <input
                                                                    type="file"
                                                                    name="fileUpload"
                                                                    id="fileUpload"
                                                                    className="inputfile"
                                                                    data-multiple-caption="{count} files selected"
                                                                    multiple
                                                                    required
                                                                    onChange={(e) => handleFileUpload(e.target.files)}
                                                                />
                                                                <span className="fileclose">
                                                                    <span className="icon-cross"></span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row g-4 align-items-center">
                                                    <div className="col-auto">
                                                        <div className="d-flex align-items-center">
                                                            <input
                                                                id="schedule_for_later"
                                                                className={`form-check-input p-1 m-1`}
                                                                type="checkbox"
                                                                name="schedule_for_later"
                                                                checked={isScheduledForLater}
                                                                onChange={(evt) => setIsScheduledForLater(evt.target.checked)}
                                                            />
                                                            Schedule for later
                                                        </div>
                                                    </div>

                                                    {isScheduledForLater && (
                                                        <div className="col-auto">
                                                            <DatePicker
                                                                className="custom-datepicker w-300 height-40 px-2 radius-10 px-4"
                                                                selected={scheduleDate}
                                                                onChange={handleDateChange}
                                                                showTimeSelect
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                minDate={new Date()}
                                                                onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                                                                filterTime={(time) => {
                                                                    // Only filter times for today's date
                                                                    const currentDate = new Date();
                                                                    const selectedDate = time;

                                                                    // If time is for today, check against current time
                                                                    if (
                                                                        selectedDate.getDate() === currentDate.getDate() &&
                                                                        selectedDate.getMonth() === currentDate.getMonth() &&
                                                                        selectedDate.getFullYear() === currentDate.getFullYear()
                                                                    ) {
                                                                        return selectedDate.getTime() >= currentDate.getTime();
                                                                    }

                                                                    // Allow all times for future dates
                                                                    return true;
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="row g-2 justify-content-center align-items-center">
                                                        <div className="col-auto">
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark-blue height-45 w-auto px-4 text-white"
                                                                onClick={handleSaveAsDraft}
                                                                disabled={!isMessageFilled}>
                                                                <span>Save as Draft</span>
                                                            </button>
                                                        </div>
                                                        <div className="col-auto">
                                                            <button
                                                                type="button"
                                                                className="btn btn-border-gray col-md-3 m-2 w-auto text-grey height-45  px-5 font-500"
                                                                onClick={handleDiscard}
                                                                disabled={!isMessageFilled}>
                                                                <span>Discard</span>
                                                            </button>
                                                        </div>
                                                        <div className="col-auto">
                                                            <button
                                                                type="button"
                                                                className="btn btn-green height-45 px-5"
                                                                onClick={() =>
                                                                    handlePublish(isScheduledForLater ? 'scheduled' : 'inprogress')
                                                                }
                                                                disabled={!isMessageFilled}>
                                                                <span>Publish</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="post-preview">
                                            <h2>Post Preview</h2>
                                            <div className="post-preview-data">
                                                {/* {networkType == 'instagram' && (
                                                    <span className="mb-2 d-block font-15">
                                                        <i className="bi bi-info-circle-fill"></i> Aspect ratio of the post must be between
                                                        4:5 (0.8) and 1.91:1 (1.91)
                                                    </span>
                                                )} */}
                                                <div className={`card ${networkType}`} id={`${networkType}-tab`}>
                                                    <div className="card-header d-flex flex-wrap align-items-center p-3">
                                                        <span>
                                                            <img
                                                                className="me-2 rounded"
                                                                src={`${process.env.REACT_APP_IMAGE_BASEPATH}/${networkType}.svg`}
                                                                width="30"
                                                                alt=""
                                                            />
                                                        </span>
                                                        <h5 className="card-title m-0">{networkTypeMap?.[networkType]}</h5>
                                                    </div>
                                                    <div id="post_content" className="card-body">
                                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: postText }} />
                                                        <div className="post-img text-center">
                                                            {fileType == 'image' && (<img alt="" src={image} className="img-fluid mx-auto" />)}
															{
																fileType == 'video' && (
																	<video width="600" controls>
																		<source src={image} type="video/mp4" />
																		Your browser does not support the video tag.
																	</video>
																)
															}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SmartContext.Provider>
            )}
        </>
    );
};
export default SocialPost;