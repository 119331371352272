import React, { useState, useEffect,useContext } from 'react';
// import { BrowserRouter as Router, Route } from 'react-router-dom';
import { axiosClient } from '../../library/Service/axiosClient';
import SessionContext from '../../library/Core/SessionContext';
import { useNavigate } from 'react-router';
import AuthorizedAccounts from './AuthorizedAccounts';
import { getMultiAssociativeArray, isEmpty } from '../../library/Core/SmartFunctions';
import { SmartContext } from '../../library/Core/SmartContext';
import { useImmerReducer } from 'use-immer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import smartReducer from '../../library/Core/SmartReducer';
import { useParams } from 'react-router-dom';
const networkTypeMap: any = {
    facebook: "Facebook",
    instagram: "Instagram",
    linkedin: "LinkedIn",
    // twitter: "Twitter"
}

const ManageSocialIntegrations: React.FC = () => {    
    const navigate = useNavigate();
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
        },
    } as any);
    
    const { sessionState } = useContext(SessionContext);
    const defaultPage = 'facebook';
    const [selectedPage, setSelectedPage] = useState(defaultPage);
    const params: any = useParams();
    const [networkTypes, setNetworkTypes] = useState<any[]>([]);
	const [socialNetworkPages, setSocialNetworkPages] = useState<any[]>([]);
	// const [uniqueNetworkTypes, setUniqueNetworkTypes]: any = useState<any[]>([]);
    
    useEffect(() => {
        const allowedNetworkTypes = Object.keys(networkTypeMap);
        if ( !allowedNetworkTypes.includes(params.tab) && !isEmpty(params.tab)) {
            navigate(`/socialmedia/social-integration`);
            return;
        }

        if (!isEmpty(params.tab)) {
            setSelectedPage(params.tab);
        }
		fetchData();
    }, []);

    useEffect(() => {
        navigate(`/socialmedia/social-integration/${selectedPage}`);
    }, [selectedPage])

    const fetchData = async () => {
        const response: any = await getPageData();
        const networkPageData = getMultiAssociativeArray(response?.data, 'network_type');

        setNetworkTypes(Object.keys(networkPageData));
        setSocialNetworkPages(networkPageData);
    };

    const getPageData = async () => {
        try {
            const params = {
                'institute_id': sessionState?.institutes?.join(',')
            }
            const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/social/get-pages`, params);
            const responseData = await response.data;
            return responseData;
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        }
    };
    
    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <div className="main flex-1 d-flex">
                <div className="container max-1140 flex-1 px-lg-0">
                    <div className="flex-1 d-flex flex-column h-100 w-100">
                        <div className="white-block white-block-notopborderradius h-100 p-0 pt-2 mb-5">
                            <div className="custom-border-gray p-3 p-sm-4 pb-3">
                                <div className="d-flex flex-wrap justify-content-between align-items-center mx-0">
                                    <h3 className="font-35 font-600 mb-0 block-title">Manage Social Integrations</h3>
                                </div>
                            </div>

                            <div className="job_internship_tab polartabsec radius-6 m-0 px-4">
                                <div className="xscroll p-0 ms-0">
                                    <ul className="nav nav-tabs social-integrations" id="JobInternshipTab" role="tablist">
                                        {Object.entries(networkTypeMap).map(([networkType, value]: any, index) => (
                                            <li className="nav-item" role="presentation" key={index}>
                                                <button
                                                    className={`nav-link ${selectedPage == networkType ? 'active' : ''}`}
                                                    id={`${networkType}-tab`}
                                                    data-bs-toggle="tab"
                                                    data-bs-target={`#${networkType}`}
                                                    type="button"
                                                    role="tab"
                                                    aria-controls={networkType}
                                                    aria-selected={selectedPage == networkType ? 'true' : 'false'}
                                                    onClick={() => setSelectedPage(networkType)}>
                                                    <i className={networkType}></i>
                                                    {value}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div className="p-3 p-md-4">
                                <div className="authorized_accounts mt-2">
                                    <h2 className="font-20 font-500">Authorized Accounts</h2>
                                    <div className="tab-content" id="pills-tabContent">
                                        {Object.entries(networkTypeMap).map(([networkType, value]: any, index) => (
                                            <AuthorizedAccounts
                                                key={index}
                                                networkType={networkType}
                                                selectedPage={selectedPage}
                                                data={socialNetworkPages[networkType]}
                                                fetchData={fetchData}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SmartContext.Provider>
    );
};
export default ManageSocialIntegrations;