import { isEmpty, isFormValid, setError, syncProfileMarker } from "../../../../../library/Core/SmartFunctions";
import { ControlOnChangeArguments, DispatchEvent, State } from "../../../../../library/Core/SmartTypes";
import { AlumniProfileEditScreens } from "../../../../../services/screen-navigation.service";
import StudentService from "../../../../../services/student.service";
import { navigateToNextStep } from "../../../services/profile-next-step-navigator";
import { isValidIsCurrentAsPerWorkingStatus } from "../../professional-work/ProfessionalWorkActions";
import levelOfSeniorityData from '../../freelancing-job/levelOfFreelancing.json';

interface DesignationMapping {
    [category: string]: {
        [designation: string]: { [industry: string]: string }[]; 
    };
}

const data = levelOfSeniorityData as DesignationMapping;

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const inValidDate = isValidDate(state, dispatch);
    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);
    
    if (!isImpersonatedSession && (!customValidationPassed || !inValidDate  || !isFormValid(state, dispatch))) {
        console.log("Form validation errors:", state?.formValidationErrors);
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    let freelancingOutcome = state?.data['freelancingOutcome'];

    // freelancingWork = freelancingWork.filter((freelancing: any) => freelancing?.companyId);
    const JObRoleCodeData = Array.from(globalState?.domain || [])
    .filter(([key, value]: any) => value.some((item: any) => item.categoryCode === 'FREELANCING_JOB_ROLE_CODE'))
    .reduce((result: any, [key, value]: any) => {
        value.forEach((item: any) => {
            if (item.categoryCode === 'FREELANCING_JOB_ROLE_CODE') {
                result[item.code] = item.value;
            }
        });

        return result;
    }, {});

    const IndustryData = Array.from(globalState?.domain || [])
        .filter(([key, value]: any) => value.some((item: any) => item.categoryCode === 'INDUSTRY_CODE'))
        .reduce((result: any, [key, value]: any) => {
            value.forEach((item: any) => {
                if (item.categoryCode === 'INDUSTRY_CODE') {
                    result[item.value] = item.code;
                }
            });
            return result;
        }, {});

    const functionalAreaData = Array.from(globalState?.domain || [])
    .filter(([key, value]: any) => value.some((item: any) => item.categoryCode === 'FUNCTIONAL_AREA_CODE'))
    .reduce((result: any, [key, value]: any) => {
        value.forEach((item: any) => {
            if (item.categoryCode === 'FUNCTIONAL_AREA_CODE') {
                result[item.value] = item.code;
            }
        });
        return result;
    }, {});

    const updatedfreelancingWork= freelancingOutcome.map((freelancing: any) => {
        const existingJob = state?.data?.jobsList?.find((existingJob: any) => existingJob.id === freelancing.id);
        return updateJobDetails(freelancing, existingJob, IndustryData,functionalAreaData,JObRoleCodeData, state);
    });

    const jobsNotInreelancingWork = state?.data?.jobsList?.filter((existingJob: any) => 
        !freelancingOutcome.some((job: any) => job.id === existingJob.id)
    );
    freelancingOutcome = [...updatedfreelancingWork, ...jobsNotInreelancingWork];

    const jobAppliedRecords = state?.data?.jobsApplied ?? [];
    const jobHistory = [...freelancingOutcome, ...jobAppliedRecords];

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            jobs: jobHistory,
            profile: state?.data['profile'],
        })
    ).then((response) => {
        if (response?.status === 200) {
            syncProfileMarker(state.routeInfo.id).then(() => {
                navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch, sessionState, sessionDispatch);
            });
        }
    });
};

const updateJobDetails = (freelancing: any, existingJob: any, IndustryData: any,functionalAreaData: any,JObRoleCodeData:any, state: State) => {
    const { jobRoleCode,designationCredited, companyId, ...rest } = freelancing;
    const mappedjobroleCode = jobRoleCode ? jobRoleCode.toString() : null; 
    const jobRolCodeName = mappedjobroleCode ? JObRoleCodeData[mappedjobroleCode] : null;
    
    let designation = null;
    if (jobRolCodeName && data[jobRolCodeName]) {
        designation = data[jobRolCodeName][designationCredited] || null;
    }

    let mappedIndustry = null;
    let functionalArea = null;
    if (designation && Array.isArray(designation) && designation.length > 0) {
        mappedIndustry = Object.keys(designation[0])[0];
        functionalArea = mappedIndustry ? designation[0][mappedIndustry] : null;
    }

    const industryCode = mappedIndustry ? IndustryData[mappedIndustry] ?? null : null;
    const functionalAreaCode = functionalArea ? functionalAreaData[functionalArea] ?? null : null;  

    console.log("industryCode",industryCode)
    console.log("functionalAreaCode",functionalAreaCode) 

    const updatedJob = {
        ...existingJob,
        ...rest,
        recordTypeCode: 'JOINED',
        jobCategory: 'SELF_EMPLOYED_AND_FREELANCING',
        industryCode,
        functionalAreaCode,
        designation: designationCredited || '',
        jobRoleCode:jobRoleCode,
        isOutcome:1
    };
    if (freelancing.isCurrent) {
        updatedJob.endDate = null;
    }
    return updatedJob;
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    let isFormInvalid = false;
    const freelancingOutcome = state.data.freelancingOutcome;
    freelancingOutcome.forEach((freelancingWork: any, index: number) => {
        if (isEmpty(freelancingWork?.companyId) && isEmpty(freelancingWork?.otherCompany)) {
            isFormInvalid = true;
            setError(`freelancingOutcome.${index}.companyId`, [`Please enter "Name of the Company"`], dispatch);
        } else {
            setError(`freelancingOutcome.${index}.companyId`, [], dispatch);
        }
    });

    // Dispatch the validation errors to the state
    if (isFormInvalid) {
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'freelancingOutcome', errorMessages },
        });
    }
    return !isFormInvalid;
};

export const onCurrentJobSelectionChange = (props: ControlOnChangeArguments) => {
    const rowIndex = parseInt(props.parentDataKey.split('.').pop() as string);
    const jobs = props.state.data.freelancingOutcome.map((row: any, index: number) => {
        setError(`freelancingOutcome.${index}.endDate`, [], props.dispatch);
        return index == rowIndex ? { ...row, endYear: '', isCurrent: false } : { ...row, isCurrent: false };
    });
    props.dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey: `${props.parentDataKey}.jobStartAndEndDates`, value: [] } });

    props.dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'freelancingOutcome', value: jobs } });
};


export const onEndDateChange = (props: ControlOnChangeArguments) => {
    const errorMessages =
        new Date(props.value) < new Date(props.row.startDate) ? ['End year should be greater than or equal to Start year'] : [];
    props.errorMessages.push(errorMessages);
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
};

const isValidDate = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const freelancingOutcomeWorkData = state?.data['freelancingOutcome'];
    let isFormValid = true;
    freelancingOutcomeWorkData.forEach((freelancingOutcome: any, index: number) => {
        if (isEmpty(freelancingOutcome?.startDate)) {
            setError(`freelancingOutcome.${index}.startDate`, [`Please enter "Start Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`freelancingOutcome.${index}.startDate`, [], dispatch);
        }

        if (
            (freelancingOutcome?.isCurrent === false || isEmpty(freelancingOutcome?.isCurrent)) &&
            isEmpty(freelancingOutcome?.endDate)
        ) {
            setError(`freelancingOutcome.${index}.endDate`, [`Please enter "End Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`freelancingOutcome.${index}.endDate`, [], dispatch);
        }

        if (new Date(freelancingOutcome?.startDate) >= new Date(freelancingOutcome?.endDate)) {
            console.log('Invalid date');
        }

        if (freelancingOutcome?.isCurrent === false || isEmpty(freelancingOutcome?.isCurrent)) {
            if (
                isEmpty(freelancingOutcome?.endDate) ||
                new Date(freelancingOutcome?.startDate) > new Date(freelancingOutcome?.endDate)
            ) {
                setError(`freelancingOutcome.${index}.endDate`, [`End year should be greater than or equal to Start year`], dispatch);
                isFormValid = false;
            } else {
                setError(`freelancingOutcome.${index}.endDate`, [], dispatch);
            }
        }
    });

    return isFormValid;
};


