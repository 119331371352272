import { activateUserSession, getDomainValueForCode, isEmpty, isFormValid, setError, syncProfileMarker } from '../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { axiosClient } from '../../../../library/Service/axiosClient';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
// import { toStudentEntity } from '../../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';
import { isValidIsCurrentAsPerWorkingStatus } from '../professional-work/ProfessionalWorkActions';
import levelOfSeniorityData from './levelOfFreelancing.json';

interface DesignationMapping {
    [category: string]: {
        [designation: string]: { [industry: string]: string }[]; 
    };
}

const data = levelOfSeniorityData as DesignationMapping;

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    const inValidDate = isValidDate(state, dispatch);
    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);
    
    if (!isImpersonatedSession && (!customValidationPassed || !inValidDate  || !isFormValid(state, dispatch))) {
        console.log("Form validation errors:", state?.formValidationErrors);
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    let freelancingWork = state?.data['freelancing'];

    // freelancingWork = freelancingWork.filter((freelancing: any) => freelancing?.companyId);
    const JObRoleCodeData = Array.from(globalState?.domain || [])
    .filter(([key, value]: any) => value.some((item: any) => item.categoryCode === 'FREELANCING_JOB_ROLE_CODE'))
    .reduce((result: any, [key, value]: any) => {
        value.forEach((item: any) => {
            if (item.categoryCode === 'FREELANCING_JOB_ROLE_CODE') {
                result[item.code] = item.value;
            }
        });
        return result;
    }, {});

    const IndustryData = Array.from(globalState?.domain || [])
        .filter(([key, value]: any) => value.some((item: any) => item.categoryCode === 'INDUSTRY_CODE'))
        .reduce((result: any, [key, value]: any) => {
            value.forEach((item: any) => {
                if (item.categoryCode === 'INDUSTRY_CODE') {
                    result[item.value] = item.code;
                }
            });
            return result;
        }, {});

    const functionalAreaData = Array.from(globalState?.domain || [])
    .filter(([key, value]: any) => value.some((item: any) => item.categoryCode === 'FUNCTIONAL_AREA_CODE'))
    .reduce((result: any, [key, value]: any) => {
        value.forEach((item: any) => {
            if (item.categoryCode === 'FUNCTIONAL_AREA_CODE') {
                result[item.value] = item.code;
            }
        });
        return result;
    }, {});

    const updatedfreelancingWork= freelancingWork.map((freelancing: any) => {
        const existingJob = state?.data?.jobsList?.find((existingJob: any) => existingJob.id === freelancing.id);
        return updateJobDetails(freelancing, existingJob, IndustryData,functionalAreaData,JObRoleCodeData, state);
    });

    const jobsNotInreelancingWork = state?.data?.jobsList?.filter((existingJob: any) => 
        !freelancingWork.some((job: any) => job.id === existingJob.id)
    );
    freelancingWork = [...updatedfreelancingWork, ...jobsNotInreelancingWork];

    const jobAppliedRecords = state?.data?.jobsApplied ?? [];
    const jobHistory = [...freelancingWork, ...jobAppliedRecords];

     //For checking is_current as per WorkingStatus
    const isValidIsCurrent = isValidIsCurrentAsPerWorkingStatus('freelancing',jobHistory,state, dispatch);
    if (!isValidIsCurrent) {
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            jobs: jobHistory,
            profile: state?.data['profile'],
        })
    ).then((response) => {
        if (response?.status === 200) {
            syncProfileMarker(state.routeInfo.id).then(() => {
                navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch, sessionState, sessionDispatch);
            });
        }
    });
};

const updateJobDetails = (freelancing: any, existingJob: any, IndustryData: any,functionalAreaData: any,JObRoleCodeData:any, state: State) => {
    const { jobRoleCode,designationCredited, companyId, ...rest } = freelancing;
    const mappedjobroleCode = jobRoleCode ? jobRoleCode.toString() : null; 
    const jobRolCodeName = mappedjobroleCode ? JObRoleCodeData[mappedjobroleCode] : null;
    
    let designation = null;
    if (jobRolCodeName && data[jobRolCodeName]) {
        designation = data[jobRolCodeName][designationCredited] || null;
    }

    let mappedIndustry = null;
    let functionalArea = null;
    if (designation && Array.isArray(designation) && designation.length > 0) {
        mappedIndustry = Object.keys(designation[0])[0];
        functionalArea = mappedIndustry ? designation[0][mappedIndustry] : null;
    }

    const industryCode = mappedIndustry ? IndustryData[mappedIndustry] ?? null : null;
    const functionalAreaCode = functionalArea ? functionalAreaData[functionalArea] ?? null : null;  

    console.log("industryCode",industryCode)
    console.log("functionalAreaCode",functionalAreaCode) 

    const updatedJob = {
        ...existingJob,
        ...rest,
        recordTypeCode: 'JOINED',
        jobCategory: 'SELF_EMPLOYED_AND_FREELANCING',
        industryCode,
        functionalAreaCode,
        designation: designationCredited || '',
        jobRoleCode:jobRoleCode,
    };
    if (freelancing.isCurrent) {
        updatedJob.endDate = null;
    }
    return updatedJob;
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    let isFormInvalid = false;
    const freelancingWork = state.data.freelancing;
    freelancingWork.forEach((freelancingWork: any, index: number) => {
        if (isEmpty(freelancingWork?.companyId) && isEmpty(freelancingWork?.otherCompany)) {
            isFormInvalid = true;
            setError(`freelancing.${index}.companyId`, [`Please enter "Name of the Company"`], dispatch);
        } else {
            setError(`freelancing.${index}.companyId`, [], dispatch);
        }
    });

    // Dispatch the validation errors to the state
    if (isFormInvalid) {
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'freelancingWork', errorMessages },
        });
    }
    return !isFormInvalid;
};

export const onCurrentJobSelectionChange = (props: ControlOnChangeArguments) => {
    const rowIndex = parseInt(props.parentDataKey.split('.').pop() as string);
    const jobs = props.state.data.freelancing.map((row: any, index: number) => {
        setError(`freelancing.${index}.endDate`, [], props.dispatch);
        return index == rowIndex ? { ...row, endYear: '', isCurrent: false } : { ...row, isCurrent: false };
    });
    props.dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey: `${props.parentDataKey}.jobStartAndEndDates`, value: [] } });

    props.dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'freelancing', value: jobs } });
};


export const onEndDateChange = (props: ControlOnChangeArguments) => {
    const errorMessages =
        new Date(props.value) < new Date(props.row.startDate) ? ['End year should be greater than or equal to Start year'] : [];
    props.errorMessages.push(errorMessages);
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
};

const isValidDate = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const freelancingWorkData = state?.data['freelancing'];
    let isFormValid = true;
    freelancingWorkData.forEach((freelancingWork: any, index: number) => {
        if (isEmpty(freelancingWork?.startDate)) {
            setError(`freelancing.${index}.startDate`, [`Please enter "Start Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`freelancing.${index}.startDate`, [], dispatch);
        }

        if (
            (freelancingWork?.isCurrent === false || isEmpty(freelancingWork?.isCurrent)) &&
            isEmpty(freelancingWork?.endDate)
        ) {
            setError(`freelancing.${index}.endDate`, [`Please enter "End Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`freelancing.${index}.endDate`, [], dispatch);
        }

        if (new Date(freelancingWork?.startDate) >= new Date(freelancingWork?.endDate)) {
            console.log('Invalid date');
        }

        if (freelancingWork?.isCurrent === false || isEmpty(freelancingWork?.isCurrent)) {
            if (
                isEmpty(freelancingWork?.endDate) ||
                new Date(freelancingWork?.startDate) > new Date(freelancingWork?.endDate)
            ) {
                setError(`freelancing.${index}.endDate`, [`End year should be greater than or equal to Start year`], dispatch);
                isFormValid = false;
            } else {
                setError(`freelancing.${index}.endDate`, [], dispatch);
            }
        }
    });

    return isFormValid;
};


