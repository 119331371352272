import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

const FutureLeadersThree: React.FC = () => {
    const [alumniName, setAlumniName] = useState('');
    const [primaryColor, setPrimaryColor] = useState('#ffffff');
    const [secondaryColor, setSecondaryColor] = useState('#fffff');
    const [schoolImage, setSchoolImage] = useState<string>(localStorage.getItem('school-image') || '');
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setUgLogo(imageUrl);
        }
    };
    const [isJoinedUGCollege, setIsJoinedUGCollege] = useState(true); // Default to checked

    const handleUGCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsJoinedUGCollege(event.target.checked);
    };
    const [isJoinedPGCollege, setIsJoinedPGCollege] = useState(true); // Default to checked

    const handlePGCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsJoinedPGCollege(event.target.checked);
    };
    const [isJoinedCompanyCollege, setIsJoinedComapnyCollege] = useState(true); // Default to checked

    const handleCompanyCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsJoinedComapnyCollege(event.target.checked);
    };
    const jwtToken = localStorage.getItem('user-jwt-token');
    const [ugLogo, setUgLogo] = useState('https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/p1-logo.jpg');
    const divRef = useRef<HTMLDivElement>(null);
    const previewRef = useRef<Window | null>(null);
    const [ugCollegeName, setUgCollegeName] = useState('');
    const [ugCourseName, setUgCourseName] = useState('');
    const [pgCollegeName, setPgCollegeName] = useState('');
    const [pgCourseName, setPgCourseName] = useState('');
    const [pgLogo, setPgLogo] = useState('https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/p1-logo.jpg');

    const handlePgFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setPgLogo(imageUrl);
        }
    };
    useEffect(() => {
        if (!jwtToken) {
            window.location.href = '/404'; // Redirect to 404 page
        }
    }, []);

    function dataURLtoBlob(dataUrl: string): Blob {
        const [metadata, base64] = dataUrl.split(',');
        const byteString = atob(base64);
        const mimeString = metadata.split(':')[1].split(';')[0];

        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }

        return new Blob([uintArray], { type: mimeString });
    }

    const handleShareOnSocialMedia = async () => {
        if (!divRef.current) {
            console.error('Element not found!');
            return;
        }

        const { default: html2canvas } = await import('html2canvas');
        const scaleFactor = 3; // Increase scale factor for better quality
        const canvas = await html2canvas(divRef.current, {
            backgroundColor: secondaryColor,
            scale: scaleFactor,
            useCORS: true,
            allowTaint: false,
        });

        const dataUrl = canvas.toDataURL('image/png', 1.0); // Use maximum quality
        const imageBlob = dataURLtoBlob(dataUrl); // Convert base64 to Blob

        // Step 2: Upload the image to S3 via your API
        const apiUrl = `${process.env.REACT_APP_BROADCAST_SERVER}/upload/get-attached`;
        const formData = new FormData();
        formData.append('pdf_doc', imageBlob, 'spotlight_image.png');

        try {
            // Include the token in the headers for authentication
            const response = await axios.post(apiUrl, formData, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`, // Include the token in the Authorization header
                },
            });

            const link = response.data.link;

            if (response.data.success) {
                // Step 3: Store the S3 URL in sessionStorage
                sessionStorage.setItem('spotlight_post_image_url', link);

                // Redirect to the "get-image" page
                window.location.href = '/socialmedia/socialpost?social_post=1'; // Adjust this to your route for displaying the image
            } else {
                alert('Error uploading image to S3');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            alert('Failed to upload image.');
        }
    };
    const handleDownload = async () => {
        if (!divRef.current) {
            console.error('Element not found!');
            return;
        }

        const images = Array.from(divRef.current.querySelectorAll('img')) as HTMLImageElement[];

        try {
            await Promise.all(
                images.map((img) => {
                    return new Promise((resolve, reject) => {
                        if (img.complete && img.naturalWidth > 0) {
                            resolve(true);
                        } else {
                            img.onload = () => resolve(true);
                            img.onerror = () => reject(false);
                        }
                    });
                })
            );

            const { default: html2canvas } = await import('html2canvas');
            const scaleFactor = 2; // Increase scale factor for better quality (400px / 400px = 2.7, so 3 is safe)
            const canvas = await html2canvas(divRef.current, {
                backgroundColor: secondaryColor,
                scale: scaleFactor, // Increase scale factor
                useCORS: true,
                allowTaint: false,
            });

            const scaledCanvas = document.createElement('canvas');
            scaledCanvas.width = 400; // Set desired width
            scaledCanvas.height = 400; // Set desired height
            const scaledContext = scaledCanvas.getContext('2d');

            if (scaledContext) {
                scaledContext.fillStyle = '#ffffff';
                scaledContext.fillRect(0, 0, 400, 400);
                scaledContext.drawImage(canvas, 0, 0, 400, 400);

                const dataUrl = scaledCanvas.toDataURL('image/png', 1.0); // Use maximum quality
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'spotlights-pic.jpg'; // Updated file name
                link.click();
            }
        } catch (error) {
            console.error('Error generating image:', error);
        }
    };
    if (!previewRef.current || previewRef.current.closed) {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const popupWidth = 400; // Set desired width
        const popupHeight = 400; // Set desired height
        const left = (screenWidth - popupWidth) / 2;
        const top = (screenHeight - popupHeight) / 2;
    }
    const handlePreview = async () => {
        if (!divRef.current) {
            console.error('Element not found!');
            return;
        }

        const { default: html2canvas } = await import('html2canvas');
        const scaleFactor = 3; // Increase scale factor for better quality
        const canvas = await html2canvas(divRef.current, {
            backgroundColor: secondaryColor,
            scale: scaleFactor, // Increase scale factor
            useCORS: true,
            allowTaint: false,
        });

        if (!previewRef.current || previewRef.current.closed) {
            const screenWidth = window.screen.width;
            const screenHeight = window.screen.height;
            const popupWidth = 400; // Set desired width
            const popupHeight = 400; // Set desired height
            const left = (screenWidth - popupWidth) / 2;
            const top = (screenHeight - popupHeight) / 2;
            previewRef.current = window.open('', 'Preview', `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`);
        }

        if (previewRef.current) {
            previewRef.current.document.body.innerHTML = '';
            previewRef.current.document.write(
                '<html><head><title>Preview</title></head><body style="margin: 0; display: flex; align-items: center; justify-content: center; background: #ffffff;"></body></html>'
            );
            const img = document.createElement('img');
            img.src = canvas.toDataURL('image/png', 1.0); // Use maximum quality
            img.style.width = '100%';
            img.style.height = 'auto';
            previewRef.current.document.body.appendChild(img);
        }
    };

    const [companyName, setCompanyName] = useState('');
    const [experties, setExperties] = useState('');
    const [designation, setDesignation] = useState('');
    const [companyLogo, setCompanyLogo] = useState('https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/p1-logo.jpg');

    const handleCompanyFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setCompanyLogo(imageUrl);
        }
    };
    useEffect(() => {
        const fetchSchoolLogo = async () => {
            try {
                const user = sessionStorage.getItem('user');
                const primaryInstitute = user ? JSON.parse(user).primaryInstitute : 0;

                const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/pre-signup/get-institute-name`, {
                    id: primaryInstitute,
                });

                if (response.data?.success && response.data.result?.[0]?.logo) {
                    const logoUrl = response.data.result[0].logo;
                    setSchoolImage(logoUrl);
                } else {
                    console.warn('No school logo returned from the API. Using fallback image.');
                }
            } catch (error) {
                console.error('Error fetching school logo:', error);
            }
        };

        fetchSchoolLogo();
    }, []);
    const [alumniProgramBatch, setAlumniProgramBatch] = useState('');
    const [profilePicture, setProfilePicture] = useState('https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/profile_pic.png');

    const handleProfileFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setProfilePicture(imageUrl);
        }
    };
    return (
        <div className="main flex-1 bg-white">
            <section className="alumni_directory_details">
                <div className="max-1140 pb-5 mx-auto">
                    <div className="row g-4">
                        <div className="col-sm-12">
                            <div className="bg-gray-F2F4F7 border-radius-6 p-3 p-sm-4 px-md-5 text-center font-24 font-500">
                                Leaders of the Future 3
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="row g-2 justify-content-between pb-2">
                                <div className="col-auto font-20 font-500">
                                    Category <span className="font-18 font-400">Success Stories</span>
                                </div>
                                <div className="col-auto font-20 font-500">
                                    Size <span className="font-18 font-400">Instagram Post - 400 x 400 px</span>
                                </div>
                            </div>
                            <div className="bg-gray-F2F4F7 border-radius-6 p-3 p-sm-4">
                                <div className="row g-4">
                                    <div className="col-md-6">
                                        <div className="w-100 h-100">
                                            <div className="bg-white border-radius-6 p-4 w-100 border h-100 d-flex justify-content-center align-items-start">
                                                <div className="position-sticky top-20">
                                                    <div className="xscroll">
                                                        <div className="max-430 min-430 mx-auto">
                                                            <div
                                                                className="leaders-poster poster-3 border"
                                                                style={{ backgroundColor: primaryColor }}
                                                                ref={divRef}>
                                                                <i className="slant" style={{ backgroundColor: secondaryColor }}></i>
                                                                <img
                                                                    src="https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/poster-white-bg.png"
                                                                    style={{ opacity: 0 }}
                                                                    className="img-fluid"
                                                                    alt=""
                                                                />

                                                                <div className="college-logo">
                                                                    <img
                                                                        src={schoolImage} // Fallback to `undefined` if `base64Image` is `null`
                                                                        width="90"
                                                                        alt="Logo"
                                                                        crossOrigin="anonymous"
                                                                    />
                                                                </div>

                                                                <div className="badge-template">
                                                                    <img
                                                                        src="https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/g-f.gif"
                                                                        className="img-fluid"
                                                                        alt=""
                                                                        crossOrigin="anonymous"
                                                                    />
                                                                </div>

                                                                <div className="leaders-poster-alumni bg-transparent">
                                                                    <img
                                                                        id="profilePicture"
                                                                        src={profilePicture}
                                                                        width="100"
                                                                        className="img-fluid"
                                                                    />
                                                                </div>

                                                                <div className="leaders-poster-alumni-detail">
                                                                    <div className="leaders-poster-alumni-name text-white">
                                                                        <span id="alumniNameDisplay">{alumniName || 'Vikas'}</span>
                                                                    </div>
                                                                    <div className="leaders-poster-alumni-batch-school text-white">
                                                                        {' '}
                                                                        {alumniProgramBatch || 'Batch 2014'} <i></i>
                                                                    </div>
                                                                    {isJoinedUGCollege && (
                                                                        <div className="pursuing d-flex ed mb-2">
                                                                            <i></i>
                                                                            <div className="pursuing-title font-13 font-500 text-white">
                                                                                {ugCollegeName || 'MIT School of Management'}
                                                                                <br /> {ugCourseName || 'BBA'}
                                                                            </div>
                                                                            <div className="pursuing-logo rightside ms-auto">
                                                                                <img
                                                                                    id="uglogo"
                                                                                    src={ugLogo}
                                                                                    width="60"
                                                                                    className="img-fluid"
                                                                                    alt="UG Logo Preview"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {isJoinedPGCollege && (
                                                                        <div className="pursuing d-flex ed mb-2">
                                                                            <i></i>
                                                                            <div className="pursuing-title font-13 font-500 text-white">
                                                                                <span>{pgCourseName || 'MBA'}</span> <br />
                                                                                <span id="pgcollage">
                                                                                    {pgCollegeName || 'Harvard Business School'}
                                                                                </span>
                                                                            </div>

                                                                            <div className="pursuing-logo rightside ms-auto">
                                                                                <img
                                                                                    id="pglogo"
                                                                                    src={pgLogo}
                                                                                    width="60"
                                                                                    className="img-fluid"
                                                                                    alt="PG Logo Preview"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {isJoinedCompanyCollege && (
                                                                        <div className="pursuing d-flex ed">
                                                                            <i></i>
                                                                            <div className="pursuing-title font-13 font-500 text-white">
                                                                                <span id="designationDisplay">
                                                                                    {designation || 'Software Engineer'}
                                                                                </span>{' '}
                                                                                <br />
                                                                                <span id="companyDisplay">{companyName || 'Google'}</span>
                                                                            </div>
                                                                            <div className="pursuing-logo rightside ms-auto">
                                                                                <img
                                                                                    id="companylogo"
                                                                                    src={companyLogo}
                                                                                    width="60"
                                                                                    className="img-fluid"
                                                                                    alt="Company Logo Preview"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className="expertise text-white">
                                                                    <strong>Expertise</strong>{' '}
                                                                    {experties || 'Strategic Planning & Market Research'}
                                                                </div>
                                                                <div className="powerby poster-powerby text-white position-absolute w-auto">
                                                                    <span className="font-13">Powered By </span>
                                                                    <img
                                                                        src="https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/univariety-white-logo.svg"
                                                                        className="img-fluid"
                                                                        width="100"
                                                                        alt=""
                                                                        crossOrigin="anonymous"
                                                                    />
                                                                </div>

                                                                <i className="bottom-strip"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="bg-white border-radius-6 p-4 h-100">
                                            <div className="h-100 pt-3">
                                                <form className="row g-3">
                                                    <div className="col-md-12">
                                                        <div className="row g-3 align-items-center">
                                                            <div className="col-12">
                                                                <label className="form-label m-0 mb-0 font-16 font-600">
                                                                    ADD ALUMNI PROFILE
                                                                </label>
                                                            </div>
                                                            <div className="col-auto col-md-auto col-lg-6"></div>
                                                            <div className="col-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Alumni Name"
                                                                    value={alumniName}
                                                                    onChange={(e) => setAlumniName(e.target.value)}
                                                                />
                                                            </div>

                                                            <div className="col-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Alumni Program and Batch Year"
                                                                    value={alumniProgramBatch}
                                                                    onChange={(e) => setAlumniProgramBatch(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="upload_section">
                                                        <input
                                                            type="file"
                                                            id="cloud-computing"
                                                            accept="image/*"
                                                            onChange={handleProfileFileUpload}
                                                        />
                                                        <div className="row g-2 align-items-center justify-content-center">
                                                            <div className="col-sm d-flex align-items-center justify-content-center">
                                                                <div className="cloud-wrap px-3">
                                                                    <div className="cloud-img">
                                                                        <img
                                                                            src="https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/cloud-computing.svg"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div className="cloud-text d-flex align-items-center">
                                                                        Upload Profile Picture{' '}
                                                                        <a href="#" className="btn-darkblue text-decoration-none ms-2">
                                                                            Upload
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="row g-3 align-items-center">
                                                            <div className="col-sm-5 col-auto">
                                                                <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="DataSet"
                                                                        id="JoinedUGCollege"
                                                                        value="Joined UG College"
                                                                        className="form-check-input ratio20 m-0"
                                                                        checked={isJoinedUGCollege}
                                                                        onChange={handleUGCheckboxChange}
                                                                    />
                                                                    <label
                                                                        htmlFor="JoinedUGCourse"
                                                                        className="form-check-label font-16 font-500 ms-2">
                                                                        Joined UG College
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-7 col">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter UG College Name"
                                                                    id="ugcollegename"
                                                                    value={ugCollegeName}
                                                                    onChange={(e) => setUgCollegeName(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="row g-3 align-items-center">
                                                            <div className="col-sm-5 col-auto">
                                                                <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                                                    <label
                                                                        htmlFor="JoinedUGCourse"
                                                                        className="form-check-label font-16 font-500 ms-2">
                                                                        Joined UG Course
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-7 col">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter PG Course Name"
                                                                    id="ugcoursename"
                                                                    value={ugCourseName}
                                                                    onChange={(e) => setUgCourseName(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="upload_section">
                                                        <input type="file" id="uploadug" accept="image/*" onChange={handleFileUpload} />
                                                        <div className="row g-2 align-items-center justify-content-center">
                                                            <div className="col-sm d-flex align-items-center justify-content-center">
                                                                <div className="cloud-wrap px-3">
                                                                    <div className="cloud-img">
                                                                        <img
                                                                            src="https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/cloud-computing.svg"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div className="cloud-text d-flex align-items-center">
                                                                        Upload UG Institute Logo{' '}
                                                                        <a href="#" className="btn-darkblue text-decoration-none ms-2">
                                                                            Upload
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="row g-3 align-items-center">
                                                            <div className="col-sm-5 col-auto">
                                                                <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="DataSet"
                                                                        id="JoinedUGCollege"
                                                                        value="Joined UG College"
                                                                        className="form-check-input ratio20 m-0"
                                                                        checked={isJoinedPGCollege}
                                                                        onChange={handlePGCheckboxChange}
                                                                    />
                                                                    <label
                                                                        htmlFor="pgcollegename"
                                                                        className="form-check-label font-16 font-500 ms-2">
                                                                        Joined PG College
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-7 col">
                                                                <input
                                                                    id="pgcollegename"
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter College Name"
                                                                    value={pgCollegeName}
                                                                    onChange={(e) => setPgCollegeName(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* PG Course Name Input */}
                                                    <div className="col-12">
                                                        <div className="row g-3 align-items-center">
                                                            <div className="col-sm-5 col-auto">
                                                                <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                                                    <label
                                                                        htmlFor="pgcoursename"
                                                                        className="form-check-label font-16 font-500 ms-2">
                                                                        Joined PG Course
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-7 col">
                                                                <input
                                                                    id="pgcoursename"
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Course Name"
                                                                    value={pgCourseName}
                                                                    onChange={(e) => setPgCourseName(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* PG Logo Upload */}
                                                    {/* PG Logo Upload */}

                                                    <div className="upload_section">
                                                        <input type="file" id="uploadpg" accept="image/*" onChange={handlePgFileUpload} />
                                                        <div className="row g-2 align-items-center justify-content-center">
                                                            <div className="col-sm d-flex align-items-center justify-content-center">
                                                                <div className="cloud-wrap px-3">
                                                                    <div className="cloud-img">
                                                                        <img
                                                                            src="https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/cloud-computing.svg"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div className="cloud-text d-flex align-items-center">
                                                                        Upload PG Institute Logo{' '}
                                                                        <a href="#" className="btn-darkblue text-decoration-none ms-2">
                                                                            Upload
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="row g-3 align-items-center">
                                                            <div className="col-sm-5 col-auto">
                                                                <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="DataSet"
                                                                        id="JoinedUGCollege"
                                                                        value="Joined UG College"
                                                                        className="form-check-input ratio20 m-0"
                                                                        checked={isJoinedCompanyCollege}
                                                                        onChange={handleCompanyCheckboxChange}
                                                                    />
                                                                    <label
                                                                        htmlFor="companyname"
                                                                        className="form-check-label font-16 font-500 ms-2">
                                                                        Company Name
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-7 col">
                                                                <input
                                                                    id="companyname"
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Company Name"
                                                                    value={companyName}
                                                                    onChange={(e) => setCompanyName(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Designation Input */}
                                                    <div className="col-12">
                                                        <div className="row g-3 align-items-center">
                                                            <div className="col-sm-5 col-auto">
                                                                <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                                                    <label
                                                                        htmlFor="designation"
                                                                        className="form-check-label font-16 font-500 ms-2">
                                                                        Designation
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-7 col">
                                                                <input
                                                                    id="designation"
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Designation Name"
                                                                    value={designation}
                                                                    onChange={(e) => setDesignation(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Company Logo Upload */}

                                                    <div className="upload_section">
                                                        <input
                                                            type="file"
                                                            id="uploadcompany"
                                                            accept="image/*"
                                                            onChange={handleCompanyFileUpload}
                                                        />
                                                        <div className="row g-2 align-items-center justify-content-center">
                                                            <div className="col-sm d-flex align-items-center justify-content-center">
                                                                <div className="cloud-wrap px-3">
                                                                    <div className="cloud-img">
                                                                        <img
                                                                            src="https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/cloud-computing.svg"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div className="cloud-text d-flex align-items-center">
                                                                        Upload Company Logo{' '}
                                                                        <a href="#" className="btn-darkblue text-decoration-none ms-2">
                                                                            Upload
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="row g-3 align-items-center">
                                                            <div className="col-sm-5 col-auto">
                                                                <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                                                    <label
                                                                        htmlFor="ExpertiseText"
                                                                        className="form-check-label font-16 font-500 ms-2">
                                                                        Expertise Text
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-7 col">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Expertise Details"
                                                                    value={experties}
                                                                    onChange={(e) => setExperties(e.target.value)}
                                                                    maxLength={65}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label className="form-label m-0 mb-1 font-16 font-500">Primary Color Shade</label>
                                                        <div className="color-field">
                                                            <label>
                                                                <img
                                                                    src="https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/color-fill.svg"
                                                                    alt=""
                                                                />
                                                            </label>
                                                            <input
                                                                type="color"
                                                                className="flex-1"
                                                                value={primaryColor}
                                                                onChange={(e) => setPrimaryColor(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label className="form-label m-0 mb-1 font-16 font-500">
                                                            Secondary Color Shade
                                                        </label>
                                                        <div className="color-field">
                                                            <label>
                                                                <img
                                                                    src="https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/color-fill.svg"
                                                                    alt=""
                                                                />
                                                            </label>
                                                            <input
                                                                type="color"
                                                                className="flex-1"
                                                                value={secondaryColor}
                                                                onChange={(e) => setSecondaryColor(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="action_btns max-100per mt-0">                          
                                <button type="button" className="nextbtn my-2 my-sm-0 mx-2 px-5" onClick={handlePreview}>
                                    <span className="px-3">Preview</span>
                                </button>
                                <button type="button" className="btn btn-green my-2 my-sm-0 mx-2 height-45 px-5" onClick={handleDownload}>
                                    <span className="px-3">Save as Image</span>
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-border-gray my-2 my-sm-0 mx-3 height-45"
                                    onClick={handleShareOnSocialMedia}>
                                    <span className="px-3">Share on Social Media</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default FutureLeadersThree;
