import { AxiosResponse } from 'axios';
import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';
import pageConfig from './PageConfignew.json';
import { format, isValid } from 'date-fns';

class AddnewService {
    
    static pageLoader(params: any) {
        const state: any = {};
        const { eventId, pageName } = params;
        
        return Promise.all([this.getPageConfig()])
            .then((values) => {
                state.formConfig = values[0];
                state.routeInfo = { pageName };
                
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }





}
export default AddnewService;
