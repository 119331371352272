import { useEffect, useState } from 'react';
import { getMenuType, isFormValid, syncProfileMarker } from '../../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import logger from '../../../../library/Core/logger';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
// import { toStudentEntity } from '../../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';
import axios from 'axios';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    logger.log(state.data['profile']);

    const errorKeysToIgnore = getErrorsToIgnore(state);
    // const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';

    // let sessionData = { ...sessionState };

    // if (!isImpersonatedSession && !isFormValid(state, dispatch, errorKeysToIgnore)) {
    if (!isFormValid(state, dispatch, errorKeysToIgnore)) {
        alert('Please correct the form errors!');
        return;
    }

    // Send event to Moengage my basics
    sendMoEngageEvent(state?.data);

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            selectedInstitute: state?.data?.selectedInstitute,
            userInstitutes: state?.data?.userInstitutes,
            user:{
                ...state?.data?.user,
                mobileNumber:state?.data?.profile.contactMobileNumber,
            },
            profile: {
                ...state?.data?.profile,
                pursuingEducationLevelCode: state?.data?.profile.presentEducationStatusCode == 'COMPLETED_STUDIES' ? null : state?.data?.profile.pursuingEducationLevelCode,
                highestCompletedEducationLevelCode: state?.data?.profile.presentEducationStatusCode != 'COMPLETED_STUDIES' ? null : state?.data?.profile.highestCompletedEducationLevelCode,
                contactEmailId: state?.data?.profile.contactEmailId,
                contactMobileNumber: state?.data?.profile.contactMobileNumber,
                contactMobileCountryCode: state?.data?.profile.contactMobileCountryCode,
            },
        })
    ).then(() => {
        let studentId = sessionState.id;
        if ( ! sessionState.id ) {
            studentId = sessionState.studentId;
        }

        // let menuType = getMenuType(state?.data?.profile);
        // state.actions['SET_MENU_TYPE'](menuType);

        // navigateToNextStep(AlumniProfileEditScreens.BASIC_INFORMATION, state, dispatch, sessionState);
        // setTimeout(() => {
        //     navigateToNextStep(AlumniProfileEditScreens.BASIC_INFORMATION, state, dispatch, sessionState);
        // }, 500);

        syncProfileMarker(state.routeInfo.id).then(() => {
            navigateToNextStep(AlumniProfileEditScreens.BASIC_INFORMATION, state, dispatch, sessionState);
        })
        
        // --------------start
        // if ( sessionState?.userStatusCode == 'ACTIVE' ) {
        //     state.actions['REDIRECT'](`/alumni/${studentId}/profile`);
        //     return;
        // }

        // const workStatusList = ['WORKING_FOR_A_COMPANY', 'PROFESSIONAL_PRACTICE', 'IN_DEFENCE', 'ENTREPRENEURSHIP'];

        // if ( workStatusList.includes(state?.data?.profile?.workingStatusCode) ) {
        //     state.actions['REDIRECT'](`/alumni/${studentId}/work`);
        //     return;
        // }

        // state.actions['REDIRECT'](`/alumni/${studentId}/outcome`);
        //-----------------end
    });
};

const getErrorsToIgnore = (state: State) => {
    if (state.data.profile.passOutYear > new Date().getFullYear() - 2 && state.data.profile.didYouTakeGapAfter12 === true)
        return ['profile.educationOrEmploymentCurrentStatusCode'];
};

const sendMoEngageEvent = async (user:any) => {

    // console.log('============USER=================');
    // console.log(user);
    // return false;

    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-basic-info`, {
            userInfo       : user
        });
        // console.log('Response:', response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    // return false;
  };