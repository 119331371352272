import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import smartReducer from '../../../library/Core/SmartReducer';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
//import { onMobileCountryCodeChange, onMobileNumberChange, twelthPassoutYear } from './CreateAlumniActions';
import { onSpeakernameChange } from './SpeakerInfoActions';
import Layout from './Layout';
//import SpeakerInfoService from './SpeakerInfoService';
//D:\userreactappuei_old\src\features\alumni\alumni-profile\sections\admin\ProfileMarker\ProfileMakerService.js
//import ProfileMarkerService from '../../../features/alumni/alumni-profile/sections/admin/ProfileMarker/ProfileMakerService.js';
import { addUserSpecificDomains } from '../../../library/Service/domainService';
import { useParams } from 'react-router-dom';
import UsersListService from './UsersListService';
import Swal from 'sweetalert2';
const SpeakerInfo = () => {
    const { globalState } = useContext(GlobalContext);
   // const { state, dispatch } = useContext(SmartContext);
    const navigate = useNavigate();
    const { routeInfo, formConfig, data } = useLoaderData() as State;
    // const queryParams = new URLSearchParams(window.location.search);
    // const eventId = queryParams.get('id');
    const { eventId } = useParams(); // Retrieve eventId here
    const { allUsers } = UsersListService();
    const [speakerData, setSpeakerData] = useState({
        data: { speakerinfo: [] }
    });


    const safeEventId = eventId || ""; // Provide a default empty string if undefined
    if(!eventId){
        navigate(`/event/add`);
    }
    
    // const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
    //     flags: { isDataLoading: true, showFormErrors: 0 },
    //     formValidationErrors: {},
    //     actions: {
    //         // SAVE: handleCreate,
    //         speakername: onSpeakernameChange,
    //         SAVE: handleSaveAndNext,
    //         SAVE_AND_EXIT: handleSaveAndExit,
    //         SAVE_AND_NEXT: handleSaveAndNext,
    //     },
    // } as any);

    const initialState: State = {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        data: { speakerinfo: [] }, // ✅ Always initialize this
        domain: new Map(),
        routeInfo: {
            pageName: "update-event-speaker",
            id: "",
            ids: [],
            school: "",
            schoolDomain: "",
            newMessageMode: false,
            code: "",
        },
        filters: {},
        internal: {},
        pageDomain: new Map(),
        businessValidationErrors: [],
        actions: {
            speakername: onSpeakernameChange,
            SAVE: handleSaveAndNext,
            SAVE_AND_EXIT: handleSaveAndExit,
            SAVE_AND_NEXT: handleSaveAndNext,
        },
    };
    
    
    
    
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, initialState);

    

    // Sync speakerData with state.data
    useEffect(() => {
        if (state?.data?.speakerinfo) {
            setSpeakerData({ data: { speakerinfo: state.data.speakerinfo } });
            console.log("Speaker Data Updated from State:", state.data.speakerinfo);
        } else {
            console.warn("state.data.speakerinfo is not available yet.");
        }
    }, [state.data]);
    
    
        

    async function onSpeakernameChange(props: ControlOnChangeArguments) {
        try {
            console.log("Props received:", props);
    
            if (!props.value || props.value.length === 0) {
                console.warn("No speaker ID provided.");
                return;
            }
    
            const speakerId = props.value;
    
            // Fetch the speaker information based on the speakerId
            const response = await axiosClient().get(
                `${process.env.REACT_APP_WIDGET_API_SERVER}/eventInfo/speaker-id-info/${speakerId}`
            );
    
            console.log("Speaker info received:", response.data);
    
            if (!response.data) {
                console.error("Error: API returned no data for speaker");
                return;
            }
    
            // ✅ Fetch the latest state
            const currentState = props.state;

            console.log("Current Speaker Info before update:", currentState.data.speakerinfo);

            if (!currentState || !currentState.data || !Array.isArray(currentState.data.speakerinfo)) {
                console.error("Error: state.data.speakerinfo is not an array. Initializing it.");
                currentState.data.speakerinfo = [];
            }

            let updatedSpeakerInfo = [...currentState.data.speakerinfo];

            props.dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: {
                    dataKey: `${props.parentDataKey}.designation`,
                    value: response.data.designation || "",
                },
            });

            props.dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: {
                    dataKey: `${props.parentDataKey}.addVideoThumbnail`,
                    value: response.data.profilePictureFilePath || null,
                },
            });

        } catch (error: any) {
            console.error("Error fetching speaker info:", error.response?.data || error.message);
        }


    }
    
    // const [state, dispatch] = useImmerReducer(smartReducer, {
    //     flags: { isDataLoading: true, showFormErrors: 0 },
    //     formValidationErrors: {},
    //     domain: new Map(),
    //     actions: {
    //         SAVE: handleSaveCreate,
    //         speakername: onSpeakernameChange,
    //     },
    // } as any);
    

    async function handleSaveAndExit(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();
        
        try {
            // const resp: any = await handleCreate(event, state, dispatch, globalState, globalDispatch);
            const resp: any = await handleCreate(event, state, dispatch, globalState, globalDispatch);
            
            // setTimeout(() => {
                if ( ! isEmpty(resp?.id) && resp?.success == '1' ) {
                    let navigateToPath = `${process.env.REACT_APP_APP_BASE_URL}/events/your-drafts`;
                    // navigate(navigateToPath, { replace: true });
                    window.location.href = navigateToPath;
                }
            // }, 500);
            
        } catch (error) {
            console.error("Error while save and exit:", error);
        }
    }
    const [isSubmitting, setIsSubmitting] = useState(false);

    async function handleSaveAndNext(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();
        if (isSubmitting) return;

        try {
            setIsSubmitting(true);

            // const resp: any = await handleCreate(event, state, dispatch, globalState, globalDispatch);
            const resp: any = await handleCreate(event, state, dispatch, globalState, globalDispatch);

            // setTimeout(() => {
                if ( ! isEmpty(resp?.id) && resp?.success == '1' ) {
                    navigate(`/event/audience-category/${resp?.id}`);
                }
            // }, 1000);

            // if ( ! isEmpty(resp?.id) && resp?.success == 1 ) {
            //     navigate(`/event/theme-options/${resp?.id}`);
            // }
        } catch (error) {
            console.error("Error while save & next:", error);
        } finally {
            setIsSubmitting(false);
        }
    }

    // async function handleCreate(
    //     event: React.SyntheticEvent<HTMLButtonElement>,
    //     state: State,
    //     dispatch: (dispatchEvent: DispatchEvent) => void,
    //     globalState: any,
    //     globalDispatch: (dispatchEvent: DispatchEvent) => void
    // ) {
        const handleCreate = async (
            event: React.SyntheticEvent<HTMLButtonElement>,
            state: State,
            dispatch: (dispatchEvent: DispatchEvent) => void,
            globalState: any,
            globalDispatch: (dispatchEvent: DispatchEvent) => void
        ): Promise<any> => {
        event.preventDefault();
    
        dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [] });
    	
        // Validate form state
        if (!isFormValid(state, dispatch)) {
            console.log('Form validation failed.');
            return;
        }
    
        // Ensure eventId is defined
        //const eventId = state.data?.eventId || globalState?.eventId;
        if (!eventId) {
            alert('Event ID is missing. Please try again.');
            return;
        }
        
        const loader	= Swal.fire({
            title: 'Saving...',
            html: `Please wait while we're saving your data..!`,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading(); // Show loading spinner
            },
        });

        // Prepare the API endpoint
        const updateAudienceURL = `${process.env.REACT_APP_WIDGET_API_SERVER}/eventAction/speaker-info`;
        // Define static event audience data
        const staticAudienceData = state.data?.speakerinfo
    
        // Process static audience data
        const processedSpeakerData = staticAudienceData.map((entry: any) => {
            const {
                organization,
                speakername,
                designation,
                speakerbio,
                organizationManual,
                speakernameManual,
                addVideoThumbnail,
            } = entry;
    
            return {
                organization: organization ? { id: organization, name: ''} : null,
                speakername: speakername ? { id: speakername, name: ''} : null,
                designation,
                speakerbio,
                organization_manual: organization ? null : { id: '', name: organizationManual},
                speakername_manual: speakername ? null : { id: '', name: speakernameManual},
                addVideoThumbnail,
            };
        });
    
        // console.log('Processed Static Audience Data:', processedSpeakerData);
    
        //Make the API call
        const res: any = await axiosClient()
            .post(updateAudienceURL, {
                eventId: eventId,
                eventspeaker: processedSpeakerData, // Send processed static data
            })
            // .then((res: any) => {

            // if (!res.data.id) {
            //     alert('There is some issue in event management. Please try again later.');
            //     return;
            // }

            // // Navigate to the next page on success
            // navigate(`/event/theme-options/${res.data.id}`);

            // retData.id = res.data.eventId;
            // retData.message = res.data.message;
            // retData.success = 1;

            // if (res.data.success != 1) {
            //     retData.message = retData.message ?? 'There is some issue in event management. Please try after sometime!';
            //     retData.success = 0;
            // }
            const retData = {
                id: res.data.id,
                success: res.data.success === 1 ? '1' : '0',
                message: res.data.message || 'There is some issue in event management. Please try after sometime!'
            };

            Swal.close();

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                },
            });
            Toast.fire({
                icon: `${retData.success == '1' ? 'success' : 'warning'}`,
                title: `${retData.message}`,
            });
            // })
            // .catch((error) => {
            //     console.error('Error updating audience info:', error);
            //     alert('Failed to update audience info. Please try again.');
            // });

            return retData;

    }
      
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const domainMap = new Map([...globalState.domain]); // Clone global state domain
                // Simulated data fetching for users from the same institute
                const usersListFromSameInstitute = await allUsers('');

                domainMap.set('USERS_FROM_SAME_INSTITUTE', usersListFromSameInstitute || []);
                // Dispatch the updated domain
                dispatch({
                    type: "SET_DOMAIN",
                    payload: { key: "USERS_FROM_SAME_INSTITUTE", value: usersListFromSameInstitute },
                });

                // Update domain map
                // Add user-specific domains
                const domain = addUserSpecificDomains(new Map([...domainMap]), data);
        
                dispatch({
                    type: 'DATA_INIT',
                    //type: 'SET_DOMAIN',
                    payload: {
                        formConfig,
                        data: { speakerinfo: data || { ...data } },
                        domain: domain,
                        routeInfo
                    },
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, [data, formConfig, routeInfo, dispatch]);
    

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
             
            <Layout>{state.flags.isDataLoading ? (
            <div className="page-loader d-flex justify-content-center py-5" >
                {/* You can replace this with a spinner or custom loader */}
                <div className="loaderNew"></div>
            </div>
        ) : (
            <PageBuilder />
        )}</Layout>
        </SmartContext.Provider>
    );
};

export default SpeakerInfo;
