import { useContext, useState, useEffect } from 'react';
import { Tooltip } from 'bootstrap';
import moment from 'moment';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { axiosClient } from '../../../../library/Service/axiosClient';
import VerifyMobileControl from '../../onboarding/VerifyMobileControl';
import { useNavigate } from 'react-router';
import {
    getDomainValueForCode,
    getFlexColDomainValueForCode,
    isEmpty,
    isSchoolAdmin,
    isSuperAdmin,
} from '../../../../library/Core/SmartFunctions';
// import { useLocation, useSearchParams } from 'react-router-dom';

const AdminAlumniProfileHeader = () => {
    const navigate = useNavigate();
    const { state } = useContext(SmartContext);
    const [profileCreatedBy, setprofileCreatedBy] = useState('');
    const [profileUpdatedBy, setprofileUpdatedBy] = useState('');
    const [parentName, setparentName] = useState('TBA');
    const [parentPhoneNumber, setparentPhoneNumber] = useState('TBA');
    const [showProfileMarkerAlert, setShowProfileMarkerAlert] = useState(false);
    const [profileMarkerURL, setProfileMarkerURL] = useState('');
    const [profileMarkerAlertMessage, setprofileMarkerAlertMessage] = useState('');
    const model = state.internal.model;
    const profile = state.data.profile;
    const userInstitutes = state?.data?.userInstitutes;
    // console.log('model', userInstitutes);
    const user = state.data.user;

    localStorage.removeItem('selectedInstitute');

    const linkToGetHeaderData = `${process.env.REACT_APP_COMMON_API}/users/get-alumni-header-data/${state.data.id}`;

    const getHeaderData = () => {
        axiosClient()
            .get(`${linkToGetHeaderData}`)
            .then((res: any) => {
                const responseUserData = res.data as any;
                console.log(responseUserData);
                let parent = 'TBA';
                let parentPhNo = 'TBA';
                if (responseUserData.success === true && responseUserData.data.parents.length > 0) {
                    parent = '';
                    parentPhNo = '';
                    responseUserData.data.parents.forEach((parentData: any) => {
                        parent =
                            parent +
                            parentData.first_name +
                            ', ' +
                            parentData.parent_type_code[0].toUpperCase() +
                            parentData.parent_type_code.slice(1).toLowerCase() +
                            '<br>';
                        parentPhNo =
                            parentPhNo +
                            (parentData.mobile_country_code ? '+' + parentData.mobile_country_code : '') +
                            '-' +
                            parentData.mobile_number +
                            '<br>';
                    });
                }

                if (responseUserData.success === true) {
                    setprofileCreatedBy(
                        ', ' +
                            (responseUserData.data.created_by[0].first_name === null
                                ? ''
                                : responseUserData.data.created_by[0].first_name) +
                            ' ' +
                            (responseUserData.data.created_by[0].last_name === null ? '' : responseUserData.data.created_by[0].last_name)
                    );
                    setprofileUpdatedBy(
                        responseUserData.data.updated_by[0].full_name === null ||
                            responseUserData.data.updated_by[0].full_name == '' ||
                            responseUserData.data.updated_by[0].full_name == ' '
                            ? ''
                            : ', ' + responseUserData.data.updated_by[0].full_name
                    );
                }

                setparentName(parent);
                setparentPhoneNumber(parentPhNo);
            })
            .catch((err: any) => {
                // Handle other errors
                console.error(err);
                alert('An error occurred while fetching details. Please try again later.');
            });
    };

    const setProfileMarkerAlertData = () => {
        if (!isEmpty(JSON.parse(state.data?.profile?.profileMarkerJson ?? '{}'))) {
            const demographicsMarkerColumns = ['CurrentlyAbroad', 'genderCode', 'LivedAbroadandBack', 'StudiedAbroad'];
            const WorkProfileMarkerColumns = [
                'is_leadership_role_in_large_company',
                'is_leadership_role_in_a_listed_company',
                'is_leadership_role_in_sme_company',
                'is_growing_startup_founder',
                'is_mid_senior_role_in_fortune_company',
                'work_experience',
            ];
            let urls: string[] = [];
            const markersJson = JSON.parse(state.data?.profile?.profileMarkerJson  ?? '{}');
            let messageColumnCategories: string[] = [];
            markersJson.forEach((marker: any) => {
                if (demographicsMarkerColumns.includes(marker.field) && !messageColumnCategories.includes('Demographics')) {
                    messageColumnCategories.push('Demographics');
                    if (marker.field == 'StudiedAbroad') {
                        urls.push('pg');
                    } else {
                        urls.push('info');
                    }
                }

                if (WorkProfileMarkerColumns.includes(marker.field) && !messageColumnCategories.includes('Work Profile')) {
                    messageColumnCategories.push('Work Profile');
                    urls.push('work');
                }
            });

            const message = `You have a ${messageColumnCategories.join(
                ','
            )} Marker active without substantial data in the actual profile of the alumni.`;
            setShowProfileMarkerAlert(true);
            if (urls.includes('work')) {
                setProfileMarkerURL(`/alumni/${model.id}/profile/work`);
            } else {
                if (urls.includes('pg') && urls.includes('info')) {
                    setProfileMarkerURL(`/alumni/${model.id}/info`);
                } else if (urls.includes('pg')) {
                    setProfileMarkerURL(`/alumni/${model.id}/pg`);
                } else {
                    setProfileMarkerURL(`/alumni/${model.id}/info`);
                }
            }
            setprofileMarkerAlertMessage(message);
        }
    };

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
        getHeaderData();
        setProfileMarkerAlertData();
    }, [state.data.profile]);

    // const [searchParams, setSearchParams] = useSearchParams();
    // const location = useLocation()

    const [showVerifyMobile, setShowVerifyMobile] = useState(false);
    const [isMobileVerified, setIsMobileVerified] = useState(model.isMobileVerified); // Initialize the state based on model.is_mobile_verify
    const handleVerifyMobile = (event: any) => {
        event.preventDefault();
        sendMobileInvite(model.mobileNumber);
        setShowVerifyMobile(true);
    };

    const sendMobileInvite = async (mobile: string) => {
        try {
            const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/sms/send-verification-otp`);
            if (response?.status === 200) {
                setIsMobileVerified(true);
            }
        } catch (error) {
            console.error('Error sending SMS:', error);
        }
    };

    const genderCode = state?.data?.profile?.genderCode ?? 'MALE';
    let profileImage = model?.profileImage;
    if (profileImage == '' || profileImage == null) {
        profileImage =
            genderCode == 'MALE'
                ? `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student-boy.svg`
                : `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student.svg`;
    }

    const interestedInUpSkilling = state?.data?.skillInfo?.interestedInUpskilling ?? false;
    const interestedInHigherEducation = state?.data?.educationPreferenceInfo?.doYouRequireApplicationSupportForPgAdmission ?? false;

    // const handleCopyLink = (userHash: string) => {
    //     const autoLoginLink = `${process.env.REACT_APP_APP_BASE_URL}/sso/${userHash}/${btoa('profile')}`;
    //     navigator.clipboard.writeText(autoLoginLink);
    // };
    const handleCopyLink = (institute: any) => {
        const flexCol = getFlexColDomainValueForCode(institute.instituteId, 'INSTITUTE_DOMAIN', state);
        const whitelabel_url = isEmpty(flexCol) ? '' : JSON.parse(flexCol).whitelabel_url;
        const url = !isEmpty(whitelabel_url) ? whitelabel_url : `${process.env.REACT_APP_APP_BASE_URL}/`;
        const autoLoginLink = `${url}sso/${institute.userHash}/${btoa('profile')}`;
        navigator.clipboard.writeText(autoLoginLink);
    };

    const instituteNames = userInstitutes
        .map((institute: any) => {
            return getDomainValueForCode(institute.instituteId, 'INSTITUTE_DOMAIN', state) ?? '';
        })
        .join(', ');

    return (
        <>
            {!state?.flags.isDataLoading && (
                <section
                    key={state?.routeInfo?.id}
                    className="bg-white container-fluid alumni_profile_brife_wrap"
                    id="alumni_profile_brife_wrap">
                    <div className="max-1140 mx-auto alumni_profile_brife">
                        <div className="row">
                            <div className="col-12 mb-4">
                                {/* <a className="btn-back" role="button" onClick={() => navigate('/alumni/directory')}>
                                    <i className="icon-right-arrow"></i> <span>Back to Directory</span>
                                </a> */}
                                {/* <div className="row justify-content-between">
                                    <div className="col-auto">
                                        <a className="btn-back" href="javascript:;"><i className="icon-right-arrow"></i> <span>Previous Profile</span></a>
                                    </div>
                                    <div className="col-auto">
                                        <a className="btn-back" href="javascript:;"> <span>Next Profile</span> <i className="icon-left-arrow"></i></a>
                                    </div>
                                </div> */}
                            </div>
                            {showProfileMarkerAlert && isSchoolAdmin() && (
                                <div className="col-12">
                                    <div className="custom-alret">
                                        <div className="row g-2 align-items-center">
                                            <div className="col">{profileMarkerAlertMessage}</div>
                                            <div className="col-auto">
                                                <a onClick={() => navigate(profileMarkerURL)} className="btn-green">
                                                    <span>Update Now</span>
                                                </a>
                                            </div>
                                            <div className="col-auto">
                                                <button
                                                    onClick={() => setShowProfileMarkerAlert(false)}
                                                    className="btn-close w-20 mt-2"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="col-12">
                                <div className="alumni_profile_name_college_other_detail_wrap">
                                    <div className="alumni_profile_image_wrap">
                                        <div className="alumni_profile_image">
                                            <img src={profileImage} alt="" />
                                        </div>
                                        <a
                                            className="pencil-edit"
                                            href="javascript:;"
                                            onClick={() => navigate(`/alumni/${model.id}/profile-picture`)}></a>
                                    </div>
                                    <div className="alumni_profile_name_other_detail">
                                        <h2>
                                            {`${user.firstName}`} {user.lastName ? `${user.lastName}` : ''}
                                        </h2>

                                        <div className="student_profile_class_detail mt-4">
                                            <div className="row g-3">
                                                <div className="col-md-4 col-sm-6">
                                                    <div className="d-flex flex-column me-4 line-height-24">
                                                        <span className="font-16 font-400">Institute Name</span>
                                                        <span className="font-18 font-500">{instituteNames}</span>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-6">
                                                    <div className="d-flex flex-column me-4 line-height-24">
                                                        <span className="font-16 font-400">Profile Created</span>
                                                        <span className="font-18 font-500">
                                                            {moment(profile?.createdAt).format('DD-MMM-YYYY')}
                                                            {profileCreatedBy ?? 'User'}
                                                        </span>
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-4 col-sm-6">
                                                    <div className="d-flex flex-column me-4 line-height-24">
                                                        <span className="font-16 font-400">Parent Name and Relation</span>
                                                        <span className="font-18 font-500" dangerouslySetInnerHTML={{__html: parentName}}></span>
                                                    </div>    
                                                </div> */}

                                                {/* <div className="col-md-4 col-sm-6">
                                                    <div className="d-flex flex-column me-4 line-height-24">
                                                        <span className="font-16 font-400">Parent Contact</span>
                                                        <span className="font-18 font-500" dangerouslySetInnerHTML={{__html: parentPhoneNumber}}></span>
                                                    </div>    
                                                </div> */}

                                                <div className="col-md-4 col-sm-6">
                                                    <div className="d-flex flex-column me-4 line-height-24">
                                                        <span className="font-16 font-400">Profile last updated on</span>
                                                        <span className="font-18 font-500">
                                                            {moment(profile.updatedAt).format('DD-MMM-YYYY')}
                                                            {profileUpdatedBy ?? 'User'}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-6">
                                                    <div className="d-flex flex-column me-4 line-height-24">
                                                        <span className="font-16 font-400">Auto-login Link</span>
                                                        <div className="d-flex flex-wrap">
                                                            {userInstitutes
                                                                ? userInstitutes.map((institute: any, i: any) => {
                                                                      const flexCol = getFlexColDomainValueForCode(
                                                                          institute.instituteId,
                                                                          'INSTITUTE_DOMAIN',
                                                                          state
                                                                      );
                                                                      const whitelabel_url = isEmpty(flexCol)
                                                                          ? ''
                                                                          : JSON.parse(flexCol).whitelabel_url;
                                                                      const url = !isEmpty(whitelabel_url)
                                                                          ? whitelabel_url
                                                                          : `${process.env.REACT_APP_APP_BASE_URL}/`;

                                                                      return (
                                                                          <div
                                                                              key={i}
                                                                              className="copylink mt-2 me-3 d-flex align-items-center">
                                                                              <a
                                                                                  id={`auto_login_link_${i}`}
                                                                                  rel="noreferrer"
                                                                                  href={`${url}sso/${institute.userHash}/${btoa(
                                                                                      'profile'
                                                                                  )}`}
                                                                                  target="_blank"
                                                                                  className="btn-white-tooltip"
                                                                                  data-bs-toggle="tooltip"
                                                                                  data-bs-html="true"
                                                                                  title=""
                                                                                  data-bs-original-title={
                                                                                      getDomainValueForCode(
                                                                                          institute.instituteId,
                                                                                          'INSTITUTE_DOMAIN',
                                                                                          state
                                                                                      ) ?? ''
                                                                                  }>
                                                                                  View
                                                                              </a>
                                                                              <i
                                                                                  id={`copy_auto_login_link_${i}`}
                                                                                  onClick={() => handleCopyLink(institute)}
                                                                                  className="icon copy ml-2"></i>
                                                                          </div>
                                                                      );
                                                                  })
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {showVerifyMobile && <VerifyMobileControl setShowVerifyMobile={setShowVerifyMobile} mobileNumber={model.mobileNumber} />}
        </>
    );
};

export default AdminAlumniProfileHeader;
