import { useContext, useState, useEffect, useRef } from 'react';
import { Modal } from "bootstrap";
import MessageRightBar from '../../messages-module/MessageRightBar';
import { axiosClient } from '../../../library/Service/axiosClient';
import { getStudentImage } from '../../../common/styles/Images';
import { getDomainValueForCode, getDomainValueForCategoryCode, updateMoEngageEvent, resendVerificationEmail } from '../../../library/Core/SmartFunctions';
import { SmartContext } from '../../../library/Core/SmartContext';
import SessionContext from '../../../library/Core/SessionContext';


function GetStartedPromptModal(props:any) {
  const [show, setShow] = useState(false);
  const [response, setResponse] = useState(null);
  const [conversationData, setConversationData] = useState(null);  
  const [conversationId, setConversationId] = useState(null);  
  const [isSingleConversation, setIsSingleConversation] = useState(false);
  const [message, setMessage] = useState('');
  const [newMessage, setNewMessage] = useState('');
  const [promptCode, setPromptCode] = useState(null);  
  const { state } = useContext(SmartContext);
  const { sessionState }: any = useContext(SessionContext);

  let promptData = getDomainValueForCategoryCode('PROMPT_MESSAGE', state);

  // This ref will hold the DOM element of the modal

  const modalRef = useRef(null);
  const modalInstanceRef = useRef(null); // Keep track of the modal instance

  useEffect(() => {
    if (modalRef.current) {
      modalInstanceRef.current = new Modal(modalRef.current); // Initialize once
    }

    return () => {
      if (modalInstanceRef.current) {
        modalInstanceRef.current.dispose(); // Clean up modal instance
      }
    };
  }, []);

  useEffect(() => {
    if (modalInstanceRef.current) {
      if (show) {
        modalInstanceRef.current.show();
      } else {
        modalInstanceRef.current.hide();
      }
    }
  }, [show]);

  const handleShow = () => {
    // console.log(123)
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (show) {
      const fetchData = async () => {
        try {
          const res = await axiosClient().get(
            `${process.env.REACT_APP_MESSAGE_SERVER}/conversations/get-single-user-conversation/${props.loginId}/${props.student.uuid}`
          );
          // Check if there's conversation
          const isSingleConv = res.data?.length;
          setIsSingleConversation(isSingleConv);
          setConversationData(res.data); // Store the fetched conversation data
          setConversationId(res.data[0]?.conversationId || null);
          // setConversationId(conversationData[0]?.conversationId); // Store the fetched conversation data
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }
  }, [show, props.loginId, props.student.uuid]);

  // Function to handle message input change
  const handleInputChange = (event) => {
    setMessage(event.target.value); // Update state with input value
   };

    // Function to check if conversationId is empty and start appropriate flow
  const sendMessage = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    if (!message.trim()) {
      console.error("Message cannot be empty.");
      return;
    }

    if (!conversationId) {
      await startFreshConversation();
    } else {
      await startOldConversation();
    }
    setPromptCode(null);
    // Clear the input field after submitting
    setMessage(''); // Reset the message state
  };

    // Send message to an old conversation
    const startOldConversation = async () => {
      try {
        const result = await axiosClient().post(
          `${process.env.REACT_APP_MESSAGE_SERVER}/conversations/${conversationId}/message`,
          { message,promptCode }
        );
        setShow(false); // Hide the modal or UI after sending the message
        // console.log('Message sent:', result);
      } catch (error) {
        console.error('Error sending message:', error);
      }
    };
  
    // Create a new conversation and send a message
    const startFreshConversation = async () => {
      const memberIds = [];
      memberIds.push(props.student.uuid);

      
       // Trigger MoEngage event when user is a STUDENT and target type is ALUMNI
       if (sessionState?.userTypeCode?.toUpperCase() === 'STUDENT' && props.student.userTypeCode?.toUpperCase() === 'ALUMNI') {
        const moEngagePayload = {
            msg: message,
            user_id: sessionState?.id // Student id
        };
        try {
            updateMoEngageEvent(moEngagePayload, 'moengage-student-question-alumni');
        } catch (error) {
            console.error('Failed to trigger MoEngage event:', error);
        }
       }

      try {
        const result = await axiosClient().post(
          `${process.env.REACT_APP_MESSAGE_SERVER}/conversations/`,
          {
            memberIds,
            message: message,
            userTypeCode: props.student.userTypeCode,
            promptCode : promptCode
          }
        );
        setShow(false); // Hide the modal or UI after sending the message
        // console.log('New conversation started and message sent:', result);
      } catch (error) {
        console.error('Error starting new conversation:', error);
      }
    };

    const sendDefaultMessage = async (messageTemplateType: string) => {
      setPromptCode(messageTemplateType); // Set Prompt Code
      let messageLanguage = '';
      let userName = props.name;
  
      let programName = getDomainValueForCode(props.programId, 'ALL_PROGRAM_CODE', state);

      let promptSingleData = getDomainValueForCode(messageTemplateType, 'PROMPT_MESSAGE', state)
      .replace('${userName}', userName)
      .replace('${programName}', programName);
      
      messageLanguage = promptSingleData
  
      setMessage(messageLanguage);
  };

  const handleResendEmail = async () => {
          const userString = localStorage.getItem('user');
          
          if (!userString) {
              alert('User data not found.');
              return;
          }
      
          try {
              const user = JSON.parse(userString);
              if (user?.id) {
                  if (await resendVerificationEmail(user.id,user.instituteId,user.whitelabelUrl,user.firstName+' '+user.lastName,user.instituteName,user.registeredEmailId)) {
                      alert('Email sent Successfully');
                  } else {
                      alert('Something went wrong');
                  }
              } else {
                  alert('Invalid user data.');
              }
          } catch (error) {
              console.error('Error parsing user data:', error);
              alert('Invalid user data.');
          }
      };

      if(localStorage.getItem('isEmailVerified') == 'undefined')
      {
          localStorage.removeItem('isEmailVerified');
          sessionStorage.removeItem('isEmailVerified');
      }
    
      const isEmailVerified: number = localStorage.getItem('isEmailVerified') ? Number(localStorage.getItem('isEmailVerified')) : 1;

  return (
    <>
 
       <a href="javascript:;" className={props.className} onClick={handleShow}>
        <i className="icon-alumni-send"></i>
        <span>Send</span>
      </a>

  
      <div className="modal fade" id='video_pop' ref={modalRef} tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
           
        <div className="p-0 border-radius-6 modal-body">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
          <div className="alumni_chat_block  border-radius-6">
            {isSingleConversation && conversationData ? (
        <>
                
            <div className="alumni_chat_conversation_header">
                <i className="icon-left-arrow d-block d-md-none"></i>
                <div className="alumni-user-leftpane">
                <span className="img-alumni">
                <img
                className="img-fluid"
                src={getStudentImage(props.student)}
                alt={props.student.name}
                />
               </span>
               <span className="alumni-name-batch">
               <span className="alumni-name">{props.student.name}</span>
               <span className="alumni-message">
                 {props.student.userTypeCode}
               </span>
               </span>
               </div>
            </div>

      <div className="alumni_chat_conversation_block my-3">
        <div className="d-flex flex-column h-100 align-items-end">
          <div className="mt-auto w-100">
            <div className="row g-3 h-100 align-items-end">
              <div className="col-12">
                {conversationData.map((message: any) => (
                  <div
                    key={message.id} // Add a unique key for each message
                    className={`row ${
                      message.isFromCurrentUser === 1
                        ? 'justify-content-end'
                        : 'justify-content-start'
                    }`}
                  >
                    <div className="col-11 col-sm-8">
                      <div className="d-flex flex-column">
                        <div className="blue-block">{message.message}</div>
                        <div className="col-auto">{message.sentDate}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

        </>
  ) : (
    <>
      <div className="alumni_chat_to">
        <div className="row g-2 align-items-start">
          <div className="col-sm-auto">
            <label className="mt-1">Sent to:</label>
          </div>
          <div className="col-sm">
            <div className="sent_too_wrap row g-2 align-items-center">
              <div className="col-auto">
                <div className="sent_too">
                  <span>{props.student.name}</span>
                  <i className="icon-cross ms-2 font-10"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex-1 d-flex flex-column justify-content-center align-items-center p-3 p-sm-4'>

      <div className="get_started d-flex flex-column justify-content-center align-items-center mb-2 mb-sm-4">
        <i className="get_started_like"></i>
        <h3>
          Connect and Chat:
          <br className="d-none d-sm-block" /> Engage with the Community
          Today!
        </h3>
      </div>

      {props.userTypeCode=== 'STUDENT' && !isSingleConversation ? (
        <div className="get_started_prompt">
          <div className="row g-3 justify-content-center">
          <div className="col-12"><p className="text-center font-18 font-500 mb-0">Frequently Asked Questions</p></div>
            {promptData.map((item, index) => (
            <div key={index} className="col-auto">
            <button className={Number(item.parentCode) === 1 ? "highlight" : ""}
            onClick={() => sendDefaultMessage(item.code)}>{item.flexCol}
            </button>
            </div>
            ))}
          </div>
        </div>
      ) : null}

      </div>
      
    </>
  )}

      {(isSingleConversation && conversationData) || (props.userTypeCode!== 'STUDENT') || (message) ? (
		    // props.student.userTypeCode === 'ALUMNI' 
		    <div className="alumni_chat_conversation_send">
		      {isEmailVerified == 1 && <div className="row g-2">
		        <div className="col">
		          <input
		            type="text"
		            className="form-control flex-1"
		            id="msgsend"
		            placeholder="Type your message..."
		            value={message} // Bind input to state
		            onChange={handleInputChange} // Capture input changes
		          />
		        </div>
		        <div className="col-auto">
		          <button type="button" className="btn btn-green position-relative px-3" onClick={(event) => sendMessage(event)}>
		            <i className="icon-alumni-send position-relative mx-1"></i>
		          </button>
		        </div>
		      </div>}
            {isEmailVerified != 1 && <div className="row g-2">
                <div className="col">
                Please verify your email to send message. You can resend the verification email by clicking the resend button.
                </div>
                <div className="col-auto">
                    <button type="button" className="btn btn-green position-relative px-3" onClick={handleResendEmail}>
                        Resend
                    </button>
                </div>
            </div>}
		    </div>
		  ) : null}

</div>
        </div> {/*Model body close */}
          
          </div>
        </div>
      </div>

    </>
  );
}

export default GetStartedPromptModal;
