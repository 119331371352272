import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import { handleCompleteLater, handleNext, onCurrentJobSelectionChange } from './FreeLancingWorkActions';
import FreeLancingWorkLayout from './FreeLancingWorkLayout';

const FreeLancingWork = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState('');

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            NEXT: handleNext,
            I_LL_DO_THIS_LATER: handleCompleteLater,
            ROUTER_NAVIGATE: navigate,
            isCurrent: onCurrentJobSelectionChange,
            REDIRECT: setRedirectUrl
        },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    let message = `In your work status, you mentioned that you are currently self-employed. Please provide the details of your self-employment`

    useEffect(() => {
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);

            const jobRoleCodeDomain = domain.get('FREELANCING_JOB_ROLE_CODE');
            if (jobRoleCodeDomain) {
                domain.set('JOB_ROLE_CODE', jobRoleCodeDomain);
            }
  
            dispatch({
                    type: 'DATA_INIT',
                    payload: { formConfig, data, domain, routeInfo },
                });
            }, []);

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <FreeLancingWorkLayout>{!state.flags.isDataLoading && <PageBuilder data={message}/>}</FreeLancingWorkLayout>
        </SmartContext.Provider>
    );
};

export default FreeLancingWork;
