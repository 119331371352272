import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { isEmpty } from '../../library/Core/SmartFunctions';
import { axiosClient } from '../../library/Service/axiosClient';
import { useImmerReducer } from 'use-immer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import smartReducer from '../../library/Core/SmartReducer';

function LinkedinLoginSuccess() {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const [accessCode, setAccessCode] = useState<string | null>(null); // State to store the access code
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
  
    // useEffect to extract the 'code' from the URL when the component is mounted
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      console.log(urlParams, " urlParams ");
      const code = urlParams.get('code');
      console.log(code, " CODE ");
      if (code) {
        setAccessCode(code); // Store the code in the state
        console.log('Authorization code:', code); // Optional: Log the code to the console
      } else {
        setErrorMessage('No authorization code found'); // Optional: Handle the error
      }
    }, []); 

    useEffect (() => {
        if ( isEmpty (accessCode) ) {
            return;
        }

        const params = {
            'networkType': `linkedin`,
            'accessCode': accessCode
        }
        addAccount(params);
    }, [accessCode])

    const addAccount = async (params: any) => {
        console.log('Linkedin Params: ', params)
        const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/social/add-account`, params);
        // console.log(response, " ADD ACCOUNT response")
        // setIsLoading(false);
        await Swal.fire(`${response?.data?.success == 1 ? 'Success..!' : ''}`, `${response?.data?.message}`, `${response?.data?.success == 1 ? 'success' : 'warning'}`);
        navigate(`/socialmedia/social-integration/${params.networkType}`);
    }

    useEffect(() => {
        if (isLoading) {
            const loader	= Swal.fire({
                title: 'Loading...',
                html: 'Please wait!',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading(); // Show loading spinner
                },
            });
        } else {
            Swal.close();
        }
    }, [isLoading]);

  return (
    <>
    {/* {isLoading && (
        <div className='loaderNew'></div>
    )} */}
    </>
  )
}

export default LinkedinLoginSuccess;