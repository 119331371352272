import { isEmpty, isSchoolAdmin } from '../../../library/Core/SmartFunctions';
import { State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
import PageConfigService from '../../../services/page-config.service';

class AlumniDirectoryService {
    static pageLoader(params: any) {
        const state: any = {};
        const { pageName, selectedCard, searchBy } = params;

        return Promise.all([
            PageConfigService.get(pageName),
            axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/alumni-directory/search-filter-domains`),
            AlumniDirectoryService.getSummaryCounts('MY_SCHOOL', searchBy),
            AlumniDirectoryService.getFilterDomain(),
            AlumniDirectoryService.getTopAlumni(),
        ]).then((values) => {
            state.formConfig = values[0].data;

            state.data = {
                searchLevel: 'MY_SCHOOL',
                searchBy,
                students: [],
                selectedCard: selectedCard ?? 'totalJoinedAlumni',
                summary: values[2].data,
                summaryCountWithoutFilter: values[2].data,
            };

            state.internal = {
                searchFilterDomains: isSchoolAdmin() ? values[3].data : values[1].data,
            };

            state.internal.getTopAlumni = values[4];

            return Promise.resolve(state);
        });
    }

    static async get(params: any) {
        return await axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/alumni-directory/search`, params);
    }

    static async getFilterDomain() {
        if (!isSchoolAdmin()) return Promise.resolve({ data: {} });
        return await axiosClient().get(`${process.env.REACT_APP_STUDENT_MANAGEMENT_SERVER}/directory/institute-filters`);
    }

    // @Cr By : Irfan @Dt :05 June 2024
    static async getTopAlumni() {
        const user = JSON.parse(localStorage.getItem('user') as string);
        const studentId = user.studentId;
        const instituteGroupId=user?.instituteGroupId //By @VP on 26-11-2024 #1176
        // return await axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/alumni-directory/get-top-alumni/${studentId}`);
        const response = await axiosClient().get(`${process.env.REACT_APP_REPORTS_SERVER}/common/alumni-top-match/${studentId}/${instituteGroupId}`);
         return response.data.fetchedAlumni;
    }

    static async performSearchWithUserSelectedCriteria(searchCriteria: any, state: State, dispatch: any, newState: any = {}) {
        const { searchLevel, searchBy } = state.data;
        const defaultCriteria = AlumniDirectoryService.getDefaultSearchCriteria(searchBy);
        const levelFromSearchCriteria = searchCriteria?.context?.level;

        const criteria = {
            ...defaultCriteria,
            ...searchCriteria,
            context: { ...defaultCriteria.context, ...searchCriteria?.context, level: levelFromSearchCriteria ?? searchLevel },
        };

        const requestURL = `${process.env.REACT_APP_REPORTS_SERVER}/alumni-directory/search`;
        const response = await axiosClient().post(requestURL, criteria);

        const summaryResponse = await this.getSummaryCountsBasedOnCampusFilter('MY_SCHOOL', searchCriteria, dispatch);
        // console.log('summaryResponse');
        // console.log(summaryResponse);
        dispatch({
            type: 'REFRESH_DATA',
            payload: {
                data: {
                    ...state.data,
                    selectedCard: searchCriteria?.context?.selectedCard ?? state.data.selectedCard,
                    students: response.data.students,
                    filters: response.data.filters,
                    ...newState,
                    summary: summaryResponse?.data || state.data.summaryCountWithoutFilter,
                },
            },
        });
    }

    static async getSummaryCountsBasedOnCampusFilter(level: string = 'MY_SCHOOL', searchFilters: any, dispatch: any) {
        console.log(searchFilters?.instituteId);
        const user = JSON.parse(localStorage.getItem('user') as string);
        const { instituteId: schoolId, instituteGroupId: schoolGroupId, passOutYear } = user;

        const skillToTarget = AlumniDirectoryService.getSkillToTarget();
        const schoolCondition = level === 'MY_SCHOOL' ? { schoolId: [schoolId] } : { schoolGroupId: [schoolGroupId], schoolId: [] };
        const searchCriteria = { skillToTarget, passOutYear, level, ...schoolCondition, requestedFrom: 'directory' };

        if (!isEmpty(searchFilters?.instituteId)) {
            searchCriteria['schoolId'] = searchFilters?.instituteId;
            console.log('hiiiiiiiiiii');
            const summaryCount = await axiosClient().post(
                `${process.env.REACT_APP_REPORTS_SERVER}/alumni-directory/student-summary-counts`,
                searchCriteria
            );

            return summaryCount;
        }
    }

    static getCriteriaForSelectedCard = (selectedCard: string, state: State) => {
        // const user = JSON.parse(sessionStorage.getItem('user') as string);
        const user = JSON.parse(localStorage.getItem('user') as string);
        const { instituteId: schoolId, instituteGroupId: schoolGroupId, passOutYear } = user;
        // const schoolId = sessionStorage.getItem('user-school-id');
        const directoryLevel = state.data.searchLevel;

        const schoolCondition = directoryLevel === 'MY_SCHOOL' ? { schoolId: [schoolId] } : { schoolGroupId: [schoolGroupId] };

        const criteriaLookup: any = {
            totalJoinedAlumni: { ...schoolCondition, ugCollegeExists: true },
            activeAlumni: { ...schoolCondition, userStatusCode: 'ACTIVE' },
            pursuingHigherStudies: { ...schoolCondition, isPursuingHigherStudies: 1 },
            fromPrestigiousCollege: { ...schoolCondition, isPrestigiousCollege: 1 },
            studyingAbroad: { ...schoolCondition, isStudyingAbroad: 1 },
            workingAlumni: { ...schoolCondition, isWorking: 1 },
            fromYourBatch: { ...schoolCondition, passOutYear: [passOutYear] },
            sameNextLevelInterests: {
                ...schoolCondition,
                skillToTarget: AlumniDirectoryService.getSkillToTarget(),
                userStatusCode: 'ACTIVE',
            },
        };

        return criteriaLookup[selectedCard];
    };

    static async getSummaryCounts(level: string = 'MY_SCHOOL', searchBy: string = '') {
        // const user = JSON.parse(sessionStorage.getItem('user') as string);
        const user = JSON.parse(localStorage.getItem('user') as string);
        const { instituteId: schoolId, instituteGroupId: schoolGroupId, passOutYear } = user;
        // const schoolId = sessionStorage.getItem('user-school-id');
        // const schoolGroupId = sessionStorage.getItem('user-school-group-id');
        // const passOutYear: any = sessionStorage.getItem('pass-out-year');

        const skillToTarget = AlumniDirectoryService.getSkillToTarget();
        const schoolCondition = level === 'MY_SCHOOL' ? { schoolId: [schoolId] } : { schoolGroupId: [schoolGroupId], schoolId: [] };
        const searchCriteria = { skillToTarget, passOutYear, level, ...schoolCondition };

        const summaryCount = await axiosClient().post(
            `${process.env.REACT_APP_REPORTS_SERVER}/alumni-directory/student-summary-counts`,
            searchCriteria
        );

        return summaryCount;
    }

    static getDefaultSearchCriteria(searchBy: string = '') {
        // const passOutYear = parseInt(sessionStorage.getItem('pass-out-year') as string);
        // const user = JSON.parse(sessionStorage.getItem('user') as string);
        const user = JSON.parse(localStorage.getItem('user') as string);
        const { passOutYear, instituteId } = user;

        return {
            context: { searchBy, passOutYear, level: 'MY_SCHOOL' },
            passOutYear: [],
            educationOrEmploymentCurrentStatusCode: [],
            fieldOfStudyCode: [],
            universityId: [],
            pgUniversityId: [],
            curriculumCode: [],
            residingCountryCode: [],
            residingCityCode: [],
            collegeCountryCode: [],
            collegeCityCode: [],
            instituteId: [],
            entranceTestCode: [],
            prestigiousCollege: null,
            skills: [],
            interests: [],
            programId: [],
            jobCompanyId: [],
            functionalAreaCode: [],
            industryCode: [],
            workingStatusCode: [],
            // userStatusCode: 'ACTIVE',
            schoolId: [instituteId],
        };
    }

    static async getCustomDomains() {
        // const user = JSON.parse(sessionStorage.getItem('user') as string);
        const user = JSON.parse(localStorage.getItem('user') as string);
        const { instituteId: schoolId, instituteGroupId: schoolGroupId } = user;
        // const schoolId = sessionStorage.getItem('user-school-id');
        // const schoolGroupId = sessionStorage.getItem('user-school-group-id');

        return await axiosClient().get(
            `${process.env.REACT_APP_REPORTS_SERVER}/alumni-directory/search-filter-domains?schoolId=${schoolId}&schoolGroupId=${schoolGroupId}`
        );
    }

    static getSearchFilterDomains(domain: any, customDomains: any) {
        // const user = JSON.parse(sessionStorage.getItem('user') as string);
        const user = JSON.parse(localStorage.getItem('user') as string);
        const { passOutYear } = user;

        const getDomainForCodes = (domain: any, categoryCode: string, codes: any) => {
            if (isEmpty(codes) || !Array.isArray(codes)) return [];
            const stringCodes = codes?.map((code: any) => code?.toString());
            let targetDomain = domain?.get(categoryCode)?.filter((domainValue: any) => stringCodes.includes(domainValue.code));
            if (categoryCode === 'PAST_YEAR_CODE') {
                targetDomain = targetDomain.map((domain: any) =>
                    domain.code == passOutYear ? { ...domain, value: `${domain.value} (your batch)` } : domain
                );
                return targetDomain?.sort((a: any, b: any) => b.value.toLowerCase().localeCompare(a.value.toLowerCase()));
            } else if (categoryCode === 'COUNTRY_CODE') {
                return targetDomain;
            } else {
                return targetDomain?.sort((a: any, b: any) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
            }
        };

        const searchFilterDomains = {};

        customDomains &&
            Object.keys(customDomains).forEach((key: any) => {
                Object.assign(searchFilterDomains, {
                    [key]: getDomainForCodes(domain, AlumniDirectoryService.filterConfig[key]?.domain, customDomains[key]),
                });
            });

        return searchFilterDomains;
    }

    static getDomainForCodes(domain: any, categoryCode: string, codes: any) {
        return domain.get(categoryCode)?.values.filter((domainValue: any) => codes.includes(domainValue.code));
    }

    static getSummaryCardControlImage = (controlName: string) => {
        const controlImageMap: any = {
            totalJoinedAlumni: '',
            pursuingHigherStudies: '',
            workingAlumni: '',
            fromYourBatch: '',
            sameNextLevelInterests: '',
        };
    };

    static getSkills = () => JSON.parse(localStorage.getItem('user') as string)?.skills?.split(',');
    static getSkillToTarget = () => JSON.parse(localStorage.getItem('user') as string)?.skillToTarget?.split(',');

    static filterConfig: any = {
        instituteId: {
            id: 'instituteId',
            label: 'Campus',
            domain: 'INSTITUTE_DOMAIN',
            applicableFor: 'BOTH',
            class: 'fiterItem w-20 mb-3',
            isAdditional: false,
            isVisible: true,
        },
        programId: {
            id: 'programId',
            label: 'Program Name',
            domain: 'ALL_PROGRAM_CODE',
            applicableFor: 'BOTH',
            class: 'fiterItem w-20 mb-3',
            isAdditional: false,
            isVisible: true,
        },
        passOutYear: {
            id: 'passOutYear',
            label: 'Batch',
            domain: 'PAST_YEAR_CODE',
            directoryLevel: 'BOTH',
            applicableFor: 'BOTH',
            class: 'fiterItem w-20 mb-3',
            isAdditional: false,
            isVisible: true,
        },
        residingCountryCode: {
            id: 'residingCountryCode',
            label: 'Current Country',
            domain: 'COUNTRY_CODE',
            applicableFor: 'BOTH',
            class: 'fiterItem w-20 mb-3',
            isAdditional: false,
            isVisible: true,
        },
        workingStatusCode: {
            id: 'workingStatusCode',
            label: 'Working Status',
            domain: 'WORK_STATUS_CODE',
            applicableFor: 'BOTH',
            class: 'fiterItem w-20 mb-3',
            isAdditional: false,
            isVisible: true,
        },
        collegeCountryCode: {
            id: 'collegeCountryCode',
            label: 'College Country',
            domain: 'COUNTRY_CODE',
            applicableFor: 'BOTH',
            class: 'fiterItem w-20 mb-3',
            isAdditional: false,
            isVisible: true,
        },
        universityId: {
            id: 'universityId',
            label: 'Higher Studies at',
            domain: 'UNIVERSITY_CODE',
            directoryLevel: 'BOTH',
            applicableFor: 'BOTH',
            class: 'fiterItem w-40 mb-3',
            isAdditional: false,
            isVisible: true,
        },
        pgUniversityId: {
            id: 'pgUniversityId',
            label: 'Higher Studies at',
            domain: 'UNIVERSITY_CODE',
            directoryLevel: 'BOTH',
            applicableFor: 'BOTH',
            class: 'fiterItem w-40 mb-3',
            isAdditional: false,
            isVisible: false,
        },
        fieldOfStudyCode: {
            id: 'fieldOfStudyCode',
            label: 'UG Course',
            domain: 'FIELD_OF_STUDY_TYPE_CODE',
            applicableFor: 'BOTH',
            class: 'fiterItem w-20 mb-3',
            isAdditional: false,
            isVisible: false,
        },
        jobCompanyId: {
            id: 'jobCompanyId',
            label: 'Company Name',
            domain: 'COMPANY_CODE',
            applicableFor: 'BOTH',
            class: 'fiterItem w-40 mb-3',
            isAdditional: false,
            isVisible: true,
        },
        functionalAreaCode: {
            id: 'functionalAreaCode',
            label: 'Functional Area',
            domain: 'FUNCTIONAL_AREA_CODE',
            applicableFor: 'BOTH',
            class: 'fiterItem w-20 mb-3',
            isAdditional: true,
            isVisible: true,
        },
        industryCode: {
            id: 'industryCode',
            label: 'Working Industry',
            domain: 'INDUSTRY_CODE',
            applicableFor: 'BOTH',
            class: 'fiterItem w-20 mb-3',
            isAdditional: true,
            isVisible: true,
        },
        entranceTestCode: {
            id: 'entranceTestCode',
            label: 'Exam Appeared',
            domain: 'ENTRANCE_TEST_CODE',
            applicableFor: 'BOTH',
            class: 'fiterItem w-20 mb-3',
            isAdditional: true,
            isVisible: true,
        },
    };
}

export default AlumniDirectoryService;
