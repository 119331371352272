// import imgcloudcomputing from '../../../styles/images/cloud-computing.svg';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { OptionsOrGroups, GroupBase } from 'react-select';
import { components } from 'react-select';
import { getStudentImage } from '../../../common/styles/Images';
import { axiosClient } from '../../../library/Service/axiosClient';
import { isEmpty } from '../../../library/Core/SmartFunctions';
import { useCallback } from 'react';
import axios from 'axios';
const imgcloudcomputing = `${process.env.REACT_APP_IMAGE_BASEPATH}/cloud-computing.svg`;

interface CustomOption {
    value: string;
    label: string;
    name: string;
    firstName: string;
    lastName: string;
    profilePictureFilePath?: string;
    schoolName?: string;
    passOutYear?: string;
    programName?: string;
    nation: string;
    achievement: string;
    selectugCollege: number;
    ugCollege: string;
    ugDepartment: string;
    selectpgCollege: number;
    pgCollege: string;
    pgDepartment: string;
    selectjobCompany: number;
    jobCompany: string;
    jobDesignation: string;
    registeredEmailId: string;
    selectLocation: number;
    selectAchievement: number;
}

// Define the type for the achievement items
const FutureLeaders = () => {
    const { id, code, eid } = useParams();
    const [schoolImage, setSchoolImage] = useState<string | null>(null);
    const [selectedUser, setSelectedUser] = useState<CustomOption | null>(null);
    const [primaryColor, setPrimaryColor] = useState('#0064A7'); // Default to red
    const [secondaryColor, setSecondaryColor] = useState('#0064A7'); // Default to whit
    const [selectedDataSet, setSelectedDataSet] = useState<string>('JoinedUGCollege'); // State to track the selected dataset
    const [isLoading, setIsLoading] = useState(false);
    const [expertise, setExpertise] = useState<string>('');
    const [uploadedImage, setUploadedImage] = useState<string | null>(null);
    const divRef = useRef<HTMLDivElement>(null);
     const jwtToken = localStorage.getItem('user-jwt-token');
    const previewRef = useRef<Window | null>(null); // State to hold the uploaded image URL
    const [editData, setEditData] = useState<any>({}); // State to hold the edit data if in edit mode
    const [errors, setErrors] = useState({
        user: '',
        username: '',
        schoolname: '',
        programname: '',
        passout: '',
        ugcollege: '',
        ugcourse: '',
        companyname: '',
        currentdesignation: '',
        pgcollege: '',
        pgcourse: '',
        currentlocation: '',
        achievement: '',
        dataSet: '',
        image: '',
    });

    useEffect(() => {
        const fetchSchoolLogo = async () => {
            try {
                const user = sessionStorage.getItem('user');
                const primaryInstitute = user ? JSON.parse(user).primaryInstitute : 0;

                const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/pre-signup/get-institute-name`, {
                    id: primaryInstitute,
                });

                if (response.data?.success && response.data.result?.[0]?.logo) {
                    const logoUrl = response.data.result[0].logo;
                    setSchoolImage(logoUrl);
                } else {
                    console.warn('No school logo returned from the API. Using fallback image.');
                }
            } catch (error) {
                console.error('Error fetching school logo:', error);
            }
        };

        fetchSchoolLogo();
    }, []);

    // State to track the selected option
    const [selectedOption, setSelectedOption] = useState<string>('');

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedDataSet(event.target.value);
    };
    let editdata: any = {};

    useEffect(() => {
        setSelectedOption('yes');
    }, []);

    const isEditing = !!eid;

    const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/image`;
        const formData = new FormData();

        // Make sure there's at least one file
        if (event.target.files && event.target.files.length > 0) {
            formData.append('image', event.target.files[0]); // Append the first file

            try {
                const response = await axiosClient().post(apiUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // Assuming the response returns the new image URL
                const newImageUrl = response.data.link;

                // Update the uploaded image state and the selected user's profile picture
                setUploadedImage(newImageUrl);
                setSelectedUser((prevUser) => (prevUser ? { ...prevUser, profilePictureFilePath: newImageUrl } : prevUser));
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        } else {
            console.log('No file selected');
        }
    };

    // Ensure the function returns a promise with the correct type
    const loadOptions = async (
        inputValue: string,
        callback: (options: OptionsOrGroups<CustomOption, GroupBase<CustomOption>>) => void
    ): Promise<OptionsOrGroups<CustomOption, GroupBase<CustomOption>>> => {
        setIsLoading(true);
        try {
            if (isEmpty(inputValue) || inputValue.length < 3) {
                setIsLoading(false);
                callback([]);
                return [];
            }
            const res = await axiosClient().get(
                `${process.env.REACT_APP_WIDGET_API_SERVER}/alumniInfo/get-alumni-guiding?term=${inputValue}`
            );
            const users = res.data || [];

            console.log(users, 'users');

            const formattedOptions: OptionsOrGroups<CustomOption, GroupBase<CustomOption>> = users.map((user: any) => ({
                value: user.uuid, // Ensure you are correctly using the UUID here
                label: `${user.firstName} ${user.lastName}`,
                name: `${user.firstName} ${user.lastName}`,
                firstName: user.firstName,
                lastName: user.lastName,
                profilePictureFilePath: user.profilePictureFilePath || '', // Handle undefined profilePictureFilePath
                schoolName: user.schoolName || '', // Handle undefined schoolName
                passOutYear: user.passOutYear || null, // Handle undefined passOutYear
                programName: user.programName || '', // Handle undefined program name
                nation: user.nation || '', // Handle undefined nation
                achievement: '', // Handle undefined nation
                ugCollege: user.ugCollege || '', // Handle undefined ugCollege
                ugDepartment: user.ugDepartment || '', // Handle undefined UG department
                pgCollege: user.pgCollege || '', // Handle undefined PG college
                pgDepartment: user.pgDepartment || '', // Handle undefined PG department
                jobCompany: user.jobCompany || '', // Handle undefined jobCompany
                jobDesignation: user.jobDesignation || '', // Handle undefined jobDesignation
                registeredEmailId: user.registeredEmailId || '', // Handle undefined registeredEmailId
                selectugCollege: user.selectugCollege || '0', // Assuming you get this from the user object
                selectpgCollege: user.selectpgCollege || '0', // Assuming you get this from the user object
                selectjobCompany: user.selectjobCompany || '0', // Assuming you get this from the user object
            }));

            setIsLoading(false);
            callback(formattedOptions);
            return formattedOptions;
        } catch (error) {
            console.error('Error loading user names:', error);
            setIsLoading(false);
            const emptyOptions: OptionsOrGroups<CustomOption, GroupBase<CustomOption>> = [];
            callback(emptyOptions);
            return emptyOptions;
        }
    };

    const handleOnChange = useCallback(async (user: CustomOption | null) => {
        console.log('User selected:', user); // Debugging user data

        if (!user) {
            console.log('No user selected. Resetting states.');
            setSelectedUser(null);
            setUploadedImage(null);
            return;
        }

        let imageBase64: string | null = null;

        if (user.profilePictureFilePath) {
            console.log('Fetching base64 for:', user.profilePictureFilePath);

            try {
                const apiUrl = `${process.env.REACT_APP_BROADCAST_SERVER}/pre-signup/get-base64-path`;
                console.log('API URL:', apiUrl); // Debugging API URL

                const response = await axiosClient().post<{ success: boolean; result: string }>(apiUrl, {
                    image: user.profilePictureFilePath,
                });

                console.log('API Response:', response.data); // Debugging API Response

                if (response.data?.success && response.data.result) {
                    imageBase64 = response.data.result; // Use `result` instead of `base64path`
                }
            } catch (error) {
                console.error('Error fetching base64 image:', error);
            }
        } else {
            console.warn('No profilePictureFilePath found, skipping API call.');
        }

        setSelectedUser((prevUser) => ({
            ...user,
            profilePictureFilePath: imageBase64 || user.profilePictureFilePath || '',
        }));

        setUploadedImage(imageBase64 || user.profilePictureFilePath || null);
    }, []);
    useEffect(() => {
        const userJwtToken = sessionStorage.getItem('user-jwt-token');

        if (!userJwtToken) {
            window.location.href = '/404'; // Redirect to 404 page
        }
    }, []);

    const CustomOption = (props: any) => {
        const { data } = props;

        console.log('Data:', data.profilePictureFilePath);
        return (
            <components.Option {...props}>
                <p
                    key={data.value}
                    className="d-flex flex-wrap align-items-center font-16 line-height-18 font-500 text-dark-blue mb-0 pb-3 pb-sm-2">
                    <span className="me-3">
                        <img
                            src={getStudentImage({ profilePictureFilePath: data.profilePictureFilePath, genderCode: '' })}
                            alt=""
                            className="rounded-circle"
                            style={{ height: '50px', borderRadius: '50%', width: '50px' }}
                        />
                    </span>
                    <span className="flex-1">
                        {`${data.firstName} ${data.lastName}`},{' '}
                        <span className="fst-italic">
                            {data.schoolName}
                            {data.passOutYear ? `, ${data.passOutYear}` : ''}
                        </span>
                    </span>
                </p>
            </components.Option>
        );
    };

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);
    };

    const handleAlumniNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newAlumniName = event.target.value;

        if (selectedOption === 'no') {
            setSelectedUser((prevUser) => ({
                ...prevUser,
                firstName: newAlumniName.split(' ')[0] || '',
                lastName: newAlumniName.split(' ').slice(1).join(' ') || '',
                label: newAlumniName,
                name: newAlumniName,
                value: prevUser?.value || '',
                nation: prevUser?.nation || '',
                profilePictureFilePath: prevUser?.profilePictureFilePath || '',
                schoolPictureFilePath: '',
                schoolName: prevUser?.schoolName || '',
                passOutYear: prevUser?.passOutYear || '', // Handle undefined passOutYear
                programName: prevUser?.programName || '', // Handle undefined program name
                achievement: prevUser?.achievement || '',
                selectugCollege: prevUser?.selectugCollege ?? 0,
                ugCollege: prevUser?.ugCollege || '',
                ugDepartment: prevUser?.ugDepartment || '',
                selectpgCollege: prevUser?.selectpgCollege ?? 0,
                pgCollege: prevUser?.pgCollege || '',
                pgDepartment: prevUser?.pgDepartment || '',
                selectjobCompany: prevUser?.selectjobCompany ?? 0,
                jobCompany: prevUser?.jobCompany || '',
                jobDesignation: prevUser?.jobDesignation || '',
                selectLocation: prevUser?.selectLocation ?? 0,
                selectAchievement: prevUser?.selectAchievement ?? 0,
                registeredEmailId: prevUser?.registeredEmailId || '', // Default to an empty string
            }));
        }
    };

    const handleProgramChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newProgram = event.target.value;

        if (selectedOption === 'no') {
            setSelectedUser((prevUser) => ({
                ...prevUser,
                firstName: prevUser?.firstName || '',
                lastName: prevUser?.lastName || '',
                label: prevUser?.label || '',
                name: prevUser?.name || '',
                value: prevUser?.value || '',
                nation: prevUser?.nation || '',
                profilePictureFilePath: prevUser?.profilePictureFilePath || '',
                schoolPictureFilePath: '',
                schoolName: prevUser?.schoolName || '',
                passOutYear: prevUser?.passOutYear || '', // Handle undefined passOutYear
                programName: newProgram || '', // Handle undefined program name
                achievement: prevUser?.achievement || '',
                selectugCollege: prevUser?.selectugCollege ?? 0,
                ugCollege: prevUser?.ugCollege || '',
                ugDepartment: prevUser?.ugDepartment || '',
                selectpgCollege: prevUser?.selectpgCollege ?? 0,
                pgCollege: prevUser?.pgCollege || '',
                pgDepartment: prevUser?.pgDepartment || '',
                selectjobCompany: prevUser?.selectjobCompany ?? 0,
                jobCompany: prevUser?.jobCompany || '',
                jobDesignation: prevUser?.jobDesignation || '',
                selectLocation: prevUser?.selectLocation ?? 0,
                selectAchievement: prevUser?.selectAchievement ?? 0,
                registeredEmailId: prevUser?.registeredEmailId || '', // Default to an empty string
            }));
        }
    };

    const handleBatchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newBetch = event.target.value;

        if (selectedOption === 'no') {
            setSelectedUser((prevUser) => ({
                ...prevUser,
                firstName: prevUser?.firstName || '',
                lastName: prevUser?.lastName || '',
                label: prevUser?.label || '',
                name: prevUser?.name || '',
                value: prevUser?.value || '',
                nation: prevUser?.nation || '',
                profilePictureFilePath: prevUser?.profilePictureFilePath || '',
                schoolPictureFilePath: '',
                schoolName: prevUser?.schoolName || '',
                passOutYear: newBetch || '', // Handle undefined passOutYear
                programName: prevUser?.programName || '', // Handle undefined program name
                achievement: prevUser?.achievement || '',
                selectugCollege: prevUser?.selectugCollege ?? 0,
                ugCollege: prevUser?.ugCollege || '',
                ugDepartment: prevUser?.ugDepartment || '',
                selectpgCollege: prevUser?.selectpgCollege ?? 0,
                pgCollege: prevUser?.pgCollege || '',
                pgDepartment: prevUser?.pgDepartment || '',
                selectjobCompany: prevUser?.selectjobCompany ?? 0,
                jobCompany: prevUser?.jobCompany || '',
                jobDesignation: prevUser?.jobDesignation || '',
                selectLocation: prevUser?.selectLocation ?? 0,
                selectAchievement: prevUser?.selectAchievement ?? 0,
                registeredEmailId: prevUser?.registeredEmailId || '', // Default to an empty string
            }));
        }
    };

    const handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newCompanyName = event.target.value;

        if (selectedOption === 'no') {
            setSelectedUser((prevUser) => ({
                ...prevUser,
                firstName: prevUser?.firstName || '',
                lastName: prevUser?.lastName || '',
                label: prevUser?.label || '',
                name: prevUser?.name || '',
                value: prevUser?.value || '',
                nation: prevUser?.nation || '',
                profilePictureFilePath: prevUser?.profilePictureFilePath || '',
                schoolPictureFilePath: '',
                schoolName: prevUser?.schoolName || '',
                passOutYear: prevUser?.passOutYear || '', // Handle undefined passOutYear
                programName: prevUser?.programName || '', // Handle undefined program name
                achievement: prevUser?.achievement || '',
                selectugCollege: prevUser?.selectugCollege ?? 0,
                ugCollege: prevUser?.ugCollege || '',
                ugDepartment: prevUser?.ugDepartment || '',
                selectpgCollege: prevUser?.selectpgCollege ?? 0,
                pgCollege: prevUser?.pgCollege || '',
                pgDepartment: prevUser?.pgDepartment || '',
                selectjobCompany: prevUser?.selectjobCompany ?? 0,
                jobCompany: newCompanyName || '',
                jobDesignation: prevUser?.jobDesignation || '',
                selectLocation: prevUser?.selectLocation ?? 0,
                selectAchievement: prevUser?.selectAchievement ?? 0,
                registeredEmailId: prevUser?.registeredEmailId || '', // Default to an empty string
            }));
        }
    };

    const handlecurrentDesignationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDesignation = event.target.value;

        if (selectedOption === 'no') {
            setSelectedUser((prevUser) => ({
                ...prevUser,
                firstName: prevUser?.firstName || '',
                lastName: prevUser?.lastName || '',
                label: prevUser?.label || '',
                name: prevUser?.name || '',
                value: prevUser?.value || '',
                nation: prevUser?.nation || '',
                profilePictureFilePath: prevUser?.profilePictureFilePath || '',
                schoolPictureFilePath: '',
                schoolName: prevUser?.schoolName || '',
                passOutYear: prevUser?.passOutYear || '', // Handle undefined passOutYear
                programName: prevUser?.programName || '', // Handle undefined program name
                achievement: prevUser?.achievement || '',
                selectugCollege: prevUser?.selectugCollege ?? 0,
                ugCollege: prevUser?.ugCollege || '',
                ugDepartment: prevUser?.ugDepartment || '',
                selectpgCollege: prevUser?.selectpgCollege ?? 0,
                pgCollege: prevUser?.pgCollege || '',
                pgDepartment: prevUser?.pgDepartment || '',
                selectjobCompany: prevUser?.selectjobCompany ?? 0,
                jobCompany: prevUser?.jobCompany || '',
                jobDesignation: newDesignation || '',
                selectLocation: prevUser?.selectLocation ?? 0,
                selectAchievement: prevUser?.selectAchievement ?? 0,
                registeredEmailId: prevUser?.registeredEmailId || '', // Default to an empty string
            }));
        }
    };

    //UG start
    const handleUGcollegeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newUGcollege = event.target.value;

        if (selectedOption === 'no') {
            setSelectedUser((prevUser) => ({
                ...prevUser,
                firstName: prevUser?.firstName || '',
                lastName: prevUser?.lastName || '',
                label: prevUser?.label || '',
                name: prevUser?.name || '',
                value: prevUser?.value || '',
                nation: prevUser?.nation || '',
                profilePictureFilePath: prevUser?.profilePictureFilePath || '',
                schoolPictureFilePath: '',
                schoolName: prevUser?.schoolName || '',
                passOutYear: prevUser?.passOutYear || '', // Handle undefined passOutYear
                programName: prevUser?.programName || '', // Handle undefined program name
                achievement: prevUser?.achievement || '',
                selectugCollege: prevUser?.selectugCollege ?? 0,
                ugCollege: newUGcollege || '',
                ugDepartment: prevUser?.ugDepartment || '',
                selectpgCollege: prevUser?.selectpgCollege ?? 0,
                pgCollege: prevUser?.pgCollege || '',
                pgDepartment: prevUser?.pgDepartment || '',
                selectjobCompany: prevUser?.selectjobCompany ?? 0,
                jobCompany: prevUser?.jobCompany || '',
                jobDesignation: prevUser?.jobDesignation || '',
                selectLocation: prevUser?.selectLocation ?? 0,
                selectAchievement: prevUser?.selectAchievement ?? 0,
                registeredEmailId: prevUser?.registeredEmailId || '', // Default to an empty string
            }));
        }
    };

    const handleUGDesignationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newUGDesignation = event.target.value;

        if (selectedOption === 'no') {
            setSelectedUser((prevUser) => ({
                ...prevUser,
                firstName: prevUser?.firstName || '',
                lastName: prevUser?.lastName || '',
                label: prevUser?.label || '',
                name: prevUser?.name || '',
                value: prevUser?.value || '',
                nation: prevUser?.nation || '',
                profilePictureFilePath: prevUser?.profilePictureFilePath || '',
                schoolPictureFilePath: '',
                schoolName: prevUser?.schoolName || '',
                passOutYear: prevUser?.passOutYear || '', // Handle undefined passOutYear
                programName: prevUser?.programName || '', // Handle undefined program name
                achievement: prevUser?.achievement || '',
                selectugCollege: prevUser?.selectugCollege ?? 0,
                ugCollege: prevUser?.ugCollege || '',
                ugDepartment: newUGDesignation || '',
                selectpgCollege: prevUser?.selectpgCollege ?? 0,
                pgCollege: prevUser?.pgCollege || '',
                pgDepartment: prevUser?.pgDepartment || '',
                selectjobCompany: prevUser?.selectjobCompany ?? 0,
                jobCompany: prevUser?.jobCompany || '',
                jobDesignation: prevUser?.jobDesignation || '',
                selectLocation: prevUser?.selectLocation ?? 0,
                selectAchievement: prevUser?.selectAchievement ?? 0,
                registeredEmailId: prevUser?.registeredEmailId || '', // Default to an empty string
            }));
        }
    };

    //UG end

    //PG start
    const handlePGcollegeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPGCollege = event.target.value;

        if (selectedOption === 'no') {
            setSelectedUser((prevUser) => ({
                ...prevUser,
                firstName: prevUser?.firstName || '',
                lastName: prevUser?.lastName || '',
                label: prevUser?.label || '',
                name: prevUser?.name || '',
                value: prevUser?.value || '',
                nation: prevUser?.nation || '',
                profilePictureFilePath: prevUser?.profilePictureFilePath || '',
                schoolPictureFilePath: '',
                schoolName: prevUser?.schoolName || '',
                passOutYear: prevUser?.passOutYear || '', // Handle undefined passOutYear
                programName: prevUser?.programName || '', // Handle undefined program name
                achievement: prevUser?.achievement || '',
                selectugCollege: prevUser?.selectugCollege ?? 0,
                ugCollege: prevUser?.ugCollege || '',
                ugDepartment: prevUser?.ugDepartment || '',
                selectpgCollege: prevUser?.selectpgCollege ?? 0,
                pgCollege: newPGCollege || '',
                pgDepartment: prevUser?.pgDepartment || '',
                selectjobCompany: prevUser?.selectjobCompany ?? 0,
                jobCompany: prevUser?.jobCompany || '',
                jobDesignation: prevUser?.jobDesignation || '',
                selectLocation: prevUser?.selectLocation ?? 0,
                selectAchievement: prevUser?.selectAchievement ?? 0,
                registeredEmailId: prevUser?.registeredEmailId || '', // Default to an empty string
            }));
        }
    };

    const handlePGDesignationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPGDesignation = event.target.value;

        if (selectedOption === 'no') {
            setSelectedUser((prevUser) => ({
                ...prevUser,
                firstName: prevUser?.firstName || '',
                lastName: prevUser?.lastName || '',
                label: prevUser?.label || '',
                name: prevUser?.name || '',
                value: prevUser?.value || '',
                nation: prevUser?.nation || '',
                profilePictureFilePath: prevUser?.profilePictureFilePath || '',
                schoolPictureFilePath: '',
                schoolName: prevUser?.schoolName || '',
                passOutYear: prevUser?.passOutYear || '', // Handle undefined passOutYear
                programName: prevUser?.programName || '', // Handle undefined program name
                achievement: prevUser?.achievement || '',
                selectugCollege: prevUser?.selectugCollege ?? 0,
                ugCollege: prevUser?.ugCollege || '',
                ugDepartment: prevUser?.ugDepartment || '',
                selectpgCollege: prevUser?.selectpgCollege ?? 0,
                pgCollege: prevUser?.pgCollege || '',
                pgDepartment: newPGDesignation || '',
                selectjobCompany: prevUser?.selectjobCompany ?? 0,
                jobCompany: prevUser?.jobCompany || '',
                jobDesignation: prevUser?.jobDesignation || '',
                selectLocation: prevUser?.selectLocation ?? 0,
                selectAchievement: prevUser?.selectAchievement ?? 0,
                registeredEmailId: prevUser?.registeredEmailId || '', // Default to an empty string
            }));
        }
    };

    //PG End

    const handleDownload = async () => {
        if (!divRef.current) {
            console.error('Element not found!');
            return;
        }

        const images = Array.from(divRef.current.querySelectorAll('img')) as HTMLImageElement[];

        try {
            await Promise.all(
                images.map((img) => {
                    return new Promise((resolve, reject) => {
                        if (img.complete && img.naturalWidth > 0) {
                            resolve(true);
                        } else {
                            img.onload = () => resolve(true);
                            img.onerror = () => reject(false);
                        }
                    });
                })
            );

            const { default: html2canvas } = await import('html2canvas');
            const scaleFactor = 2; // Increase scale factor for better quality (400px / 400px = 2.7, so 3 is safe)
            const canvas = await html2canvas(divRef.current, {
                backgroundColor: secondaryColor,
                scale: scaleFactor, // Increase scale factor
                useCORS: true,
                allowTaint: false,
            });

            const scaledCanvas = document.createElement('canvas');
            scaledCanvas.width = 400; // Set desired width
            scaledCanvas.height = 400; // Set desired height
            const scaledContext = scaledCanvas.getContext('2d');

            if (scaledContext) {
                scaledContext.fillStyle = '#ffffff';
                scaledContext.fillRect(0, 0, 400, 400);
                scaledContext.drawImage(canvas, 0, 0, 400, 400);

                const dataUrl = scaledCanvas.toDataURL('image/png', 1.0); // Use maximum quality
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'spotlights-pic.jpg'; // Updated file name
                link.click();
            }
        } catch (error) {
            console.error('Error generating image:', error);
        }
    };
    function dataURLtoBlob(dataUrl: string): Blob {
        const [metadata, base64] = dataUrl.split(',');
        const byteString = atob(base64);
        const mimeString = metadata.split(':')[1].split(';')[0];

        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }

        return new Blob([uintArray], { type: mimeString });
    }
    const handleShareOnSocialMedia = async () => {
        if (!divRef.current) {
            console.error('Element not found!');
            return;
        }

        const { default: html2canvas } = await import('html2canvas');
        const scaleFactor = 3; // Increase scale factor for better quality
        const canvas = await html2canvas(divRef.current, {
            backgroundColor: secondaryColor,
            scale: scaleFactor,
            useCORS: true,
            allowTaint: false,
        });

        const dataUrl = canvas.toDataURL('image/png', 1.0); // Use maximum quality
        const imageBlob = dataURLtoBlob(dataUrl); // Convert base64 to Blob

        // Step 2: Upload the image to S3 via your API
        const apiUrl = `${process.env.REACT_APP_BROADCAST_SERVER}/upload/get-attached`;
        const formData = new FormData();
        formData.append('pdf_doc', imageBlob, 'spotlight_image.png');

        try {
            // Include the token in the headers for authentication
            const response = await axios.post(apiUrl, formData, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`, // Include the token in the Authorization header
                },
            });

            const link = response.data.link;

            if (response.data.success) {
                // Step 3: Store the S3 URL in sessionStorage
                sessionStorage.setItem('spotlight_post_image_url', link);

                // Redirect to the "get-image" page
               window.location.href = '/socialmedia/socialpost?social_post=1'; // Adjust this to your route for displaying the image
            } else {
                alert('Error uploading image to S3');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            alert('Failed to upload image.');
        }
    };
    const handlePreview = async () => {
        if (!divRef.current) {
            console.error('Element not found!');
            return;
        }

        // Capture the actual background color before modifying
        const computedStyle = window.getComputedStyle(divRef.current);
        const originalBgColor = computedStyle.backgroundColor;

        // Ensure the background color is set correctly before capturing
        divRef.current.style.backgroundColor = originalBgColor; // Retain the original background color

        const { default: html2canvas } = await import('html2canvas');
        const scaleFactor = 3; // Increase scale factor for better quality
        const canvas = await html2canvas(divRef.current, {
            backgroundColor: null, // Set null to retain actual background
            scale: scaleFactor, // Increase scale factor
            useCORS: true, // Enable CORS for external images
            allowTaint: false, // Disallow tainting the canvas
        });

        if (!previewRef.current || previewRef.current.closed) {
            const screenWidth = window.screen.width;
            const screenHeight = window.screen.height;
            const popupWidth = 400; // Set desired width
            const popupHeight = 400; // Set desired height
            const left = (screenWidth - popupWidth) / 2;
            const top = (screenHeight - popupHeight) / 2;
            previewRef.current = window.open('', 'Preview', `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`);
        }

        if (previewRef.current) {
            previewRef.current.document.body.innerHTML = '';
            previewRef.current.document.write(
                '<html><head><title>Preview</title></head><body style="margin: 0; display: flex; align-items: center; justify-content: center; background: #ffffff;"></body></html>'
            );
            const img = document.createElement('img');
            img.src = canvas.toDataURL('image/png', 1.0); // Use maximum quality
            img.style.width = '100%';
            img.style.height = 'auto';
            previewRef.current.document.body.appendChild(img);
        }

        // Reset the background color after capturing (optional)
        divRef.current.style.backgroundColor = originalBgColor;
    };

    return (
        <>
            <div className="main flex-1 bg-white">
                <div className="max-1140 mx-auto pt-5">
                    <div className="d-flex justify-content-between align-items-center pb-3 ">
                        <h3 data-aos="fade-left" data-aos-delay="600" className="font-30 font-600 mb-0 block-title position-relative">
                            Create New Image Template <i className="icon-right-arrow font-16 position-relative me-1"></i>
                            <span>Configure</span>
                        </h3>
                    </div>
                </div>

                <div className="max-1140 pb-5 overflow-hidden mx-auto">
                    <div className="row g-4">
                        <div className="col-sm-12">
                            <div className="bg-gray-F2F4F7 border-radius-6 p-3 p-sm-4 px-md-5 text-center font-24 font-500">
                                Leaders of the Future
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="row g-2 justify-content-between pb-2">
                                <div className="col-auto font-20 font-500">
                                    Category <span className="font-18 font-400">Leaders of the Future</span>
                                </div>
                                <div className="col-auto font-20 font-500">
                                    Size <span className="font-18 font-400">Instagram Post - 400 x 400 px</span>
                                </div>
                            </div>
                            <div className="bg-gray-F2F4F7 border-radius-6 p-4">
                                <div className="row g-4">
                                    <div className="col-md-6">
                                        {/* /.older file   */}
                                        {/* /end .older file   */}
                                        <div className="w-100 h-100 ">
                                            <div className="bg-white border-radius-6 p-3 p-sm-3 w-100 border h-100 d-flex justify-content-center align-items-center">
                                                <div className="max-430 mx-auto">
                                                    <div className="leaders-poster" style={{ backgroundColor: primaryColor }} ref={divRef}>
                                                        <img
                                                            src="https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/poster-white-bg.png"
                                                            className="img-fluid"
                                                            crossOrigin="anonymous"
                                                            alt=""
                                                        />

                                                        <div className="college-logo">
                                                            <img
                                                                src={
                                                                    schoolImage ||
                                                                    'https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/neps-logo.png'
                                                                }
                                                                className="img-fluid"
                                                                alt="School Logo"
                                                            />
                                                        </div>

                                                        <div className="leaders-poster-alumni">
                                                            <img
                                                                src={
                                                                    uploadedImage ||
                                                                    selectedUser?.profilePictureFilePath ||
                                                                    'https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/profile_pic.png'
                                                                }
                                                                width="80"
                                                                alt=""
                                                                crossOrigin="anonymous"
                                                            />
                                                        </div>

                                                        <div className="leaders-poster-alumni-detail">
                                                            <div className="leaders-poster-alumni-name" style={{ color: secondaryColor }}>
                                                                {selectedUser
                                                                    ? `${selectedUser.firstName} ${selectedUser.lastName}`
                                                                    : 'Alumni Name'}
                                                            </div>
                                                            <div className="leaders-poster-alumni-batch-school">
                                                                {selectedUser ? `${selectedUser.passOutYear}` : 'Batch 2018'} |{' '}
                                                                {selectedUser ? `${selectedUser.programName}` : '12th CLass'}
                                                            </div>
                                                            <div className="curve-line">
                                                                <img
                                                                    src="https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/curve-line.svg"
                                                                    className="img-fluid"
                                                                    width="476"
                                                                    alt=""
                                                                    crossOrigin="anonymous"
                                                                />
                                                            </div>
                                                            <div className="pursuing">
                                                                <i></i>

                                                                <div className="pursuing-title">
                                                                    {selectedDataSet === 'JoinedUGCollege' ||
                                                                    selectedUser?.selectugCollege === 1 ||
                                                                    (!selectedDataSet &&
                                                                        !selectedUser?.selectpgCollege &&
                                                                        !selectedUser?.selectjobCompany) ? (
                                                                        <span>UG</span>
                                                                    ) : selectedDataSet === 'JoinedPGCollege' ||
                                                                      selectedUser?.selectpgCollege === 1 ? (
                                                                        <span>PG</span>
                                                                    ) : selectedDataSet === 'CurrentCompany' ||
                                                                      selectedUser?.selectjobCompany === 1 ? (
                                                                        <span>Organization</span>
                                                                    ) : (
                                                                        <i className="tool"></i>
                                                                    )}

                                                                    <br />

                                                                    {selectedDataSet === 'JoinedUGCollege' ||
                                                                    selectedUser?.selectugCollege === 1 ||
                                                                    (!selectedDataSet &&
                                                                        !selectedUser?.selectpgCollege &&
                                                                        !selectedUser?.selectjobCompany) ? (
                                                                        `${selectedUser?.ugCollege || 'N/A'}`
                                                                    ) : selectedDataSet === 'JoinedPGCollege' ||
                                                                      selectedUser?.selectpgCollege === 1 ? (
                                                                        `${selectedUser?.pgCollege || 'N/A'}`
                                                                    ) : selectedDataSet === 'CurrentCompany' ||
                                                                      selectedUser?.selectjobCompany === 1 ? (
                                                                        `${selectedUser?.jobCompany || 'N/A'}`
                                                                    ) : (
                                                                        <i className="tool"></i>
                                                                    )}
                                                                    <span className="mx-1">-</span>
                                                                    <b>
                                                                        {selectedDataSet === 'JoinedUGCollege' ||
                                                                        selectedUser?.selectugCollege === 1 ||
                                                                        (!selectedDataSet &&
                                                                            !selectedUser?.selectpgCollege &&
                                                                            !selectedUser?.selectjobCompany) ? (
                                                                            `${selectedUser?.ugDepartment || ' N/A '}`
                                                                        ) : selectedDataSet === 'JoinedPGCollege' || // Fixed space issue
                                                                          selectedUser?.selectpgCollege === 1 ? (
                                                                            `${selectedUser?.pgDepartment || 'N/A'}` // Ensuring pgDepartment is available
                                                                        ) : selectedDataSet === 'CurrentCompany' ||
                                                                          selectedUser?.selectjobCompany === 1 ? (
                                                                            `${selectedUser?.jobDesignation || 'N/A'}`
                                                                        ) : (
                                                                            <i className="tool"></i>
                                                                        )}
                                                                    </b>
                                                                </div>

                                                                <div className="pursuing-logo"></div>
                                                            </div>
                                                        </div>

                                                        <div className="expertise">
                                                            <i></i>
                                                            <strong>Expertise:</strong> {expertise || 'Enter your expertise above'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row g-4">
                                            <div className="col-12">
                                                <div className="bg-white border-radius-6 p-4">
                                                    <form className="p-2">
                                                        <div className="row g-4">
                                                            <div className="col-md-12">
                                                                <div className="row g-3 align-items-center">
                                                                    <div className="col-12">
                                                                        <label className="form-label m-0 mb-1 font-16 font-600">
                                                                            ADD ALUMNI PROFILE
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-auto col-md-auto col-lg-6">
                                                                        <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                                                            <input
                                                                                type="radio"
                                                                                name="ADDALUMNIPROFILE"
                                                                                id="pickDatafromSystem"
                                                                                value="yes"
                                                                                checked={selectedOption === 'yes'}
                                                                                onChange={handleOptionChange}
                                                                                className="form-check-input custom-border-84-20 m-0"
                                                                            />
                                                                            <label
                                                                                htmlFor="pickDatafromSystem"
                                                                                className="form-check-label font-16 font-500 ms-2 ">
                                                                                Pick Data from System
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-auto col-md-auto col-lg-6">
                                                                        <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                                                            <input
                                                                                type="radio"
                                                                                name="ADDALUMNIPROFILE"
                                                                                id="addExternalData"
                                                                                value="no"
                                                                                checked={selectedOption === 'no'}
                                                                                onChange={handleOptionChange}
                                                                                className="form-check-input custom-border-84-20 m-0"
                                                                            />
                                                                            <label
                                                                                htmlFor="addExternalData"
                                                                                className="form-check-label font-16 font-500 ms-2 ">
                                                                                Add External Data
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* Conditionally render the div based on the selected option */}
                                                            {selectedOption === 'yes' && (
                                                                <div className="row g-4">
                                                                    <div className="main flex-1 bg-white">
                                                                        <div className="containerinnercover singleOption">
                                                                            <AsyncSelect
                                                                                loadOptions={loadOptions}
                                                                                onChange={handleOnChange}
                                                                                isSearchable={true}
                                                                                isClearable
                                                                                components={{
                                                                                    DropdownIndicator: null,
                                                                                    IndicatorSeparator: null,
                                                                                    Option: CustomOption,
                                                                                }}
                                                                                menuPortalTarget={document.body}
                                                                                styles={{
                                                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                                }}
                                                                                value={selectedUser}
                                                                                placeholder="Search by name" // Add this line for the placeholder
                                                                            />
                                                                            {errors.user && (
                                                                                <div style={{ color: 'red', marginTop: '5px' }}>
                                                                                    {errors.user}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <label
                                                                            className="form-label m-0 mb-2 font-16 font-500 w-100"
                                                                            htmlFor="DataSet">
                                                                            Choose Data Set to Display
                                                                        </label>
                                                                        <div className="row g-3">
                                                                            <div className="col-auto col-md-auto col-lg-6">
                                                                                <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center pe-3">
                                                                                    <input
                                                                                        type="radio"
                                                                                        name="DataSet"
                                                                                        id="JoinedUGCollege"
                                                                                        value="JoinedUGCollege"
                                                                                        onChange={handleRadioChange}
                                                                                        className="form-check-input custom-border-84-20 m-0"
                                                                                        checked={selectedDataSet === 'JoinedUGCollege'} // Automatically selects if selectugCollege is 1
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="JoinedUGCollege"
                                                                                        className="form-check-label font-16 font-500 ms-2">
                                                                                        Joined UG College
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-auto col-md-auto col-lg-6">
                                                                                <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                                                                    <input
                                                                                        type="radio"
                                                                                        name="DataSet"
                                                                                        id="JoinedPGCollege"
                                                                                        value="JoinedPGCollege"
                                                                                        onChange={handleRadioChange}
                                                                                        className="form-check-input custom-border-84-20 m-0"
                                                                                        checked={selectedDataSet === 'JoinedPGCollege'} // Automatically selects if selectpgCollege is 1
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="JoinedPGCollege"
                                                                                        className="form-check-label font-16 font-500 ms-2">
                                                                                        Joined PG College
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-auto col-md-auto col-lg-6">
                                                                                <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                                                                    <input
                                                                                        type="radio"
                                                                                        name="DataSet"
                                                                                        id="CurrentCompany"
                                                                                        value="CurrentCompany"
                                                                                        onChange={handleRadioChange}
                                                                                        className="form-check-input custom-border-84-20 m-0"
                                                                                        checked={selectedDataSet === 'CurrentCompany'} // Automatically selects if selectjobCompany is 1
                                                                                    />
                                                                                    <label
                                                                                        htmlFor="CurrentCompany"
                                                                                        className="form-check-label font-16 font-500 ms-2">
                                                                                        Current Company
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {errors.dataSet && (
                                                                            <div style={{ color: 'red', marginTop: '5px' }}>
                                                                                {errors.dataSet}
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    <div className="col-md-12">
                                                                        <hr className="m-0" />
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <label
                                                                            className="form-label m-0 mb-1 font-16 font-500 w-100 mb-2"
                                                                            htmlFor="cloud-computing">
                                                                            Upload Profile Pic
                                                                        </label>
                                                                        <div className="upload_section">
                                                                            <input
                                                                                type="file"
                                                                                id="cloud-computing"
                                                                                onChange={handleImageUpload}
                                                                                className=""
                                                                                accept="image/*"
                                                                            />
                                                                            <div className="row g-2 align-items-center justify-content-center">
                                                                                <div className="col-sm d-flex align-items-center justify-content-center">
                                                                                    <div className="cloud-wrap px-3 px-sm-5 mx-auto">
                                                                                        <div className="cloud-img">
                                                                                            <img src={imgcloudcomputing} alt="" />
                                                                                        </div>
                                                                                        <div className="cloud-text">
                                                                                            {' '}
                                                                                            <a href="#" className="text-sky-blue">
                                                                                                Browse
                                                                                            </a>{' '}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <span className="text-end font-14 d-block text-dark-blue opacity-7 mt-1">
                                                                            Only png, jpg, jpeg files. Best resolution 400px x 400px
                                                                        </span>
                                                                        {errors.image && (
                                                                            <div style={{ color: 'red', marginTop: '5px' }}>
                                                                                {errors.image}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {selectedOption === 'no' && (
                                                                <div className="col-md-12">
                                                                    <div className="row g-3 align-items-center mb-2">
                                                                        <div className="col-12">
                                                                            <input
                                                                                type="text"
                                                                                onChange={handleAlumniNameChange}
                                                                                value={selectedUser?.name || ''} // Ensure the value comes from selectedUser
                                                                                className="form-control"
                                                                                placeholder="Enter Alumni Name"
                                                                                maxLength={38}
                                                                            />
                                                                        </div>
                                                                        {errors.username && (
                                                                            <div
                                                                                style={{
                                                                                    color: 'red',
                                                                                    marginTop: '5px',
                                                                                    backgroundColor: secondaryColor,
                                                                                }}>
                                                                                {errors.username}
                                                                            </div>
                                                                        )}

                                                                        {errors.schoolname && (
                                                                            <div style={{ color: 'red', marginTop: '5px' }}>
                                                                                {errors.schoolname}
                                                                            </div>
                                                                        )}
                                                                        <div className="col-12">
                                                                            <input
                                                                                type="text"
                                                                                onChange={handleProgramChange}
                                                                                value={selectedUser?.programName || ''} // Ensure the value comes from selectedUser
                                                                                className="form-control"
                                                                                placeholder="Enter Alumni Program"
                                                                                maxLength={48}
                                                                            />
                                                                        </div>
                                                                        {errors.programname && (
                                                                            <div style={{ color: 'red', marginTop: '5px' }}>
                                                                                {errors.programname}
                                                                            </div>
                                                                        )}
                                                                        <div className="col-12">
                                                                            <input
                                                                                type="text"
                                                                                onChange={handleBatchChange}
                                                                                value={selectedUser?.passOutYear || ''} // Ensure the value comes from selectedUser
                                                                                className="form-control"
                                                                                placeholder="Enter Alumni Batch Year"
                                                                                maxLength={11}
                                                                            />
                                                                        </div>
                                                                        {errors.passout && (
                                                                            <div style={{ color: 'red', marginTop: '5px' }}>
                                                                                {errors.passout}
                                                                            </div>
                                                                        )}
                                                                        <div className="col-12">
                                                                            <input
                                                                                type="radio"
                                                                                name="DataSet"
                                                                                id="JoinedUGCollege"
                                                                                value="JoinedUGCollege"
                                                                                onChange={handleRadioChange}
                                                                                className="form-check-input custom-border-84-20 m-0 college-type"
                                                                                checked={selectedDataSet === 'JoinedUGCollege'} // Automatically selects if selectugCollege is 1
                                                                            />
                                                                            <label
                                                                                htmlFor="JoinedUG"
                                                                                className="form-check-label font-16 font-500 ms-2">
                                                                                Joined UG
                                                                            </label>
                                                                            <div className="row g-2  mt-0 college-type-option">
                                                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                                                    <label
                                                                                        htmlFor="ShowCurrentLocation"
                                                                                        className="form-check-label font-16 font-500 mb-1 ">
                                                                                        Joined UG College
                                                                                    </label>
                                                                                    <input
                                                                                        type="text"
                                                                                        onChange={handleUGcollegeChange}
                                                                                        value={selectedUser?.ugCollege || ''} // Ensure the value comes from selectedUser
                                                                                        className="form-control"
                                                                                        placeholder="Enter College Name"
                                                                                        maxLength={50}
                                                                                    />
                                                                                    {/* <input type="text" className="form-control" placeholder="Enter Company Name" /> */}
                                                                                    {errors.ugcollege && (
                                                                                        <div style={{ color: 'red', marginTop: '5px' }}>
                                                                                            {errors.ugcollege}
                                                                                        </div>
                                                                                    )}
                                                                                </div>

                                                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                                                    <label
                                                                                        htmlFor="ShowAchievement"
                                                                                        className="form-check-label font-16 font-500 mb-1 ">
                                                                                        Joined UG Course
                                                                                    </label>
                                                                                    <input
                                                                                        type="text"
                                                                                        onChange={handleUGDesignationChange}
                                                                                        value={selectedUser?.ugDepartment || ''} // Ensure the value comes from selectedUser
                                                                                        className="form-control"
                                                                                        placeholder="Enter Course Name"
                                                                                        maxLength={25}
                                                                                    />
                                                                                    {/* <input type="text" className="form-control" placeholder="Enter Designation" /> */}
                                                                                    {errors.ugcourse && (
                                                                                        <div style={{ color: 'red', marginTop: '5px' }}>
                                                                                            {errors.ugcourse}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <input
                                                                                type="radio"
                                                                                name="DataSet"
                                                                                id="JoinedPGCollege"
                                                                                value="JoinedPGCollege"
                                                                                onChange={handleRadioChange}
                                                                                className="form-check-input custom-border-84-20 m-0 college-type"
                                                                                checked={selectedDataSet === 'JoinedPGCollege'} // Automatically selects if selectpgCollege is 1
                                                                            />
                                                                            <label
                                                                                htmlFor="JoinedPG"
                                                                                className="form-check-label font-16 font-500 ms-2">
                                                                                Joined PG
                                                                            </label>
                                                                            <div className="row g-2  mt-0 college-type-option">
                                                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                                                    <label
                                                                                        htmlFor="ShowCurrentLocation"
                                                                                        className="form-check-label font-16 font-500 mb-1 ">
                                                                                        Joined PG College
                                                                                    </label>
                                                                                    <input
                                                                                        type="text"
                                                                                        onChange={handlePGcollegeChange}
                                                                                        value={selectedUser?.pgCollege || ''} // Ensure the value comes from selectedUser
                                                                                        className="form-control"
                                                                                        placeholder="Enter College Name"
                                                                                        maxLength={50}
                                                                                    />
                                                                                    {/* <input type="text" className="form-control" placeholder="Enter Company Name" /> */}
                                                                                    {errors.pgcollege && (
                                                                                        <div style={{ color: 'red', marginTop: '5px' }}>
                                                                                            {errors.pgcollege}
                                                                                        </div>
                                                                                    )}
                                                                                </div>

                                                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                                                    <label
                                                                                        htmlFor="ShowAchievement"
                                                                                        className="form-check-label font-16 font-500 mb-1 ">
                                                                                        Joined PG Course
                                                                                    </label>
                                                                                    <input
                                                                                        type="text"
                                                                                        onChange={handlePGDesignationChange}
                                                                                        value={selectedUser?.pgDepartment || ''} // Ensure the value comes from selectedUser
                                                                                        className="form-control"
                                                                                        placeholder="Enter Course Name"
                                                                                        maxLength={25}
                                                                                    />
                                                                                    {/* <input type="text" className="form-control" placeholder="Enter Designation" /> */}
                                                                                    {errors.pgcourse && (
                                                                                        <div style={{ color: 'red', marginTop: '5px' }}>
                                                                                            {errors.pgcourse}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <input
                                                                                type="radio"
                                                                                name="DataSet"
                                                                                id="CurrentCompany"
                                                                                value="CurrentCompany"
                                                                                onChange={handleRadioChange}
                                                                                className="form-check-input custom-border-84-20 m-0 college-type"
                                                                                checked={selectedDataSet === 'CurrentCompany'} // Automatically selects if selectjobCompany is 1
                                                                            />
                                                                            <label
                                                                                htmlFor="CurrentCompany"
                                                                                className="form-check-label font-16 font-500 ms-2">
                                                                                Current Company
                                                                            </label>

                                                                            <div className="row g-2  mt-0 college-type-option">
                                                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                                                    <label
                                                                                        htmlFor="ShowCurrentLocation"
                                                                                        className="form-check-label font-16 font-500 mb-1 ">
                                                                                        Company Name
                                                                                    </label>
                                                                                    <input
                                                                                        type="text"
                                                                                        onChange={handleCompanyNameChange}
                                                                                        value={selectedUser?.jobCompany || ''} // Ensure the value comes from selectedUser
                                                                                        className="form-control"
                                                                                        placeholder="Enter Company Name"
                                                                                        maxLength={50}
                                                                                    />
                                                                                    {/* <input type="text" className="form-control" placeholder="Enter Company Name" /> */}
                                                                                    {errors.companyname && (
                                                                                        <div style={{ color: 'red', marginTop: '5px' }}>
                                                                                            {errors.companyname}
                                                                                        </div>
                                                                                    )}
                                                                                </div>

                                                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                                                    <label
                                                                                        htmlFor="ShowAchievement"
                                                                                        className="form-check-label font-16 font-500 mb-1 ">
                                                                                        Current Designation
                                                                                    </label>
                                                                                    <input
                                                                                        type="text"
                                                                                        onChange={handlecurrentDesignationChange}
                                                                                        value={selectedUser?.jobDesignation || ''} // Ensure the value comes from selectedUser
                                                                                        className="form-control"
                                                                                        placeholder="Enter Designation"
                                                                                        maxLength={25}
                                                                                    />
                                                                                    {/* <input type="text" className="form-control" placeholder="Enter Designation" /> */}
                                                                                    {errors.currentdesignation && (
                                                                                        <div style={{ color: 'red', marginTop: '5px' }}>
                                                                                            {errors.currentdesignation}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {errors.dataSet && (
                                                                            <div style={{ color: 'red', marginTop: '5px' }}>
                                                                                {errors.dataSet}
                                                                            </div>
                                                                        )}

                                                                        <div className="col-sm-6 col-md-12 col-lg-7">
                                                                            {/* <input type="text" className="form-control" placeholder="Enter Current Location"/>  */}
                                                                        </div>
                                                                        {errors.currentlocation && (
                                                                            <div style={{ color: 'red', marginTop: '5px' }}>
                                                                                {errors.currentlocation}
                                                                            </div>
                                                                        )}

                                                                        {errors.achievement && (
                                                                            <div style={{ color: 'red', marginTop: '5px' }}>
                                                                                {errors.achievement}
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    <div className="col-md-12">
                                                                        <hr />
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <label
                                                                            className="form-label m-0 mb-1 font-16 font-500 w-100 mb-2"
                                                                            htmlFor="cloud-computing">
                                                                            Upload Display Image
                                                                        </label>
                                                                        <div className="upload_section">
                                                                            <input
                                                                                type="file"
                                                                                id="cloud-computing"
                                                                                onChange={handleImageUpload}
                                                                                className=""
                                                                                accept="image/*"
                                                                            />
                                                                            <div className="row g-2 align-items-center justify-content-center">
                                                                                <div className="col-sm d-flex align-items-center justify-content-center">
                                                                                    <div className="cloud-wrap px-3 px-sm-5 mx-auto">
                                                                                        <div className="cloud-img">
                                                                                            <img src={imgcloudcomputing} alt="" />
                                                                                        </div>
                                                                                        <div className="cloud-text">
                                                                                            {' '}
                                                                                            <a href="#" className="text-sky-blue">
                                                                                                Browse
                                                                                            </a>{' '}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <span className="text-end font-14 d-block text-dark-blue opacity-7 mt-1">
                                                                            Only png, jpg, jpeg files. Best resolution 400px x 400px
                                                                        </span>
                                                                        {errors.image && (
                                                                            <div style={{ color: 'red', marginTop: '5px' }}>
                                                                                {errors.image}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-12">
                                                            <input
                                                                type="text"
                                                                value={expertise}
                                                                id="experties"
                                                                onChange={(e) => setExpertise(e.target.value)}
                                                                className="form-control"
                                                                placeholder="Enter User Expertise"
                                                                maxLength={65}
                                                            />
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="col-md-6">
                                                                <label className="form-label m-0 mb-1 font-16 font-500">
                                                                    Primary Color Shade
                                                                </label>
                                                                <input
                                                                    type="color"
                                                                    value={primaryColor}
                                                                    onChange={(e) => setPrimaryColor(e.target.value)}
                                                                    className="form-control form-search radius-25"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <label className="form-label m-0 mb-1 font-16 font-500">
                                                                Secondary Color Shade
                                                            </label>
                                                            <input
                                                                type="color"
                                                                value={secondaryColor}
                                                                onChange={(e) => setSecondaryColor(e.target.value)}
                                                                className="form-control form-search radius-25"
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="action_btns mt-4 max-100per">
                {/* <button type="button" className="nextbtn my-2 my-sm-0 mx-2 px-5" data-bs-toggle="modal" data-bs-target="#exampleModal">Preview</button>               */}
                <button type="button" className="nextbtn my-2 my-sm-0 mx-2" onClick={handlePreview}>
                    <span className="px-3">Preview</span>
                </button>
                <button type="button" className="btn btn-green my-2 my-sm-0 mx-2 height-45" onClick={handleDownload}>
                    <span className="px-3">Save as Image</span>
                </button>
                <button
                                    type="button"
                                    className="btn btn-border-gray my-2 my-sm-0 mx-3 height-45"
                                    onClick={handleShareOnSocialMedia}>
                                    <span className="px-3">Share on Social Media</span>
                                </button>
                
            </div>
        </>
    );
};

export default FutureLeaders;
