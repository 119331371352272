import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import { handleCompleteLater, handleNext, onDidYouReceiveScholarshipChange, populateEndYearOnStartYearChange } from './PostGraduateActions';
import PostGraduateLayout from './PostGraduateLayout';
import { getDomainValueForCode } from '../../../../library/Core/SmartFunctions';

const PostGraduate = (props: any) => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState('');

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            NEXT: handleNext,
            I_LL_DO_THIS_LATER: handleCompleteLater,
            ROUTER_NAVIGATE: navigate,
            startYear: populateEndYearOnStartYearChange,
            didYouReceiveScholarship: onDidYouReceiveScholarshipChange,
            REDIRECT: setRedirectUrl
        },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    let message = `Here's How My Post Graduation Went!`
    let outcomeStudiesStatus = ['JOINED_DOCTORAL','HIGHER_STUDIES','JOINED_PG','WENT_FOR_HIGHER_STUDIES']
    if(outcomeStudiesStatus.includes(state?.data?.selectedInstitute?.postProgramCompletionStatusCode)){
        let program_name = getDomainValueForCode(state?.data?.selectedInstitute?.programId,'ALL_PROGRAM_CODE',state)
        let institute_name = getDomainValueForCode(state?.data?.selectedInstitute?.instituteId,'INSTITUTE_DOMAIN',state)
        message = `After Completing ${program_name} from ${institute_name}, you mentioned that you went for higher studies. Please provide the details of the institute and course you joined after your program completion at ${institute_name}`
    }
    else 
    if(state?.data?.profile?.presentEducationStatusCode == "PURSUING_STUDIES"){
        let program_level = state?.data?.profile?.pursuingEducationLevelCode
        message = `In your education status, you mentioned that you are currently pursuing studies at ${program_level} level. Please provide the details of your current education.`
    }

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <PostGraduateLayout>{!state.flags.isDataLoading && <PageBuilder data={message}/>}</PostGraduateLayout>
        </SmartContext.Provider>
    );
};

export default PostGraduate;
