import React, { useState } from 'react';

const LoginWithLinkedin: React.FC = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [user, setUser] = useState<any>(null); // Optional: Store user info

  // Handle Instagram OAuth login
  const handleLogin = () => {    
    // Construct the OAuth URL
    const clientId = `${process.env.REACT_APP_LINKEDIN_CLIENT_ID}`;
    // const clientId = `${process.env.REACT_APP_LINKEDIN_COMMUNITY_MGMT_APP_ID}`;
    console.log(clientId, " clientId =============== clientId")
    const redirectUri = `${process.env.REACT_APP_APP_BASE_URL}/socialmedia/linkedin-login-success`;
    // const scope = 'r_organization_social,w_organization_social';
    const scope = 'r_liteprofile,r_emailaddress,w_member_social';

    // const authUrl = `https://www.facebook.com/v13.0/dialog/oauth?client_id=${facebookAppId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}&response_type=${responseType}`;
    // const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&state={STATE}&scope=${encodeURIComponent(scope)}`;
    const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}`;
    // debugger;

    // Redirect the user to the Facebook login page
    window.location.href = authUrl;
  };

  // Check if the user is already redirected back with the access token
  React.useEffect(() => {
    handleLogin();    
  }, []);

  return (
    <div>
      <button onClick={handleLogin}></button>
    </div>
  );
};

export default LoginWithLinkedin;