import { AxiosResponse } from 'axios';
import AppSettingsService from '../../services/app-settings.service';
import DomainDataService from '../../services/domain-data.service';
import JobPostingService from '../../services/job-posting.service';
import PageConfigService from '../../services/page-config.service';
import StudentService from '../../services/student.service';
import { axiosClient } from '../Service/axiosClient';
import { getValueFromSessionStore, storeAppConfig } from './SessionStore';
import { convertDomainArrayToMap, isEmpty } from './SmartFunctions';
import { ObjectWithKeys } from './SmartTypes';

export type LocalStorageResponse = {
    data: any;
    source: string;
};

const getDataUrl = (pageName: string, id: string = '', searchBy: string = '') => {
    const dataUrl: ObjectWithKeys = {
        'school-management-login': { type: 'GET_NOT_APPLICABLE', url: `${process.env.REACT_APP_STUDENT_SERVER}/${id}/student` },
        'student-signup': { type: 'GET_NOT_APPLICABLE', url: `${process.env.REACT_APP_STUDENT_SERVER}/${id}/student` },
        'student-login': { type: 'GET_NOT_APPLICABLE', url: `${process.env.REACT_APP_STUDENT_SERVER}/${id}/student` },
        'alumni-login': { type: 'GET_NOT_APPLICABLE', url: `${process.env.REACT_APP_STUDENT_SERVER}/${id}/student` },
        'alumni-signup': { type: 'GET_NOT_APPLICABLE', url: `${process.env.REACT_APP_STUDENT_SERVER}/${id}/student` },
        'alumni-job-posting': { type: 'GENERAL', url: `${process.env.REACT_APP_JOB_SERVER}/jobs/${id}` },
        'alumni-directory': { type: 'GENERAL', url: `http://localhost:3010/api/alumni/directory` },
        'alumni-job-listing': { type: 'GENERAL', url: `http://localhost:3010/api/jobs?criteria=ACTIVE_LIST` },
        messages: { type: 'GENERAL', url: `${process.env.REACT_APP_STUDENT_SERVER}/conversation?page=0&size=10&sort=` },
        'alumni-profile': { type: 'GENERAL', url: `${process.env.REACT_APP_STUDENT_SERVER}/alumni/${id}/profile` },
        'alumni-onboarding': { type: 'GET_NOT_APPLICABLE', url: `${process.env.REACT_APP_STUDENT_SERVER}/alumni/${id}/profile` },
        'alumni-welcome': { type: 'GET_NOT_APPLICABLE', url: `${process.env.REACT_APP_STUDENT_SERVER}/alumni/${id}/profile` },
        studentAdditionalInfo: { type: '', url: '' },
        'alumni-album': { type: 'GENERAL', url: `${process.env.REACT_APP_STUDENT_SERVER}/memories/${id}` },
        alumniFirstStepComplete: { type: '', url: '' },
    };
    return dataUrl[pageName] as ObjectWithKeys;
};

const getData = (
    pageName: string,
    id: string,
    searchBy: string,
    editId:any
): Promise<any> | Promise<AxiosResponse<any, any>> | Promise<LocalStorageResponse> => {
    const storedEntity = getValueFromSessionStore(`page-data-${id}-${pageName}`);
    if (!isEmpty(storedEntity)) return Promise.resolve({ data: JSON.parse(storedEntity as string) });
    const dataUrlConfig = getDataUrl(pageName, id, searchBy);

    if (pageName === 'alumni-job-posting') return JobPostingService.getJobPostingById(id);
    if (dataUrlConfig['type'] === 'GET_NOT_APPLICABLE') return Promise.resolve({ data: {} });
    if (id === 'new') return Promise.resolve({ data: {} });
    if (dataUrlConfig['type'] === 'POST') return axiosClient().post(dataUrlConfig['url'] as string, {});

    return StudentService.get(id, dataUrlConfig['apiShortName'] === 'student-profile' ? false : true,false,editId);
};

const pageLoader = (params: any) => {
    const state: any = {};
    const { id, pageName, searchBy,editId } = params;

    return Promise.all([
        PageConfigService.get(pageName),
        getData(pageName, id, searchBy,editId),
        DomainDataService.get(),
        AppSettingsService.get(),
    ])
        .then((values) => {
            state.formConfig = values[0].data;
            state.data = values[1];
            state.domain = values[2].data instanceof Map ? values[2].data : convertDomainArrayToMap(values[2].data);
            state.internal = { gridState: [] };
            state.routeInfo = { id, pageName, ...params };
            // storeDomainData(state.domain);
            storeAppConfig(values[3].data);
            return Promise.resolve(state);
        })
        .catch(function (err) {
            console.log(err);
        });
};

export default pageLoader;
