import { isEmpty, isFormValid, setError, syncProfileMarker } from '../../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../../library/Core/SmartTypes';
import { AlumniProfileEditScreens } from '../../../../../services/screen-navigation.service';
import StudentService from '../../../../../services/student.service';
import { navigateToNextStep } from '../../../services/profile-next-step-navigator';


interface DomainItem {
    categoryCode: string;
    value: string;
    code: string;
}

interface Domain {
    [key: string]: DomainItem[];
}

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const inValidDate = isValidDate(state, dispatch);
    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);
    if (!isImpersonatedSession && (!customValidationPassed || !inValidDate || !isFormValid(state, dispatch))) {
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    let professionalPracticeOutcome = state?.data['professionalPracticeOutcome'];
    if (professionalPracticeOutcome && typeof professionalPracticeOutcome === 'object' && !Array.isArray(professionalPracticeOutcome)) {
        professionalPracticeOutcome = [professionalPracticeOutcome];
    } else if (!Array.isArray(professionalPracticeOutcome)) {
        professionalPracticeOutcome = [];
    }

    // Get Industry and Functional Area Data with proper checks for array structure
    const IndustryData = Array.from(globalState?.domain || [])
        .filter(([key, value]: any) => {
            return value.some((item: any) => item.categoryCode === 'INDUSTRY_CODE');
        })
        .reduce((result: any, [key, value]: any) => {
            value.forEach((item: any) => {
                if (item.categoryCode === 'INDUSTRY_CODE') {
                    console.log('Item during reduce:', item); // Log each item that matches 'INDUSTRY_CODE'
                    result[item.value] = item.code;
                }
            });
            return result;
        }, {}) as any;

    const functionalData = Array.from(globalState?.domain || [])
        .filter(([key, value]: any) => {
            return value.some((item: any) => item.categoryCode === 'FUNCTIONAL_AREA_CODE');
        })
        .reduce((result: any, [key, value]: any) => {
            value.forEach((item: any) => {
                if (item.categoryCode === 'FUNCTIONAL_AREA_CODE') {
                    console.log('Item during reduce:', item);
                    result[item.value] = item.code;
                }
            });
            return result;
        }, {}) as any;

    // Mappers to convert Practice Types to corresponding Industry/Functional Area
    const industryMapperWithPractice = {
        DOCTOR: 'Healthcare and Life Sciences',
        DENTIST: 'Healthcare and Life Sciences',
        VETERINARIAN: 'Healthcare and Life Sciences',
        PSYSIOTHERAPY: 'Healthcare and Life Sciences',
        CA: 'Accounting, Auditing and Financial Services',
        LEGAL: 'Law and Legal Consulting',
        ARCHITECT: 'Infrastructure and Construction',
        INSURANCE_AGENT: 'BFSI',
        REAL_ESTATE: 'Real Estate',
    };

    const functionalAreaMapperWithPractice = {
        DOCTOR: 'Diagnosis and Treatment',
        DENTIST: 'Diagnosis and Treatment',
        VETERINARIAN: 'Diagnosis and Treatment',
        PSYSIOTHERAPY: 'Diagnosis and Treatment',
        CA: 'Finance and Accounts',
        LEGAL: 'Legal',
        ARCHITECT: 'Engineering and Design',
        INSURANCE_AGENT: 'Sales and BD',
        REAL_ESTATE: 'Sales and BD',
    };


    if (Array.isArray(professionalPracticeOutcome)) {
        professionalPracticeOutcome = professionalPracticeOutcome.map((practice) => {
            let industryCode: string | null = null;
            let functionalAreaCode: string | null = null;

        if (practice?.typeOfPractice && practice.typeOfPractice !== 'OTHERS') {
                const typeOfPractice = practice?.typeOfPractice as keyof typeof industryMapperWithPractice;
                const mappedIndustry = industryMapperWithPractice[typeOfPractice];
                if (mappedIndustry && IndustryData) {
                    industryCode = IndustryData[mappedIndustry] ?? null;
                }

                const mappedFunctionalArea = functionalAreaMapperWithPractice[typeOfPractice];
                if (mappedFunctionalArea && functionalData) {
                    functionalAreaCode = functionalData[mappedFunctionalArea] ?? null;
                }
            } else {
                industryCode = practice?.industryCode ?? null;
                functionalAreaCode = practice?.functionalAreaCode ?? null;
            }

            const updatedPractice = state?.data?.jobsList?.find((existingJob: any) => existingJob.id == practice.id);
            return updatedPractice ? updatedJob(updatedPractice, practice, industryCode, functionalAreaCode) : updatedJob({}, practice, industryCode, functionalAreaCode);
        });

        const jobsNotInProfessionalPract = state?.data?.jobsList?.filter((existingJob: any) => 
            !professionalPracticeOutcome.some((job: any) => job.id === existingJob.id)
        );
        professionalPracticeOutcome = [...professionalPracticeOutcome, ...jobsNotInProfessionalPract];
    }
    
    let jobAppliedRecords = state?.data?.jobsApplied ?? [];
    let jobHistory = [...professionalPracticeOutcome, ...jobAppliedRecords];

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            jobs: jobHistory,
            profile: state?.data['profile'],
        })
    ).then((response) => {
        if (response?.status === 200) {
            syncProfileMarker(state.routeInfo.id).then(() => {
                navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch, sessionState, sessionDispatch);
            })
            
        }
    });
};

const updatedJob = (existingJob: any, practice: any, industryCode: any, functionalAreaCode: any) => ({
    ...existingJob,
    ...practice,
    recordTypeCode: 'JOINED',
    jobCategory: 'PROFESSIONAL_PRACTICE',
    isOutcome: 1,
    industryCode,
    functionalAreaCode,
    endDate: practice.isCurrent ? null : existingJob?.endDate || practice.endDate
});

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    let isFormInvalid = false;
    const professionalPracticeOutcome = state.data.professionalPracticeOutcome;

    professionalPracticeOutcome.forEach((professionalPracticeOutcome: any, index: number) => {
        if (isEmpty(professionalPracticeOutcome?.companyId) && isEmpty(professionalPracticeOutcome?.otherCompany)) {
            isFormInvalid = true;
            setError(`professionalPracticeOutcome.${index}.companyId`, [`Please enter "Name of the Company"`], dispatch);
        } else {
            setError(`professionalPracticeOutcome.${index}.companyId`, [], dispatch);
        }
    });

    // Dispatch the validation errors to the state
    if (isFormInvalid) {
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'professionalPracticeOutcome', errorMessages },
        });
    }
    return !isFormInvalid;
};

export const onCurrentJobSelectionChange = (props: ControlOnChangeArguments) => {
    const rowIndex = parseInt(props.parentDataKey.split('.').pop() as string);
    const jobs = props.state.data.professionalPracticeOutcome.map((row: any, index: number) => {
        setError(`professionalPracticeOutcome.${index}.endDate`, [], props.dispatch);
        return index == rowIndex ? { ...row, endYear: '', isCurrent: false } : { ...row, isCurrent: false };
    });
    props.dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey: `${props.parentDataKey}.jobStartAndEndDates`, value: [] } });

    props.dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'professionalPracticeOutcome', value: jobs } });
};

export const onStartDateChange = (props: ControlOnChangeArguments) => {
    alert(props.row.endDate);
    const errorMessages =
        new Date(props.value) > new Date(props.row.endDate) ? ['Start year should be less than or equal to End year'] : [];
    
    props.errorMessages.push(errorMessages);
};

export const onEndDateChange = (props: ControlOnChangeArguments) => {
    const errorMessages =
        new Date(props.value) < new Date(props.row.startDate) ? ['End year should be greater than or equal to Start year'] : [];
    props.errorMessages.push(errorMessages);
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
};

const isValidDate = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const professionalPracticeData = state?.data['professionalPracticeOutcome'];
    let isFormValid = true;
    professionalPracticeData.forEach((professionalpractice: any, index: number) => {
        console.log(professionalpractice);

        if (isEmpty(professionalpractice?.startDate)) {
            setError(`professionalPracticeOutcome.${index}.startDate`, [`Please enter "Start Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`professionalPracticeOutcome.${index}.startDate`, [], dispatch);
        }

        if (
            (professionalpractice?.isCurrent === false || isEmpty(professionalpractice?.isCurrent)) &&
            isEmpty(professionalpractice?.endDate)
        ) {
            setError(`professionalPracticeOutcome.${index}.endDate`, [`Please enter "End Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`professionalPracticeOutcome.${index}.endDate`, [], dispatch);
        }

        if (new Date(professionalpractice?.startDate) >= new Date(professionalpractice?.endDate)) {
            console.log('Invalid date');
        }

        if (professionalpractice?.isCurrent === false || isEmpty(professionalpractice?.isCurrent)) {
            if (
                isEmpty(professionalpractice?.endDate) ||
                new Date(professionalpractice?.startDate) > new Date(professionalpractice?.endDate)
            ) {
                setError(`professionalPracticeOutcome.${index}.endDate`, [`End year should be greater than or equal to Start year`], dispatch);
                isFormValid = false;
            } else {
                setError(`professionalPracticeOutcome.${index}.endDate`, [], dispatch);
            }
        }
    });

    return isFormValid;
};
