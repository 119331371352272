import { activateUserSession, isEmpty, isFormValid, setError, syncProfileMarker } from '../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { axiosClient } from '../../../../library/Service/axiosClient';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
// import { toStudentEntity } from '../../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';
import axios from 'axios';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const inValidDate = isValidDate(state, dispatch);
    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);

    if ( !isImpersonatedSession &&
        !state?.data?.profile?.haveNoWorkExp &&  (!isFormValid(state, dispatch, ['jobs.*.companyId']) || !customValidationPassed || !inValidDate)) {
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    let jobHistory = state?.data['jobs']?.map((job:any) => {
        const existingJob = state?.data?.jobsList?.find((existingJob: any) => existingJob.id === job.id);
        const jobCategory = job.jobCategory || 'WORKING_FOR_A_COMPANY';
        const recordTypeCode = job.recordTypeCode || 'JOINED';

        return existingJob ? { ...existingJob, ...job, endDate: job.isCurrent ? null : existingJob.endDate }
            : { ...job, recordTypeCode, jobCategory, endDate: job.isCurrent ? null : job.endDate };
    });

    const jobsNotInHistory = state?.data?.jobsList?.filter((existingJob: any) =>
        !jobHistory.some((job: any) => job.id === existingJob.id)
    );

    jobHistory = [...jobHistory, ...jobsNotInHistory, ...state?.data?.jobsApplied];

    //For checking is_current as per WorkingStatus
    const isValidIsCurrent = isValidIsCurrentAsPerWorkingStatus('jobs',jobHistory,state, dispatch);
    if (!isValidIsCurrent) {
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    // Send event to Moengage PG College
    sendMoEngageEvent(state?.data);

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            jobs: jobHistory,
            profile: state?.data['profile'],
        })
    ).then((response) => {
        if (response?.status === 200) {
            syncProfileMarker(state.routeInfo.id).then(() => {
                navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch, sessionState, sessionDispatch);
            })
        }
    });
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    let isFormInvalid = false;
    const jobs = state.data.jobs;

    jobs.forEach((job: any, index: number) => {
        if (isEmpty(job?.companyId) && isEmpty(job?.otherCompany)) {
            isFormInvalid = true;
            setError(`jobs.${index}.companyId`, [`Please enter "I Was an Employee at"`], dispatch);
        } else {
            setError(`jobs.${index}.companyId`, [], dispatch);
        }
    });

    // Dispatch the validation errors to the state
    if (isFormInvalid) {
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'jobs', errorMessages },
        });
    }

    return !isFormInvalid;
};

export const onCurrentJobSelectionChange = (props: ControlOnChangeArguments) => {
    const rowIndex = parseInt(props.parentDataKey.split('.').pop() as string);
    const jobs = props.state.data.jobs.map((row: any, index: number) => {
        setError(`jobs.${index}.endDate`, [], props.dispatch);
        return index == rowIndex ? { ...row, endYear: '', isCurrent: false } : { ...row, isCurrent: false };
    });
    props.dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey: `${props.parentDataKey}.jobStartAndEndDates`, value: [] } });

    props.dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'jobs', value: jobs } });
};

export const onStartDateChange = (props: ControlOnChangeArguments) => {
    alert(props.row.endDate);
    const errorMessages =
        new Date(props.value) > new Date(props.row.endDate) ? ['Start year should be less than or equal to End year'] : [];
    // props.dispatch({
    //     type: 'SET_FIELD_VALIDATION_ERRORS',
    //     payload: { dataKey: `${props.parentDataKey}.startDate`, errorMessages },
    // });

    props.errorMessages.push(errorMessages);
};

export const onEndDateChange = (props: ControlOnChangeArguments) => {
    const errorMessages =
        new Date(props.value) < new Date(props.row.startDate) ? ['End year should be greater than or equal to Start year'] : [];
    props.errorMessages.push(errorMessages);
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
    // state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch);
};

export const isValidIsCurrentAsPerWorkingStatus = (jobNode:String,jobHistory:any,state: State, dispatch: (dispatchEvent: DispatchEvent) => void): boolean => {
  console.log("jobHistory",jobHistory)
    let isFormValid = true;
    const workingStatusCode = (state?.data?.profile?.workingStatusCode==='IN_DEFENCE') ? 'DEFENCE' : state?.data?.profile?.workingStatusCode;
    const matchingJobs = jobHistory?.filter(
        (job: any) => job.jobCategory === workingStatusCode
      );
    
      // If no job with matching jobCategory exists, no validation is necessary
      if (matchingJobs.length > 0) {
        const hasCurrentJob = matchingJobs.some((job: any) => job.isCurrent === true); 
    
        if (!hasCurrentJob) {
            setError(`${jobNode}`, [
              `Please enter current company data since you're currently working in ${workingStatusCode}`,
            ], dispatch);
            isFormValid = false;
          }
        }
      
     return isFormValid;
};

const isValidDate = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const jobdata = state?.data['jobs'];
    let isFormValid = true;
    jobdata.forEach((job: any, index: number) => {
        console.log(job);

        if (isEmpty(job?.startDate)) {
            setError(`jobs.${index}.startDate`, [`Please enter "Start Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`jobs.${index}.startDate`, [], dispatch);
        }

        if ((job?.isCurrent === false || isEmpty(job?.isCurrent)) && isEmpty(job?.endDate)) {
            setError(`jobs.${index}.endDate`, [`Please enter "End Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`jobs.${index}.endDate`, [], dispatch);
        }

        if (new Date(job?.startDate) >= new Date(job?.endDate)) {
            console.log('Invalid date');
        }
        if (job?.isCurrent === false || isEmpty(job?.isCurrent)) {
            if (isEmpty(job?.endDate) || new Date(job?.startDate) > new Date(job?.endDate)) {
                setError(`jobs.${index}.endDate`, [`End year should be greater than or equal to Start year`], dispatch);
                isFormValid = false;
            } else {
                setError(`jobs.${index}.endDate`, [], dispatch);
            }
        }
    });

    return isFormValid;
};

const sendMoEngageEvent = async (user: any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-work-experience`, {
            userInfo: user,
        });
        // console.log('Response:', response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
