import { resendVerificationEmail } from "../../../library/Core/SmartFunctions";

function ResendVerificationEmailControl() {
    const handleResendEmail = async () => {
              const userString = localStorage.getItem('user');
              
              if (!userString) {
                  alert('User data not found.');
                  return;
              }
          
              try {
                  const user = JSON.parse(userString);
                  if (user?.id) {
                      if (await resendVerificationEmail(user.id,user.instituteId,user.whitelabelUrl,user.firstName+' '+user.lastName,user.instituteName,user.registeredEmailId)) {
                          alert('Email sent Successfully');
                      } else {
                          alert('Something went wrong');
                      }
                  } else {
                      alert('Invalid user data.');
                  }
              } catch (error) {
                  console.error('Error parsing user data:', error);
                  alert('Invalid user data.');
              }
          };

    if(localStorage.getItem('isEmailVerified') == 'undefined')
    {
        localStorage.removeItem('isEmailVerified');
        sessionStorage.removeItem('isEmailVerified');
    }

    const isEmailVerified: number = localStorage.getItem('isEmailVerified') ? Number(localStorage.getItem('isEmailVerified')) : 1;

    return (
    <>
        {isEmailVerified != 1 && <div className="container max-1140">
            <div className="row justify-content-between align-items-center custom-header">
                <div className='col-12'>
                    <div className='custom-alret'>
                        <div className='row g-2 align-items-center'>
                            <div className="col">Don't miss out on important updates from the alumni network. Verify your email now. You can resend the verification email by clicking the resend button.</div>
                            <div className='col-auto'><a onClick={handleResendEmail} className='btn-green'><span>Resend</span></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
    </>
    )
}

export default ResendVerificationEmailControl;