import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { format, isValid } from 'date-fns';

const AddReferral = () => {
    const navigate = useNavigate();
    var [formattedDateFrom, setFormattedDateFrom] = useState('');
var [formattedTimeFrom, setFormattedTimeFrom] = useState('');

    const queryParams = new URLSearchParams(window.location.search);
    const event_Type = queryParams.get('event_type');
    const eventId = queryParams.get('event_id'); // Assuming event_id is also passed in the URL

    const sesinstituteId = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user') as string).primaryInstitute : 0;
    const instLogo = sessionStorage.getItem('school-image');
    console.log(instLogo);
    const instName = localStorage.getItem('school_settings') ? JSON.parse(localStorage.getItem('school_settings') as string).name : '';
    const instDomain = localStorage.getItem('school_settings') ? JSON.parse(localStorage.getItem('school_settings') as string).domain : '';
    const jwtToken = localStorage.getItem('user-jwt-token');
    const [eventTitle, setEventTitle] = useState<string>('');
    const [eventDate, setEventDate] = useState<string>('');
    const [regLink, setRegLink] = useState<string>('');
    // Get the base URL (e.g., http://localhost:3000)
    const baseUrl = window.location.origin;
    let referLink = `${baseUrl}/reference/${instDomain}/users`;

    // Function to get event info
    // Function to fetch event details
    const getEventInfo = async (eventId: string) => {
      try {
          if (eventId) {
              const response = await axios.get(`${process.env.REACT_APP_WIDGET_API_SERVER}/eventInfo/basic-info/${eventId}`, {
                  headers: {
                      Authorization: `Bearer ${jwtToken}`,
                  },
              });
  
              if (response.data) {
                  setEventTitle(response.data.eventTitle || 'Upcoming Event');
                  setRegLink(response.data.regLink || '');
  
                  // Format Date
                  const eventFrom = new Date(response.data.from);
                  const formattedDateFrom = format(eventFrom, 'eeee, MMMM dd, yyyy');
                    
                  // Format the time to: "3:21:34 PM"
                  const formattedTimeFrom = format(eventFrom, 'h:mm:ss a');
                  
                  // Update state with formatted date and time
                  setFormattedDateFrom(formattedDateFrom);
                  setFormattedTimeFrom(formattedTimeFrom);
              }
          }
      } catch (error) {
          console.error('Error fetching event info:', error);
      }
  };

    useEffect(() => {
        if (event_Type === '1' && eventId) {
            getEventInfo(eventId);
        }

        const saveTemplate = async () => {
            const currentDate = new Date();
            const dateOptions: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const formattedDate = currentDate.toLocaleDateString(undefined, dateOptions);
            const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
            const formattedTime = currentDate.toLocaleTimeString(undefined, timeOptions);
            const date = `${formattedDate} ${formattedTime}`;

            let body;

            // If event_type is 1, use the provided HTML template
            if (event_Type === '1') {
                body = `
<!DOCTYPE html>
<html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">

<head>
  <title></title>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  <meta name="viewport" content="width=device-width,initial-scale=1">
  <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]--><!--[if !mso]><!--><!--<![endif]-->
  <style>
    * {
      box-sizing: border-box
    }

    body {
      margin: 0;
      padding: 0
    }

    a[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: inherit !important
    }

    #MessageViewBody a {
      color: inherit;
      text-decoration: none
    }

    p {
      line-height: inherit
    }

    .desktop_hide,
    .desktop_hide table {
      mso-hide: all;
      display: none;
      max-height: 0;
      overflow: hidden
    }

    .image_block img+div {
      display: none
    }

    @media (max-width:620px) {
      .mobile_hide {
        display: none
      }

      .row-content {
        width: 100% !important
      }

      .stack .column {
        width: 100%;
        display: block
      }

      .mobile_hide {
        min-height: 0;
        max-height: 0;
        max-width: 0;
        overflow: hidden;
        font-size: 0
      }

      .desktop_hide,
      .desktop_hide table {
        display: table !important;
        max-height: none !important
      }
    }
  </style>
</head>

<body class="body"
  style="background-color:#f7f7f7;margin:0;padding:0;-webkit-text-size-adjust:none;text-size-adjust:none">
  <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation"
    style="mso-table-lspace:0;mso-table-rspace:0;background-color:#f7f7f7;background-image:none;background-position:top left;background-size:auto;background-repeat:no-repeat">
    <tbody>
      <tr>
        <td>
          <table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
            <tbody>
              <tr>
                <td>
                  <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0"
                    role="presentation"
                    style="mso-table-lspace:0;mso-table-rspace:0;color:#333;width:600px;margin:0 auto" width="600">
                    <tbody>
                      <tr>
                        <td class="column column-1" width="100%"
                          style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;padding-bottom:20px;padding-top:20px;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0">
                          <table class="empty_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                            <tr>
                              <td class="pad">
                                <div></div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
            <tbody>
              <tr>
                <td>
                  <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0"
                    role="presentation"
                    style="mso-table-lspace:0;mso-table-rspace:0;background-color:#fff;background-image:url(https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/f7683d20-2948-460f-8f91-a13fe519b593/E101AEM0MJ84EE2KTBOQ0PLY/alumni-engagement/top-header.png);background-repeat:no-repeat;background-size:cover;color:#000;width:600px;margin:0 auto"
                    width="600">
                    <tbody>
                      <tr>
                        <td class="column column-1" width="100%"
                          style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;padding-bottom:28px;padding-left:10px;padding-right:10px;padding-top:28px;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0">
                          <table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                            <tr>
                              <td class="pad" style="width:100%">
                                <div class="alignment" align="center" style="line-height:10px">
                                  <div style="max-width:160px"><a href="https://beefree.io" target="_blank"
                                      style="outline:none" tabindex="-1"><img
                                        src="${instLogo}"
                                        style="display:block;height:auto;border:0;width:100%" width="160" alt="Image"
                                        title="Image" height="auto"></a></div>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
            <tbody>
              <tr>
                <td>
                  <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0"
                    role="presentation"
                    style="mso-table-lspace:0;mso-table-rspace:0;color:#333;width:600px;margin:0 auto" width="600">
                    <tbody>
                      <tr>
                        <td class="column column-1" width="100%"
                          style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;background-color:#fff;padding-bottom:10px;padding-left:30px;padding-right:30px;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0">
                          <table class="text_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                            <tr>
                              <td class="pad" style="padding-bottom:15px;padding-top:30px">
                                <div style="font-family:sans-serif">
                                  <div class
                                    style="font-size:12px;font-family:Arial,'Helvetica Neue',Helvetica,sans-serif;mso-line-height-alt:14.399999999999999px;color:#000;line-height:1.2">
                                    <p style="margin:0;font-size:14px;mso-line-height-alt:16.8px"><strong><span
                                          style="font-size:16px;">Dear @First_Name @Last_Name,</span></strong></p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                          <table class="text_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                            <tr>
                              <td class="pad" style="padding-bottom:5px;padding-top:5px">
                                <div style="font-family:sans-serif">
                                  <div class
                                    style="font-size:12px;font-family:Arial,'Helvetica Neue',Helvetica,sans-serif;mso-line-height-alt:14.399999999999999px;color:#000;line-height:1.2">
                                    <p style="margin:0;font-size:14px;mso-line-height-alt:16.8px"><span
                                        style="font-size:16px;">Being an exclusive member of our school gets you exclusive access to the events we organise. 
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                          <table class="text_block block-3" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                            <tr>
                              <td class="pad" style="padding-bottom:10px;padding-top:10px">
                                <div style="font-family:sans-serif">
                                  <div class
                                    style="font-size:12px;font-family:Arial,'Helvetica Neue',Helvetica,sans-serif;mso-line-height-alt:14.399999999999999px;color:#000;line-height:1.2">
                                    <p style="margin:0;font-size:14px;mso-line-height-alt:16.8px"><span
                                        style="font-size:16px;">We are glad to invite you to the  ${eventTitle} as a valued member of our illustrious Alumni Community. 
                                        </span></p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                          <table class="text_block block-3" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                            <tr>
                              <td class="pad" style="padding-bottom:10px;padding-top:10px">
                                <div style="font-family:sans-serif">
                                  <div class
                                    style="font-size:12px;font-family:Arial,'Helvetica Neue',Helvetica,sans-serif;mso-line-height-alt:14.399999999999999px;color:#000;line-height:1.2">
                                    <p style="margin:0;font-size:14px;mso-line-height-alt:16.8px"><span
                                        style="font-size:16px;">This event will be engaging and promises to put your thinking caps on! </span></p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="row row-4" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
            <tbody>
              <tr>
                <td>
                  <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0"
                    role="presentation"
                    style="mso-table-lspace:0;mso-table-rspace:0;color:#333;width:600px;margin:0 auto" width="600">
                    <tbody>
                      <tr>
                        <td class="column column-1" width="100%"
                          style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;background-color:#fff;padding-bottom:10px;padding-left:30px;padding-right:30px;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0">
                          <table class="html_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                            <tr>
                              <td class="pad">
                                <div style="font-family:Arial,'Helvetica Neue',Helvetica,sans-serif;text-align:center"
                                  align="center">
                                  <div class="our-class" style="
    background-color: #0b3454;
    padding: 30px;
    border-radius: 5px;
    text-align: left;
    color: #fff;
    background-image: url(https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/f7683d20-2948-460f-8f91-a13fe519b593/MA9APFJAMODXI6YT3IKC239C/curve.png);
    background-position: right -1px bottom -1px;
    background-repeat: no-repeat;
    line-height: 24px;
    font-size: 16px;
">
                                    <b>Date : </b> ${formattedDateFrom}<br>
                                    <b>Venue : </b> ${instName}<br>
                                    <b>Time : </b>  ${formattedTimeFrom}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="row row-5" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
            <tbody>
              <tr>
                <td>
                  <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0"
                    role="presentation"
                    style="mso-table-lspace:0;mso-table-rspace:0;color:#333;width:600px;margin:0 auto" width="600">
                    <tbody>
                      <tr>
                        <td class="column column-1" width="100%"
                          style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;background-color:#fff;padding-bottom:15px;padding-left:30px;padding-right:30px;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0">
                          <table class="text_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                            <tr>
                              <td class="pad" style="padding-bottom:10px;padding-top:10px">
                                <div style="font-family:sans-serif">
                                  <div class
                                    style="font-size:12px;font-family:Arial,'Helvetica Neue',Helvetica,sans-serif;mso-line-height-alt:14.399999999999999px;color:#000;line-height:1.2">
                                    <p style="margin:0;font-size:14px;mso-line-height-alt:16.8px">
                                      <span style="font-size:16px;">By being a part of this event, you get access to come back to the school, interact with your juniors, your peers, and take a trip down the memory lane!.</span>
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                          <table class="text_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                            <tr>
                              <td class="pad" style="padding-bottom:10px;padding-top:10px">
                                <div style="font-family:sans-serif">
                                  <div class
                                    style="font-size:12px;font-family:Arial,'Helvetica Neue',Helvetica,sans-serif;mso-line-height-alt:14.399999999999999px;color:#000;line-height:1.2">
                                    <p style="margin:0;font-size:14px;mso-line-height-alt:16.8px"><span
                                        style="font-size:16px;">Hope to see you there! </span>
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                          <table class="button_block block-3" width="100%" border="0" cellpadding="10" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                            <tr>
                              <td class="pad" style="padding-left: 0;">
                                <div class="alignment"><!--[if mso]>
<v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="dummyurl.com" style="height:44px;width:169px;v-text-anchor:middle;" arcsize="57%" stroke="false" fillcolor="#5cb148">
<w:anchorlock/>
<v:textbox inset="0px,0px,0px,0px">
<center dir="false" style="color:#ffffff;font-family:Arial, sans-serif;font-size:14px">
<![endif]-->
${regLink ? `
    <a href="${regLink}" target="_blank"
       style="background-color:#5cb148;border-bottom:0px solid transparent;border-left:0px solid transparent;border-radius:25px;border-right:0px solid transparent;border-top:0px solid transparent;color:#ffffff;display:inline-block;font-family:Arial, 'Helvetica Neue', Helvetica, sans-serif;font-size:14px;font-weight:400;mso-border-alt:none;padding-bottom:8px;padding-top:8px;text-align:center;text-decoration:none;width:auto;word-break:keep-all;">
      <span style="padding-left:40px;padding-right:40px;font-size:14px;display:inline-block;letter-spacing:normal;">
        <span style="word-break: break-word; line-height: 28px;">
          <strong>Register Now</strong>
        </span>
      </span>
    </a>
  ` : ''}
                                  <!--[if mso]></center></v:textbox></v:roundrect><![endif]-->
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="row row-6" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
            <tbody>
              <tr>
                <td>
                  <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0"
                    role="presentation"
                    style="mso-table-lspace:0;mso-table-rspace:0;border-radius:0;color:#000;width:600px;margin:0 auto"
                    width="600">
                    <tbody>
                      <tr>
                        <td class="column column-1" width="100%"
                          style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;background-color:#fff;padding-bottom:30px;padding-left:30px;padding-right:30px;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0">
                          <table class="divider_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                            <tr>
                              <td class="pad" style="padding-bottom:5px">
                                <div class="alignment" align="center">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%"
                                    style="mso-table-lspace:0;mso-table-rspace:0">
                                    <tr>
                                      <td class="divider_inner"
                                        style="font-size:1px;line-height:1px;border-top:1px solid #e3e3e3">
                                        <span> </span></td>
                                    </tr>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </table>
                          <table class="text_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                            <tr>
                              <td class="pad" style="padding-bottom:10px;padding-top:10px">
                                <div style="font-family:sans-serif">
                                  <div class
                                    style="font-size:12px;font-family:Arial,'Helvetica Neue',Helvetica,sans-serif;mso-line-height-alt:14.399999999999999px;color:#000;line-height:1.2">
                                    <p style="margin:0;mso-line-height-alt:14.399999999999999px"><span
                                        style="font-size:16px;">Warm regards,</span><br>
                                      <strong><span style="font-size:16px;">Alumni Helpdesk</span></strong>
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="row row-7" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
            <tbody>
              <tr>
                <td>
                  <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0"
                    role="presentation"
                    style="mso-table-lspace:0;mso-table-rspace:0;color:#333;width:600px;margin:0 auto" width="600">
                    <tbody>
                      <tr>
                        <td class="column column-1" width="100%"
                          style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;padding-bottom:25px;padding-top:25px;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0">
                          <table class="empty_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                            <tr>
                              <td class="pad">
                                <div></div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table><!-- End -->
</body>

</html>`;
            }
else{
     body = `<p><strong>Dear @First_Name @Last_Name,</strong></p><p>We hope this message finds you well! As a valued member of the ${instName} family, you know firsthand the enriching experience and opportunities we offer. As we begin our admissions season, we’d love your help in spreading the word to potential students who could benefit from our programs and curricula.<br/><br/> If you have friends, family members, or colleagues who are considering taking admission and might be a great fit for ${instName}, please encourage them to explore our offerings. </p><p><a href="${referLink}" rel="noopener noreferrer" target="_blank" style="color: rgb(255, 255, 255); background-color: rgb(92, 177, 72);">Refer Now</a></p><p>We appreciate your support in helping us grow the  ${instName} family.</p><br><p>Warm regards,</br><strong>Alumni Helpdesk</strong></p>`;
        
}
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BROADCAST_SERVER}/email/save-template`,
                    {
                        subject: event_Type === '1' ? `Invitation: ${eventTitle}` : 'Refer Friends and Family to study at your alma mater!',
                        message: body,
                        school_ref_id: sesinstituteId,
                        status: 0,
                        created_date: date,
                        sender_mail: event_Type === '1' ? 'Alumni Helpdesk' : 'Refer Friends and Family',
                        university_logo: instLogo,
                        template_ref_id: event_Type === '1' ? 5 : 4,
                        base_url: baseUrl,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                const insertedId = response.data.insertedId;
                if (insertedId) {
                    if (event_Type) {
                        navigate(`/broadcast/alumni-profiles?template_id=${insertedId}&type=6&event_type=${event_Type}`);
                    } else {
                        navigate(`/broadcast/alumni-profiles?template_id=${insertedId}&type=6`);
                    }
                } else {
                    console.error('Inserted ID not found in the response');
                }
            } catch (error) {
                console.error('Error saving template:', error);
            }
        };

        saveTemplate();
    }, [sesinstituteId, jwtToken, navigate, baseUrl, event_Type, eventId, eventTitle]);

    return null; // Ensure no content is rendered
};

export default AddReferral;
