import { activateUserSession, isEmpty, setError } from '../../../library/Core/SmartFunctions';

export const navigateToNextStep = async (currentStep, state, dispatch, sessionState, sessionDispatch) => {
    const user_id = state.routeInfo.id;

    let userStatusCode = 'ACTIVE';
    if (sessionState?.userTypeCode == 'SUPER_ADMIN') {
        state?.data?.userInstitutes.map((institute) => {
            if (institute.userStatusCode != 'ACTIVE') {
                userStatusCode = institute.userStatusCode;
            }
        });
    }

    if ((sessionState?.userTypeCode == 'SUPER_ADMIN' || sessionState?.userTypeCode == 'INSTITUTE_ADMIN') && userStatusCode == 'ACTIVE') {
        return state.actions['REDIRECT'](`/alumni/${user_id}/profile`);
    }

    if (sessionState?.userTypeCode != 'SUPER_ADMIN' && sessionState?.userStatusCode == 'ACTIVE') {
        return state.actions['REDIRECT'](`/alumni/${sessionState.id}/profile`);
    }

    let nextStepRoute = '';
    switch (currentStep) {
        // case 'login':
        //     nextStepRoute = findNextStepUrlForLogin(state);
        //     break;

        case 'info':
            await findNextStepUrlForBasicInfo(state, dispatch, sessionState, sessionDispatch).then((data) => {
                nextStepRoute = data;
            });
            break;

        case 'outcome':
            await findNextStepUrlForOutcome(state, dispatch, sessionState, sessionDispatch).then((data) => {
                nextStepRoute = data;
            });
            break;

        case 'student-took-a-break':
            await findNextStepUrlForBreak(state, dispatch, sessionState, sessionDispatch).then((data) => {
                nextStepRoute = data;
            });
            break;

        case 'ug':
            await findNextStepUrlForUg(state, dispatch, sessionState, sessionDispatch).then((data) => {
                nextStepRoute = data;
            });
            break;

        case 'pg':
            await findNextStepUrlForPg(state, dispatch, sessionState, sessionDispatch).then((data) => {
                nextStepRoute = data;
            });
            break;

        case 'student-school':
            await findNextStepUrlForSchool(state, dispatch, sessionState, sessionDispatch).then((data) => {
                nextStepRoute = data;
            });
            break;

        // case 'scholarship':
        //     nextStepRoute = findNextStepUrlForScholarship(state, dispatch, sessionState, sessionDispatch);
        //     break;

        case 'entrance-test':
            await findNextStepUrlForEntranceTest(state, dispatch, sessionState, sessionDispatch).then((data) => {
                nextStepRoute = data;
            });
            break;

        case 'entrance-exam':
            await findNextStepUrlForEntranceExam(state, dispatch, sessionState, sessionDispatch).then((data) => {
                nextStepRoute = data;
            });
            break;

        case 'student-preference':
            await findNextStepUrlForStudentPreference(state, dispatch, sessionState, sessionDispatch).then((data) => {
                nextStepRoute = data;
            });
            break;

        case 'work':
            await findNextStepUrlForWork(state, dispatch, sessionState, sessionDispatch).then((data) => {
                nextStepRoute = data;
            });
            break;
        case 'job-preferences':
                await findNextStepUrlForStudentPreference(state, dispatch, sessionState, sessionDispatch).then((data) => {
                    nextStepRoute = data;
        });
        break;
        // case 'higher_education-preference':
        //     nextStepRoute = findNextStepUrlForHigherEducationPreference(state, dispatch, sessionState, sessionDispatch);
        //     break;
        // case 'job-preferences':
        //     nextStepRoute = findNextStepUrlForJobPreferences(state, dispatch, sessionState, sessionDispatch);
        //     break;
        // case 'achievements':
        //     nextStepRoute = findNextStepUrlForAchievements(state, dispatch, sessionState, sessionDispatch);
        //     break;
        // case 'mentoring-interests':
        //     nextStepRoute = findNextStepUrlForMentoringInterests(state, dispatch, sessionState, sessionDispatch);
        //     break;
        // case 'profile-picture':
        //     nextStepRoute = findNextStepUrlForProfilePicture(state, dispatch, sessionState, sessionDispatch);
        //     break;
        default:
            const domain = !isEmpty(localStorage.getItem('school_settings'))
                ? JSON.parse(localStorage.getItem('school_settings'))?.domain
                : 'miles';
            return state.actions['ROUTER_NAVIGATE'](`/user/${domain}/login`);
    }

    return state.actions['REDIRECT'](`/alumni/${user_id}/${nextStepRoute}`);
};

// const findNextStepUrlForLogin = (state) => 'info';

// const findNextStepUrlForBasicInfo = (state) => 'ug';

// const findNextStepUrlForUg = (state) => 'pg';

// const findNextStepUrlForPg = (state) => 'scholarship';

// const findNextStepUrlForScholarship = (state) => 'entrance-test';

// const findNextStepUrlForEntranceTest = (state) => 'work';

// const findNextStepUrlForWork = (state) => 'higher-education-preference';

// const findNextStepUrlForHigherEducationPreference = (state) => 'job-preferences';

// const findNextStepUrlForJobPreferences = (state) => 'achievements';

// const findNextStepUrlForAchievements = (state) => 'mentoring-interests';

// const findNextStepUrlForMentoringInterests = (state) => 'profile-picture';

// const findNextStepUrlForProfilePicture = (state) => '/';

const findNextStepUrlForLogin = async (state, dispatch, sessionState, sessionDispatch) => 'info';

const findNextStepUrlForBasicInfo = async (state, dispatch, sessionState, sessionDispatch) => 'outcome';

const findNextStepUrlForOutcome = async (state, dispatch, sessionState, sessionDispatch) => {
    return 'profile';
};

const findNextStepUrlForBreak = async (state, dispatch, sessionState, sessionDispatch) => {
    return await checkPendingMandatory(state, dispatch, sessionState, sessionDispatch);
};

const findNextStepUrlForUg = async (state, dispatch, sessionState, sessionDispatch) => {
    return await checkPendingMandatory(state, dispatch, sessionState, sessionDispatch);
};

const findNextStepUrlForPg = async (state, dispatch, sessionState, sessionDispatch) => {
    return await checkPendingMandatory(state, dispatch, sessionState, sessionDispatch);
};

const findNextStepUrlForSchool = async (state, dispatch, sessionState, sessionDispatch) => {
    return await checkPendingMandatory(state, dispatch, sessionState, sessionDispatch);
};

const findNextStepUrlForScholarship = async (state, dispatch, sessionState, sessionDispatch) => 'entrance-test';

const findNextStepUrlForEntranceTest = async (state, dispatch, sessionState, sessionDispatch) => {
    return checkPendingMandatory(state, dispatch, sessionState, sessionDispatch);
};

const findNextStepUrlForEntranceExam = async (state, dispatch, sessionState, sessionDispatch) => {
    return checkPendingMandatory(state, dispatch, sessionState, sessionDispatch);
};

const findNextStepUrlForStudentPreference = async (state, dispatch, sessionState, sessionDispatch) => {
    return checkPendingMandatory(state, dispatch, sessionState, sessionDispatch);
};

const findNextStepUrlForWork = async (state, dispatch, sessionState, sessionDispatch) => {
    return checkPendingMandatory(state, dispatch, sessionState, sessionDispatch);
};

const findNextStepUrlForHigherEducationPreference = async (state, dispatch, sessionState, sessionDispatch) => 'job-preferences';

const findNextStepUrlForJobPreferences = async (state, dispatch, sessionState, sessionDispatch) => 'achievements';

const findNextStepUrlForAchievements = async (state, dispatch, sessionState, sessionDispatch) => 'mentoring-interests';

const findNextStepUrlForMentoringInterests = async (state, dispatch, sessionState, sessionDispatch) => 'profile-picture';

const findNextStepUrlForProfilePicture = async (state, dispatch, sessionState, sessionDispatch) => '/';

const checkPendingMandatory = async (state, dispatch, sessionState, sessionDispatch) => {
    let pageSlug = 'profile';
    const errorMessages = [];
  
    // check work data;
    const workStatusList = ['WORKING_FOR_A_COMPANY'];
    if (workStatusList.includes(state?.data?.profile?.workingStatusCode) &&
        (isEmpty(state?.data?.jobs) || state?.data?.jobs?.length <= 0 ) 
    ) {
        pageSlug = 'work';
    }else if(state?.data?.profile?.workingStatusCode==="ENTREPRENEURSHIP" &&
        (isEmpty(state?.data?.entreprenurship) || state?.data?.entreprenurship?.length <= 0)
    ){
        pageSlug = 'entrepreneurship';
    }else if(state?.data?.profile?.workingStatusCode==="TEACHING_AND_RESEARCH" &&
        (isEmpty(state?.data?.teachingWork) || state?.data?.teachingWork?.length <= 0)
    ){
        pageSlug = 'teaching-experience';
    }else if(state?.data?.profile?.workingStatusCode==="PROFESSIONAL_PRACTICE" &&
        (isEmpty(state?.data?.professionalPractice) || state?.data?.professionalPractice?.length <= 0 )
    ){
        pageSlug = 'professional-practice-experience';
    }else if(state?.data?.profile?.workingStatusCode==="IN_DEFENCE" &&
        (isEmpty(state?.data?.defenceWork) || state?.data?.defenceWork?.length <= 0 )
    ){
        pageSlug = 'defence-experience';
    }else if(state?.data?.profile?.workingStatusCode==="SELF_EMPLOYED_AND_FREELANCING" && (isEmpty(state?.data?.freelancing) || state?.data?.freelancing?.length <= 0)){
        pageSlug = 'freelancing-experience';
    }else if (state?.data?.profile?.presentEducationStatusCode == 'PURSUING_STUDIES') {
        switch (state?.data?.profile?.pursuingEducationLevelCode) {
            case 'UG':
                if (isEmpty(state?.data?.underGradCollegeJoined) || state?.data?.underGradCollegeJoined.length <= 0) {
                    pageSlug = 'ug';
                }
                break;

            case 'PG':
            case 'Doctoral':
                if (isEmpty(state?.data?.postGradCollegeJoined) || state?.data?.postGradCollegeJoined.length <= 0) {
                    pageSlug = 'pg';
                } else if (isEmpty(state?.data?.underGradCollegeJoined) || state?.data?.underGradCollegeJoined.length <= 0) {
                    pageSlug = 'ug';
                }
                break;

            // default:
            //     if (schoolArr.includes(state?.data?.profile?.pursuingEducationLevelCode)) {
            //         if ( isEmpty (state?.data?.schoolOutComesDetail) || state?.data?.schoolOutComesDetail.length <= 0 ) {
            //             pageSlug = 'student-school';
            //         }
            //     }
            //     break;
        }
    } else if (state?.data?.profile?.presentEducationStatusCode == 'COMPLETED_STUDIES') {
        if (state?.data?.profile?.workingStatusCode == 'NOT_WORKING' && state?.data?.profile?.preparingForExam) {
            pageSlug = 'entrance-exam';
        }

        switch (state?.data?.profile?.highestCompletedEducationLevelCode) {
            case 'UG':
                if (isEmpty(state?.data?.underGradCollegeJoined) || state?.data?.underGradCollegeJoined.length <= 0) {
                    pageSlug = 'ug';
                }
                break;

            case 'PG':
            case 'Doctoral':
                if (isEmpty(state?.data?.postGradCollege) || state?.data?.postGradCollege.length <= 0) {
                    pageSlug = 'pg';
                }
                break;

            // default:
            //     if (schoolArr.includes(state?.data?.profile?.highestCompletedEducationLevelCode)) {
            //         if ( isEmpty (state?.data?.schoolOutComesDetail) || state?.data?.schoolOutComesDetail.length <= 0 ) {
            //             pageSlug = 'student-school';
            //         }
            //     }
            //     break;
        }
    }

    if (pageSlug == 'profile') {
        await activateUserSession(sessionState, sessionDispatch);
    }

    return pageSlug;
};
