import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { isEmpty } from '../../library/Core/SmartFunctions';
import { axiosClient } from '../../library/Service/axiosClient';
import { useImmerReducer } from 'use-immer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import smartReducer from '../../library/Core/SmartReducer';

function InstagramLoginSuccess() {
    const [isLoading, setIsLoading] = useState(true);
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
        },
    } as any);
    const [accessToken, setAccessToken]: any = useState();
    const [expirationTime, setExpirationTime]: any = useState();
    const navigate = useNavigate();

    useEffect(() => {
        console.log(state, " S T A T E 23")
        const hash = window.location.hash;
        const urlParams = new URLSearchParams(hash.replace('#', '?')); // Replace `#` with `?` to use URLSearchParams

        // Get the parameters from the hash
        const accessToken = urlParams.get('access_token');
        const expirationTime = urlParams.get('data_access_expiration_time');

        console.log(accessToken, " accessToken ");
        console.log(expirationTime, " expirationTime ")
        // Set state with the parsed values
        if (accessToken) setAccessToken(accessToken);
        if (expirationTime) setExpirationTime(expirationTime);

        // fetch(`https://graph.facebook.com/me/accounts?access_token=${accessToken}`)
        // fetch(`https://graph.facebook.com/me/accounts?fields=id,name,picture&access_token=${accessToken}`)
        const params = {
            'networkType': `instagram`,
            'accessToken': accessToken
        }

        addAccount(params);
        // const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/profile/get-social-network-pages`, params);
        // const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/social/get-pages`, params);
        

        // add-account
        // .then(response => response.json())
        // .then(data => {
        //     console.log(data, " data ALL")
        //     // No data is coming due to lack of permissions
        //     // Need to request advanced access from the FB by submitting a review request
        //     // Before that need to get rid of violations
        //     if (data && data.data) {
        //         // Assuming the user has multiple pages, get the first one
        //         const pages = data.data  // If there are multiple pages, you can choose the correct one

        //         if (isEmpty(pages[0])) {
        //             Swal.fire(`Oops..!`, 'No account data found..!', 'warning').then((result) => {
        //                 navigate(`/socialmedia/social-integration`);    
        //             });
        //         }

        //         // pages.map((page: any, idx: any) => {
        //         //     const pageId = page.id;
        //         //     const accessToken = page.access_token;
        //         //     const pageName = page.name;
        //         //     const category = page.category;
    
        //         //     console.log('Page ID:', pageId);
        //         //     console.log('Page Name:', pageName);
        //         // })

        //         // call API to insert/update the page data here.

        //         setIsLoading(false);
                


        //     } else {
        //         console.log('No pages found or error:', data);
        //         Swal.fire(`Oops..!`, 'No account data found..!', 'warning').then((result) => {
        //             navigate(`/socialmedia/social-integration`);    
        //         });
        //     }
        // })
        // .catch(error => {
        //     console.error('Error fetching page data:', error);
        // });
    }, []);

    const addAccount = async (params: any) => {
        console.log('Instagram Params: ', params)
        const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/social/add-account`, params);
        // console.log(response, " ADD ACCOUNT response")
        // setIsLoading(false);
        await Swal.fire(`${response?.data?.success == 1 ? 'Success..!' : ''}`, `${response?.data?.message}`, `${response?.data?.success == 1 ? 'success' : 'warning'}`);
        navigate(`/socialmedia/social-integration/${params.networkType}`);
    }

    useEffect(() => {
        if (isLoading) {
            const loader	= Swal.fire({
                title: 'Loading...',
                html: 'Please wait!',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading(); // Show loading spinner
                },
            });
        } else {
            Swal.close();
        }
    }, [isLoading]);

  return (
    <>
    {/* {isLoading && (
        <div className='loaderNew'></div>
    )} */}
    </>
  )
}

export default InstagramLoginSuccess;
